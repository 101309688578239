import TextField from '@mui/material/TextField';
import clsx from 'clsx';
import { ChangeEvent, FC, useState } from 'react';
import { useFormContext, UseFormSetValue } from 'react-hook-form';
import { ReactComponent as IdIcon } from '../../icons/Id.svg';
import { CustomTypography } from '../custom/CustomTypography';
import { EditIdmProviderInputs } from '../extensions/trustedProviders/edit/EditIdmProvider';
import { UploadAndDisplayImage } from '../settings/clientSettings/UploadAndDisplayImage';
import styles from './editPanel/EditProvider.module.css';

type TProviderHeader = {
  watchDescription: string;
  overrideImage?: File | string | null;
  setAvatarError?: (error: string) => void;
  clearAvatarError?: () => void;
  setAvatarValue?: (value: any) => void;
  pathToAvatar: string | null;
  setAvatarLink?: (value: string) => void;
  setValueImage?: UseFormSetValue<EditIdmProviderInputs>;
};

export const ProviderHeader: FC<TProviderHeader> = ({ watchDescription, pathToAvatar }) => {
  const [imgSrc, setImgSrc] = useState<string | null>(pathToAvatar);

  const {
    register,
    setValue,
    clearErrors,
    setError,
    formState: { errors },
  } = useFormContext();
  return (
    <>
      <CustomTypography className={clsx('font-golos', 'text-17-regular', styles.subtitle)}>
        Основная информация
      </CustomTypography>
      <CustomTypography className={clsx('text-14', styles.asterisk, styles['input-title'])}>
        Имя
      </CustomTypography>
      <TextField
        {...register('name', {
          required: true,
          onChange: () => {
            if (errors.name) clearErrors('name');
          },
        })}
        className="custom"
        error={!!errors.name}
        helperText={errors.name ? (errors.name.message as string) : ''}
        fullWidth
        variant="standard"
      />
      <CustomTypography className={clsx('text-14', styles['input-subtitle'])} color="grey">
        Название, отображаемое пользователям
      </CustomTypography>
      <CustomTypography className={clsx('text-14', styles['input-title'])}>
        Описание
      </CustomTypography>
      <TextField
        {...register('description', {
          onChange: (event: ChangeEvent<HTMLInputElement>) => {
            if (event.target.value.length > 255) {
              setError('description', {
                message: 'Невозможно ввести более 255 символов',
              });
              setValue('description', event.target.value.slice(0, 255));
            } else if (errors.description) clearErrors('description');
          },
        })}
        className="custom"
        error={!!errors.description}
        helperText={errors.description ? (errors.description.message as string) : ''}
        fullWidth
        variant="standard"
        multiline
      />
      <CustomTypography className={clsx('text-14', styles['input-subtitle'])} color="grey">
        {watchDescription?.length}/255 символов
      </CustomTypography>
      <div>
        <CustomTypography className={clsx('text-14', styles['input-title'])}>
          Логотип
        </CustomTypography>
        <UploadAndDisplayImage
          componentName="create-provider"
          DefaultIcon={<IdIcon fill="#CED0D9" className={styles['id-icon']} />}
          pathToAvatar={pathToAvatar}
          imgSrc={imgSrc}
          setImgSrc={setImgSrc}
        />
        {errors.avatar && (
          <CustomTypography color="error" className={clsx('text-14', styles['input-error'])}>
            {errors.avatar.message?.toString()}
          </CustomTypography>
        )}
        <CustomTypography className={clsx('text-14', styles['input-subtitle'])} color="grey">
          Файл с расширением .jpg, .jpeg, .png, .svg. Максимальный размер - 1 МБ.
        </CustomTypography>
      </div>
      <div className={styles.divider} />
      <CustomTypography className={clsx('text-17', styles.subtitle)}>Параметры</CustomTypography>
    </>
  );
};
