import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { BACKEND_URL } from '../../constants';
import { getAccessToken } from '../../service/auth';

export enum MailCodeTypes {
  authorization = 'authorization',
  registration = 'registration',
  changeMail = 'changeMail',
  addEmail = 'addEmail',
}

export const mailApi = createApi({
  reducerPath: 'mailApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${BACKEND_URL}/api/mail/v1`,
    prepareHeaders: async (headers) => {
      const accessToken = await getAccessToken();
      headers.set('authorization', `Bearer ${accessToken}`);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    checkEmailAvialability: builder.query<{ isExist: boolean; uniqueRole: boolean }, string>({
      query: (email) => {
        return {
          url: `${BACKEND_URL}/api/v1/verification/check?type=EMAIL&email=${email}`,
          method: 'GET',
        };
      },
    }),

    sendEmailCode: builder.mutation<
      { message?: string },
      {
        uid?: string;
        email: string;
        type: MailCodeTypes;
        userId?: string;
        name?: string;
        resend?: boolean;
        rebind?: boolean;
      }
    >({
      query: ({ uid, email, type, userId, name, resend }) => {
        const timezoneOffsetInHours = new Date().getTimezoneOffset() / 60;

        return {
          url: BACKEND_URL + '/api/v1/verification/code',
          body: JSON.stringify({
            type: 'EMAIL',
            email,
            code_type: type,
            uid,
            user_id: userId,
            name,
            resend,
            timezone_offset: timezoneOffsetInHours,
          }),
          headers: {
            'Content-Type': 'application/json',
          },
          method: 'POST',
        };
      },
    }),
  }),
});

export const { useLazyCheckEmailAvialabilityQuery, useSendEmailCodeMutation } = mailApi;
