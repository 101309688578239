import LinearProgress from '@mui/material/LinearProgress';
import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { CLIENT_ID } from '../constants';
import { convertUserProfile } from '../helpers';
import { setUserProfile } from '../redux/userSlice';
// import { RootState } from '../redux/rootReducer';
import { getTokensByCode, sendAuthRequest } from '../requests/oidc';
import { decodeJWT, isTokenValid, setTokenCookies } from '../service/auth';
import { useNavigate } from 'react-router-dom-v5-compat';
import { useLazyGetUserRolesQuery } from 'src/redux/services/users';
import { Roles } from 'src/enums';

type TCodeProps = unknown;
const mapStateToProps = () => ({});

const CodeComponent: React.FC<TCodeProps> = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [getUserRoles] = useLazyGetUserRolesQuery();

  useEffect(() => {
    const auth = async () => {
      try {
        const { accessToken, idToken, refreshToken, expiresIn } = await getTokensByCode();
        if (await isTokenValid(idToken)) {
          const decodedJWT = decodeJWT(idToken);
          if (decodedJWT) {
            setTokenCookies(accessToken, refreshToken, expiresIn);

            const userProfile = convertUserProfile(decodedJWT);
            if (userProfile.id) {
              const roles = await getUserRoles({ id: userProfile.id }).unwrap();
              const role = roles?.find(
                (item: { client_id: string; role: Roles }) => item.client_id === CLIENT_ID,
              )?.role;

              dispatch(setUserProfile({ ...userProfile, roleRootApp: role, roles: roles }));
            }
          }
          navigate('/main');
        } else {
          sendAuthRequest('consent');
        }
      } catch (e) {
        console.log('auth error: ' + e);
        navigate('/login');
      }
    };

    auth();
  }, []);

  return <LinearProgress />;
};

export const Code = connect(mapStateToProps)(CodeComponent);
