import { FC, ReactNode } from 'react';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { TypographyProps } from '@mui/material';
import { ThemeColors } from './Theme';

export const CustomTypography: FC<TypographyProps & { color?: ThemeColors, children: ReactNode }> = ({
  sx,
  color,
  children,
  ...props
}) => {
  const theme = useTheme();

  const getColor = () => {
    if (color === 'grey') return theme.palette.custom.mainGrey;
    if (color === 'error') return theme.palette.custom.error;
    if (color === 'link') return theme.palette.custom.link;
    if (color === 'secondary') return theme.palette.custom.secondary;
    if (color === 'background') return theme.palette.custom.background;
    if (color === 'secondary-grey') return theme.palette.custom.secondaryGrey;
    return theme.palette.custom.main;
  };

  return <Typography sx={{ color: getColor(), ...sx }} {...props}>{children}</Typography>;
};
