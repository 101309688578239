import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import clsx from 'clsx';
import { ChangeEvent, FC, ReactElement, useEffect, useRef } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { getImageURL, toBase64 } from '../../../helpers';
import styles from './UploadAndDisplayImage.module.css';

type TUploadAndDisplayImageProps = {
  setAvatarError?: (error: string) => void;
  setAvatarValue?: (value: File | null) => void;
  clearAvatarError?: () => void;
  setImgSrc: (value: string | null) => void;
  maxImageSize?: number;
  componentName?: string;
  DefaultIcon?: ReactElement;
  overRideImageSrc?: File | string | null;
  pathToAvatar?: string | null;
  setAvatarLink?: (value: string) => void;
  imgSrc?: string | null;
  disabled?: boolean;
  onAvailableAvatarsButtonClick?: () => void;
};

export const UploadAndDisplayImage: FC<TUploadAndDisplayImageProps> = ({
  setAvatarError,
  clearAvatarError,
  componentName,
  DefaultIcon,
  overRideImageSrc,
  pathToAvatar,
  imgSrc,
  setImgSrc,
  disabled,
  maxImageSize = 1,
  onAvailableAvatarsButtonClick,
}) => {
  const { register, setValue, setError, clearErrors, control } = useFormContext();
  const inputRef = useRef<HTMLInputElement>(null);

  const uploadedAvatar = useWatch({ control, name: 'avatar' });
  useEffect(() => {
    if (typeof uploadedAvatar === 'string') {
      setImgSrc(uploadedAvatar);
    }
  }, [uploadedAvatar, setImgSrc]);

  useEffect(() => {
    if (typeof pathToAvatar !== 'string') pathToAvatar = null;
    setImgSrc(pathToAvatar);
  }, [pathToAvatar]);

  useEffect(() => {
    if (overRideImageSrc) {
      const setImgSrcAsync = async () => {
        if (overRideImageSrc instanceof Blob) {
          setImgSrc(await toBase64(overRideImageSrc));
        } else {
          setImgSrc(overRideImageSrc);
        }
      };

      setImgSrcAsync();
    }
  }, [overRideImageSrc]);

  const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) {
      clearErrors('avatar');
      return;
    }

    if (
      !['jpeg', 'png', 'jpg', 'svg+xml'].find((imageType) => `image/${imageType}` === file.type)
    ) {
      setError('avatar', { message: 'Недопустимый формат изображения' });
      return setAvatarError && setAvatarError('Недопустимый формат изображения');
    }

    if (file.size / 1024 / 1024 > maxImageSize) {
      setError('avatar', { message: `Размер изображения не должен превышать ${maxImageSize} МБ` });
      return (
        setAvatarError &&
        setAvatarError(`Размер изображения не должен превышать ${maxImageSize} МБ`)
      );
    }

    const src = await toBase64(file);
    setImgSrc(src);
    setValue('avatar', file, { shouldDirty: true });

    // Сбрасываем значение input через ref
    if (inputRef.current) {
      inputRef.current.value = '';
    }

    clearErrors('avatar');
    clearAvatarError && clearAvatarError();
  };

  return (
    <div className={styles.container}>
        <Avatar
          className={clsx(styles.avatarContainer, {
            [styles.avatarCircle]: DefaultIcon?.props.title === 'Profile avatar',
            [styles['gray-avatar']]: DefaultIcon?.props.title === 'Profile avatar' && !imgSrc,
          })}
        >
          {imgSrc ? <img className={styles.avatar} src={imgSrc?.startsWith('data:') ? imgSrc : getImageURL(imgSrc)} alt="Uploaded Avatar" /> : DefaultIcon}
        </Avatar>
        <Button
          onClick={() => {
            setImgSrc(null);
            clearAvatarError && clearAvatarError();
          }}
          className={styles['delete-button']}
          variant="custom"
          color="secondary"
          disabled={disabled || !imgSrc}
        >
          Удалить
        </Button>
        <Button
          className={styles['load-button']}
          variant="custom"
          component="label"
          color="secondary"
          disabled={disabled}
        >
          Загрузить
          <input
            id={componentName}
            type="file"
            hidden
            {...register('avatar')}
            onChange={handleFileChange}
            ref={inputRef}
          />
        </Button>
        {onAvailableAvatarsButtonClick && (
          <Button variant="custom" color="secondary" onClick={onAvailableAvatarsButtonClick}>
            Доступные
          </Button>
        )}
      </div>
  );
};
