import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import LayersOutlinedIcon from '@mui/icons-material/LayersOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import clsx from 'clsx';
import { FC, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom-v5-compat';
import { getImageURL } from 'src/helpers';
import { useDeleteClientMutation } from 'src/redux/services/clients';
import { CustomMuiIcon } from '../custom/CustomMuiIcon';
import { CustomTypography } from '../custom/CustomTypography';
import { ConfirmationModal } from '../modal/ConfirmationModal';
import { IconWrapper } from '../shared/IconWrapper';
import styles from './ClientDetailsHeader.module.css';
import { useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';

interface IHeaderProps {
  client?: {
    avatar?: string | null;
    name?: string;
  };
  description: string;
}

const HeaderComponent: FC<IHeaderProps> = ({ client, description }) => {
  const navigate = useNavigate();

  const { id } = useParams<{ id: string }>();

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const [deleteClient] = useDeleteClientMutation();
  const clientProfile = useSelector((state: RootState) => state.app.clientProfile);
  const PROJECT_NAME = clientProfile?.name || 'PROJECT_NAME';

  const handleDelete = () => {
    deleteClient(id ?? '');
    setDeleteModalOpen(false);
    navigate('/applications');
  };

  return (
    <div className={styles.header}>
      <Box className={styles.headerContent}>
        <Box className={styles.headerAvatar}>
          {client?.avatar ? (
            <Avatar src={getImageURL(client.avatar)} className={styles.avatar} />
          ) : (
            <CustomMuiIcon Icon={LayersOutlinedIcon} color="grey" className={styles.avatar} />
          )}
        </Box>
        <Box className={styles.headerInfo}>
          <CustomTypography className="header-2-medium">{client?.name}</CustomTypography>
          <div className={styles.headerButtons}>
            <Tooltip title="Настройки">
              <Button
                data-id="settings-button"
                variant="custom"
                component="label"
                color="secondary"
                onClick={() => navigate('edit')}
                startIcon={<SettingsOutlinedIcon classes={{ root: styles.buttonIcon }} />}
              />
            </Tooltip>
            <Tooltip title="Удалить приложение">
              <Button
                data-id="delete-button"
                variant="custom"
                component="label"
                color="secondary"
                onClick={() => setDeleteModalOpen(true)}
                startIcon={<DeleteOutlineOutlinedIcon classes={{ root: styles.buttonIcon }} />}
              />
            </Tooltip>
          </div>
        </Box>
      </Box>
      <Box className={styles.descriptionContent}>
        <div className={styles.descriptionHeader}>
          <IconWrapper className={styles.iconWrapper}>
            <CustomMuiIcon Icon={InfoOutlinedIcon} color="grey" />
          </IconWrapper>
          <CustomTypography className="text-15-medium">Описание</CustomTypography>
        </div>
        <CustomTypography className={clsx('text-14', styles.descriptionText)}>
          {!description ? 'Отсутствует описание приложения' : description}
        </CustomTypography>
      </Box>
      <ConfirmationModal
        title="Удалить приложение"
        mainMessage={[
          `Приложение ${client?.name} будет удалено.`,
          `Пользователи не смогут войти в ваше приложение, используя аккаунт ${PROJECT_NAME}.`,
        ]}
        additionMessage="Это действие невозможно отменить."
        isOpen={deleteModalOpen}
        onAction={() => handleDelete()}
        onClose={() => setDeleteModalOpen(false)}
      />
    </div>
  );
};

export const ClientDetailsHeader = HeaderComponent;
