import Button from '@mui/material/Button';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import clsx from 'clsx';
import React, { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom-v5-compat';
import { getImageURL } from 'src/helpers';
import { TMiscProvider, useDeleteProviderMutation } from '../../../redux/services/provider';
import {
  TProfileField,
  useCreateProfileFieldMutation,
  useGetRuleValidationsByFieldNameQuery,
  useUpdateProfileFieldMutation,
} from '../../../redux/services/settings';
import { useGetUserQuery } from '../../../redux/services/user';
import { PrivateScopesMode } from '../../../requests/user';
import { CustomTypography } from '../../custom/CustomTypography';
import { ConfirmationModal } from '../../modal/ConfirmationModal';
import { EClaimPrivacy, PublicStatusPopover } from '../../profile/PublicStatusPopover';
import { IconWithTooltip } from '../../shared/IconWithTooltip';
import { BaseSidePanel } from '../../sidePanel/BaseSidePanel';
import styles from './EditProfileField.module.css';
import { EditRuleValidations } from './EditRuleValidations';
import { EmailProvider } from './EmailProvider';
import { PhoneProvider } from './PhoneProvider';
import Avatar from '@mui/material/Avatar';
import { RuleFieldNames } from 'src/enums';

type TEditProfileField = {
  isOpen: boolean;
  onClose: () => void;
  selectedProfile?: TProfileField;
  phoneProvider?: TMiscProvider;
  emailProvider?: TMiscProvider;
};

export const EditProfileField: FC<TEditProfileField> = ({
  isOpen,
  onClose,
  selectedProfile,
  phoneProvider,
  emailProvider,
}) => {
  const [createProfileField] = useCreateProfileFieldMutation();
  const [updateProfileField] = useUpdateProfileFieldMutation();
  const [deleteProvider] = useDeleteProviderMutation();
  const { clientId = '' } = useParams<{ clientId: string }>();

  const [saveClaimPrivacy, setClaimPrivacy] = useState(EClaimPrivacy.private);
  const [saveField, setField] = useState<string>('');
  const [saveTitle, setTitle] = useState<string>('');
  const [saveDefault, setDefault] = useState<string | undefined>(undefined);
  const [saveMappingVcard, setMappingVcard] = useState<string | undefined>(undefined);
  const [saveEditable, setEditable] = useState<boolean>(true);
  const [saveRequired, setRequired] = useState<boolean>(false);
  const [saveUnique, setUnique] = useState<boolean>(false);
  const [saveActive, setActive] = useState<boolean>(false);
  const [saveAllowedAsLogin, setAllowedAsLogin] = useState<boolean | undefined>(undefined);
  const [initialData, setInitialData] = useState<Omit<TProfileField, 'type'>>({
    field: '',
    title: '',
    default: undefined,
    mapping_vcard: undefined,
    editable: true,
    required: false,
    unique: false,
    active: false,
    allowed_as_login: undefined,
    claim: EClaimPrivacy.private,
  });
  const [isSave, setIsSave] = useState(false);
  const [isEditRuleValidationsOpen, setIsEditRuleValidationsOpen] = useState(false);
  const [isPhoneProviderOpen, setIsPhoneProviderOpen] = useState(false);
  const [isEmailProviderOpen, setIsEmailProviderOpen] = useState(false);
  const [isModalDeleteMailOpen, setIsModalDeleteMailOpen] = useState(false);
  const [isModalDeletePhoneOpen, setIsModalDeletePhoneOpen] = useState(false);
  const { refetch: getUser } = useGetUserQuery();

  const headerText = !selectedProfile
    ? 'Добавить поле профиля'
    : `Редактировать параметры поля "${selectedProfile.title}"`;

  const { data: ruleValidations = [] } = useGetRuleValidationsByFieldNameQuery(
    selectedProfile?.field || '',
  );
  const listTitleRuleValidations = selectedProfile
    ? ruleValidations.filter((item) => item.active).map((item) => item.title) || []
    : [];

  useEffect(() => {
    return () => {
      setField('');
      setTitle('');
      setDefault('');
      setMappingVcard('');
      setEditable(true);
      setRequired(false);
      setUnique(false);
      setActive(false);
      setClaimPrivacy(EClaimPrivacy.private);
      setAllowedAsLogin(undefined);
    };
  }, [isOpen]);

  useEffect(() => {
    if (selectedProfile) {
      const initial = {
        field: selectedProfile.field,
        title: selectedProfile.title,
        default: selectedProfile.default || undefined,
        mapping_vcard: selectedProfile.mapping_vcard || undefined,
        editable: selectedProfile.editable,
        required: selectedProfile.required,
        unique: selectedProfile.unique,
        active: selectedProfile.active,
        claim: selectedProfile.claim,
        allowed_as_login: selectedProfile.allowed_as_login || undefined,
      };

      setInitialData(initial);
      setField(initial.field);
      setTitle(initial.title);
      setDefault(initial.default);
      setMappingVcard(initial.mapping_vcard);
      setEditable(initial.editable);
      setRequired(initial.required);
      setUnique(initial.unique);
      setActive(initial.active);
      setClaimPrivacy(initial.claim);
      setAllowedAsLogin(initial.allowed_as_login);
    } else {
      const initial = {
        field: '',
        title: '',
        default: undefined,
        mapping_vcard: undefined,
        editable: true,
        required: false,
        unique: false,
        active: false,
        claim: EClaimPrivacy.private,
        allowed_as_login: undefined,
      };

      setInitialData(initial);
      setField(initial.field);
      setTitle(initial.title);
      setDefault(initial.default);
      setMappingVcard(initial.mapping_vcard);
      setEditable(initial.editable);
      setRequired(initial.required);
      setUnique(initial.unique);
      setActive(initial.active);
      setClaimPrivacy(initial.claim);
      setAllowedAsLogin(initial.allowed_as_login);
    }
  }, [selectedProfile, isOpen]);

  useEffect(() => {
    getUser();
  }, [selectedProfile, createProfileField]);

  useEffect(() => {
    const isDataChanged =
      saveField !== initialData.field ||
      saveTitle !== initialData.title ||
      saveDefault !== initialData.default ||
      saveMappingVcard !== initialData.mapping_vcard ||
      saveEditable !== initialData.editable ||
      saveRequired !== initialData.required ||
      saveUnique !== initialData.unique ||
      saveActive !== initialData.active ||
      saveAllowedAsLogin !== initialData.allowed_as_login ||
      saveClaimPrivacy !== initialData.claim;

    setIsSave(isDataChanged);
  }, [
    saveField,
    saveTitle,
    saveDefault,
    saveMappingVcard,
    saveEditable,
    saveRequired,
    saveUnique,
    saveActive,
    saveClaimPrivacy,
    saveAllowedAsLogin,
    initialData,
  ]);

  const handleSave = async () => {
    try {
      let newClaim = undefined;
      switch (saveClaimPrivacy) {
        case EClaimPrivacy.private:
          newClaim = PrivateScopesMode.SET_PRIVATE;
          break;
        case EClaimPrivacy.publicGravatar:
          newClaim = PrivateScopesMode.SET_PUBLIC_GRAVATAR;
          break;
        case EClaimPrivacy.publicOauth:
          newClaim = PrivateScopesMode.SET_PUBLIC_OAUTH;
          break;
        default:
          break;
      }

      if (selectedProfile) {
        await updateProfileField({
          field_name: selectedProfile.field,
          body: {
            field: saveField !== initialData.field ? saveField : undefined,
            title: saveTitle !== initialData.title ? saveTitle : undefined,
            default: saveDefault !== initialData.default ? saveDefault : undefined,
            mapping_vcard:
              saveMappingVcard !== initialData.mapping_vcard ? saveMappingVcard : undefined,
            editable: saveEditable !== initialData.editable ? saveEditable : undefined,
            required: saveRequired !== initialData.required ? saveRequired : undefined,
            unique: saveUnique !== initialData.unique ? saveUnique : undefined,
            active: saveActive !== initialData.active ? saveActive : undefined,
            claim: saveClaimPrivacy !== initialData.claim ? newClaim : undefined,
            allowed_as_login:
              saveAllowedAsLogin !== initialData.allowed_as_login ? saveAllowedAsLogin : undefined,
          },
        }).unwrap();
      } else {
        await createProfileField({
          field: saveField,
          title: saveTitle,
          default: saveDefault,
          mapping_vcard: saveMappingVcard,
          editable: saveEditable,
          required: saveRequired,
          unique: saveUnique,
          active: saveActive,
          claim: newClaim || PrivateScopesMode.SET_PRIVATE,
          allowed_as_login: saveAllowedAsLogin,
        }).unwrap();
      }

      handleDrawerClose();
    } catch (error) {
      // empty_line
    }
  };

  // Функция обратного вызова для обновления статуса картинки
  const handleImageStatusChange = (status: EClaimPrivacy) => {
    setClaimPrivacy(status);
  };

  const handleDrawerClose = () => {
    setClaimPrivacy;
    onClose();
  };

  const handleDeleteProvider = async (providerId: string) => {
    await deleteProvider({
      client_id: clientId,
      provider_id: providerId,
    });
  };

  return (
    <BaseSidePanel
      handleClosePanel={() => handleDrawerClose()}
      isOpenPanel={isOpen}
      title={headerText}
      isNoBackdrop={false}
      handleConfirm={handleSave}
      disabledButtonConfirm={!isSave}
    >
      <div className={styles.wrapper}>
        <CustomTypography className={clsx('text-14', styles.asterisk, styles['margin-bottom'])}>
          Название
        </CustomTypography>
        <TextField
          placeholder="Введите название"
          value={saveField}
          onChange={(event) => setField(event.target.value)}
          disabled={selectedProfile?.type === 'general'}
          fullWidth
          variant="standard"
          className="custom"
        />
        <CustomTypography className={clsx('text-14', styles['input-subtitle'])} color="grey">
          Название поля в системе
        </CustomTypography>

        <CustomTypography className={clsx('text-14', styles.asterisk, styles['margin-bottom'])}>
          Описание поля
        </CustomTypography>
        <TextField
          placeholder="Введите описание поля"
          value={saveTitle}
          onChange={(event) => setTitle(event.target.value)}
          disabled={selectedProfile?.type === 'general'}
          fullWidth
          variant="standard"
          className="custom"
        />
        <CustomTypography className={clsx('text-14', styles['input-subtitle'])} color="grey">
          Отображаемое название поля
        </CustomTypography>
        {selectedProfile && ['login', 'email', 'phone_number'].includes(selectedProfile.field) && (
          <>
            <div className={styles.switcher}>
              <CustomTypography className={clsx('text-14', styles['info-item-value'])}>
                Использовать в качестве логина
              </CustomTypography>
              <Switch
                className={styles.switch}
                checked={saveAllowedAsLogin}
                onChange={() => {
                  setAllowedAsLogin(!saveAllowedAsLogin);
                  if (!saveAllowedAsLogin === true) {
                    setUnique(true);
                    setRequired(true);
                  }
                }}
              />
            </div>
          </>
        )}
        <div className={styles.switcher}>
          <CustomTypography className={clsx('text-14', styles['info-item-value'])}>
            Активность
          </CustomTypography>
          <Switch
            className={styles.switch}
            checked={saveActive}
            onChange={() => setActive(!saveActive)}
            disabled={selectedProfile && selectedProfile?.type !== 'custom'}
          />
        </div>
        <CustomTypography
          style={{ marginBottom: 8 }}
          className={clsx('font-golos', 'text-17-regular')}
        >
          Параметры
        </CustomTypography>
        <CustomTypography style={{ marginBottom: 16 }} className={clsx('text-14')} color="grey">
          Укажите параметры поля
        </CustomTypography>
        <div className={styles.switchers}>
          <div className={styles.switcher}>
            <CustomTypography className={clsx('text-14', styles['info-item-value'])}>
              Редактируемость
            </CustomTypography>
            <Switch
              className={styles.switch}
              checked={saveEditable}
              onChange={() => setEditable(!saveEditable)}
            />
          </div>
          <div className={styles.switcher}>
            <CustomTypography className={clsx('text-14', styles['info-item-value'])}>
              Обязательность
            </CustomTypography>
            <Switch
              disabled={selectedProfile?.field === 'picture'}
              className={styles.switch}
              checked={saveRequired}
              onChange={() => {
                setRequired(!saveRequired);
                if (!saveRequired === false) {
                  setAllowedAsLogin(false);
                }
              }}
            />
          </div>
          <div className={styles.switcher}>
            <CustomTypography className={clsx('text-14', styles['info-item-value'])}>
              Уникальность
            </CustomTypography>
            <Switch
              className={styles.switch}
              checked={saveUnique}
              disabled={
                selectedProfile &&
                selectedProfile?.type !== 'custom' &&
                selectedProfile?.field !== 'email'
              }
              onChange={() => {
                setUnique(!saveUnique);
                if (!saveUnique === false) {
                  setAllowedAsLogin(false);
                }
              }}
            />
          </div>
          <div className={styles.switcher}>
            <CustomTypography className={clsx('text-14', styles['info-item-value'])}>
              Публичность
            </CustomTypography>
            <PublicStatusPopover
              claimPrivacy={saveClaimPrivacy}
              setStatus={handleImageStatusChange}
              disabled={!selectedProfile}
            />
          </div>
          {(selectedProfile?.type === 'custom' || !selectedProfile) && (
            <>
              <CustomTypography className={clsx('text-14', styles['margin-bottom'])}>
                Атрибут vCard
              </CustomTypography>
              <TextField
                placeholder="Введите атрибут vCard"
                value={saveMappingVcard || undefined}
                onChange={(event) => setMappingVcard(event.target.value)}
                fullWidth
                variant="standard"
                className="custom"
              />
              <CustomTypography className={clsx('text-14', styles['input-subtitle'])} color="grey">
                Укажите название атрибута vcard, с которым будет сопоставлено поле
              </CustomTypography>
            </>
          )}
          {(selectedProfile?.type === 'custom' || !selectedProfile) && (
            <>
              <CustomTypography className={clsx('text-14', styles['margin-bottom'])}>
                Значение по умолчанию
              </CustomTypography>
              <TextField
                placeholder="Введите значение по умолчанию"
                value={saveDefault || undefined}
                onChange={(event) => setDefault(event.target.value)}
                fullWidth
                variant="standard"
                className="custom"
              />
              <CustomTypography className={clsx('text-14', styles['input-subtitle'])} color="grey">
                Укажите значение поля по умолчанию
              </CustomTypography>
            </>
          )}
        </div>
        {selectedProfile?.field === 'phone_number' && (
          <div className={styles['margin-bottom']}>
            <div className={clsx(styles.flex, styles['margin-bottom'])}>
              <CustomTypography className={clsx('text-14')}>
                Настройки подтверждения номера телефона
              </CustomTypography>
              {!phoneProvider?.params && (
                <Button variant="custom2" onClick={() => setIsPhoneProviderOpen(true)}>
                  Добавить
                </Button>
              )}
            </div>
            {phoneProvider && phoneProvider?.params && (
              <div
                data-id="phone-provider-card"
                className={styles.provider}
                onClick={() => setIsPhoneProviderOpen(true)}
              >
                <Avatar src={getImageURL(phoneProvider?.avatar)} className={styles.providerIcon} />
                <div className={styles['provider-name-wrapper']}>
                  <CustomTypography className={clsx('text-14', styles['provider-name'])}>
                    {'issuer' in phoneProvider.params && phoneProvider.params.issuer}
                  </CustomTypography>
                </div>
                <IconWithTooltip
                  dataAttribute={'edit-phone-provider-button'}
                  iconType="edit"
                  onClick={() => setIsPhoneProviderOpen(true)}
                />
                <IconWithTooltip
                  dataAttribute={'delete-phone-provider-button'}
                  iconType="delete"
                  onClick={() => setIsModalDeletePhoneOpen(true)}
                />
              </div>
            )}
          </div>
        )}
        {selectedProfile?.field === 'email' && (
          <div className={styles['margin-bottom']}>
            <div className={clsx(styles.flex, styles['margin-bottom'])}>
              <CustomTypography className={clsx('text-14')}>
                Настройки подтверждения электронной почты
              </CustomTypography>
              {!emailProvider?.params && (
                <Button variant="custom2" onClick={() => setIsEmailProviderOpen(true)}>
                  Добавить
                </Button>
              )}
            </div>
            {emailProvider && emailProvider?.params && (
              <div
                data-id="email-provider-card"
                className={styles.provider}
                onClick={() => setIsEmailProviderOpen(true)}
              >
                <Avatar src={getImageURL(emailProvider?.avatar)} className={styles.providerIcon} />
                <div className={styles['provider-name-wrapper']}>
                  <CustomTypography className={clsx('text-14', styles['provider-name'])}>
                    {'root_mail' in emailProvider.params && emailProvider.params.root_mail}
                  </CustomTypography>
                </div>
                <IconWithTooltip
                  dataAttribute={'edit-email-provider-button'}
                  iconType="edit"
                  onClick={() => setIsEmailProviderOpen(true)}
                />
                <IconWithTooltip
                  dataAttribute={'delete-email-provider-button'}
                  iconType="delete"
                  onClick={() => setIsModalDeleteMailOpen(true)}
                />
              </div>
            )}
          </div>
        )}
        {![RuleFieldNames.agreement, RuleFieldNames.birthdate, RuleFieldNames.picture].includes(
          selectedProfile?.field as RuleFieldNames,
        ) && (
          <>
            <div className={clsx(styles.flex, styles['margin-bottom'])}>
              <CustomTypography className={clsx('text-14')}>Правила валидации</CustomTypography>
              <Button
                variant="custom2"
                onClick={() => setIsEditRuleValidationsOpen(true)}
                disabled={!selectedProfile}
              >
                Настроить
              </Button>
            </div>
            <TextField
              placeholder="Правила валидации не заданы"
              value={listTitleRuleValidations ? listTitleRuleValidations.join(', ') : undefined}
              onChange={(event) => setField(event.target.value)}
              disabled={true}
              fullWidth
              variant="standard"
              className="custom"
            />
          </>
        )}
      </div>
      <EditRuleValidations
        close={() => setIsEditRuleValidationsOpen(false)}
        field_name={saveField}
        isOpen={isEditRuleValidationsOpen}
      />
      <PhoneProvider
        isOpen={isPhoneProviderOpen}
        close={() => setIsPhoneProviderOpen(false)}
        phoneProvider={phoneProvider}
      />
      <EmailProvider
        isOpen={isEmailProviderOpen}
        close={() => setIsEmailProviderOpen(false)}
        emailProvider={emailProvider}
      />
      <ConfirmationModal
        isOpen={isModalDeleteMailOpen}
        onAction={() => {
          emailProvider && emailProvider.id && handleDeleteProvider(emailProvider.id);
          setIsModalDeleteMailOpen(false);
        }}
        onClose={() => setIsModalDeleteMailOpen(false)}
        title={'Удалить провайдер "Электронная почта"'}
        mainMessage={[
          'Провайдер "Электронная почта" будет удален вместе со связанными с ним настройками. Соответствующий способ входа также будет удален.',
        ]}
      />
      <ConfirmationModal
        isOpen={isModalDeletePhoneOpen}
        onAction={() => {
          phoneProvider && phoneProvider.id && handleDeleteProvider(phoneProvider.id);
          setIsModalDeletePhoneOpen(false);
        }}
        onClose={() => setIsModalDeletePhoneOpen(false)}
        title={'Удалить провайдер "Номер телефона"'}
        mainMessage={[
          'Провайдер "Номер телефона" будет удален вместе со связанными с ним настройками. Соответствующий способ входа также будет удален.',
        ]}
      />
    </BaseSidePanel>
  );
};
