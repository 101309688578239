import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import TextField from '@mui/material/TextField';
import styles from './FillProfile.module.css';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import clsx from 'clsx';
import { PasswordTextfield } from '../custom/PasswordTextfield';
import {
  useAddEmailMutation,
  useChangePasswordMutation,
  useUpdateUserMutation,
} from '../../redux/services/user';
import { connect, useDispatch } from 'react-redux';
import { getUserProfile } from '../../requests/oidc';
import {
  convertUserProfile,
  generateYearsBetween,
  isApiErrorWithMessage,
  isDateError,
} from '../../helpers';
import { setUserProfile, TUserProfile } from '../../redux/userSlice';
import { RootState } from '../../redux/store';
import { TAppSlice } from '../../redux/appSlice';
import {
  MailCodeTypes,
  useLazyCheckEmailAvialabilityQuery,
  useSendEmailCodeMutation,
} from '../../redux/services/mail';
import { CustomSelect } from '../custom/CustomSelect';
import { useNavigate } from 'react-router-dom-v5-compat';
import { useGetSettingsQuery } from '../../redux/services/settings';
import { CustomTypography } from '../custom/CustomTypography';

const mapStateToProps = (state: RootState) => ({
  passwordChangeRequired: state.user.userProfile.password_change_required,
  email: state.user.userProfile.email,
  birthdate: state.user.userProfile.birthdate,
  userId: state.user.userProfile.id,
  nickname: state.user.userProfile.nickname,
  isMobile: state.app.isMobile,
});

const FillProfileComponent = ({
  passwordChangeRequired,
  userId,
  nickname,
  isMobile,
}: {
  userId: TUserProfile['id'];
  email: TUserProfile['email'];
  passwordChangeRequired: TUserProfile['password_change_required'];
  birthdate: TUserProfile['birthdate'];
  nickname: TUserProfile['nickname'];
  isMobile: TAppSlice['isMobile'];
}) => {
  const [steps, setSteps] = useState<string[]>([]);
  const [activeStep, setActiveStep] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    if (userId) {
      if (!passwordChangeRequired) navigate('/profile');
      if (passwordChangeRequired) setSteps((steps) => [...steps, 'Пароль']);
    }
  }, [userId]);

  const getFieldByStep = (step: number) => {
    switch (steps[step]) {
      case 'Пароль':
        return (
          <FieldPassword
            isMobile={isMobile}
            userId={userId}
            steps={steps}
            step={activeStep}
            setActiveStep={setActiveStep}
          />
        );
      case 'Электронная почта':
        return (
          <FieldEmail
            isMobile={isMobile}
            nickname={nickname}
            steps={steps}
            userId={userId}
            step={activeStep}
            setActiveStep={setActiveStep}
          />
        );
      case 'Дата рождения':
        return <FieldDateOfBirth isMobile={isMobile} userId={userId} />;
    }
  };

  return (
    <div className={styles.wrapper}>
      <CustomTypography className={clsx('header-2-medium')} style={{ marginBottom: 24 }}>
        Заполнение профиля
      </CustomTypography>
      <CustomTypography className={clsx('text-14')} style={{ marginBottom: 24 }} color="grey">
        Ваш профиль был создан автоматически. Для завершения регистрации укажите необходимые данные.
      </CustomTypography>
      <Stepper style={{ marginBottom: 42 }} className="custom" activeStep={activeStep}>
        {steps.map((label) => {
          const stepProps: { completed?: boolean } = {};
          const labelProps: {
            optional?: React.ReactNode;
          } = {};
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {getFieldByStep(activeStep)}
    </div>
  );
};

export const FillProfile = connect(mapStateToProps)(FillProfileComponent);

const FieldPassword = ({
  step,
  setActiveStep,
  steps,
  userId = '0',
}: {
  userId?: string;
  step: number;
  steps: string[];
  setActiveStep: Dispatch<SetStateAction<number>>;
  isMobile: TAppSlice['isMobile'];
}) => {
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordError, setNewPasswordError] = useState('');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [changePassword] = useChangePasswordMutation();

  const handleChangePassword = async () => {
    try {
      if (!newPassword) return setNewPasswordError('Укажите пароль');
      await changePassword({ newPassword, userId: userId }).unwrap();
      if (step === steps.length - 1) {
        const profile = await getUserProfile();
        if (profile) {
          const convertedProfile = convertUserProfile(profile);
          dispatch(setUserProfile(convertedProfile));
          return navigate('/profile');
        }
      }
      return setActiveStep((prevActiveStep) => prevActiveStep + 1);
    } catch (e) {
      console.log('error: ', e);
    }
  };

  const getNewPasswordError = () => {
    if (!newPassword) return setNewPasswordError('Укажите пароль');
    if (newPassword.length < 8) return setNewPasswordError('Используйте не менее 8 символов');
    if (newPassword.length > 32) return setNewPasswordError('Используйте не более 32 символов');
  };

  return (
    <>
      <CustomTypography style={{ marginBottom: 8 }} className={clsx('text-14')}>
        Пароль
      </CustomTypography>
      <PasswordTextfield
        value={newPassword}
        onChange={(e) => {
          if (newPasswordError) setNewPasswordError('');
          setNewPassword(e.target.value);
        }}
        onBlur={getNewPasswordError}
        className="custom"
        style={{ marginBottom: 24 }}
        FormHelperTextProps={{
          className: clsx('text-14'),
        }}
        error={!!newPasswordError}
        helperText={newPasswordError ? newPasswordError : ''}
        fullWidth
        variant="standard"
      />
      <Button
        className={styles['continue-button']}
        variant={'custom'}
        onClick={() => handleChangePassword()}
      >
        Продолжить
      </Button>
    </>
  );
};

const FieldEmail = ({
  userId,
  step,
  steps,
  setActiveStep,
  nickname,
}: {
  userId: TUserProfile['id'];
  nickname: TUserProfile['nickname'];
  step: number;
  steps: string[];
  setActiveStep: Dispatch<SetStateAction<number>>;
  isMobile: TAppSlice['isMobile'];
}) => {
  const [addEmail] = useAddEmailMutation();
  const [emailError, setEmailError] = useState('');
  const [codeError, setCodeError] = useState<null | string>(null);
  const [isMailSent, setIsMailSent] = useState(false);
  const [newEmail, setNewEmail] = useState('');
  const [[minute, second], setTime] = useState<number[]>([1, 30]);
  const [code, setCode] = useState('');
  const [resendCode, setResendCode] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [sendEmailCode] = useSendEmailCodeMutation();
  const [checkEmailAvialability] = useLazyCheckEmailAvialabilityQuery();

  const getEmailError = () => {
    const emailTest =
      /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/iu;
    if (newEmail) {
      if (!emailTest.test(newEmail)) {
        return setEmailError('Неверный формат адреса электронной почты');
      }
    }
    return setEmailError('');
  };

  const tick = () => {
    if (minute === 0 && second === 0) {
      setTime([0, 0]);
    } else if (second == 0) {
      setTime([minute - 1, 59]);
    } else {
      setTime([minute, second - 1]);
    }
  };

  useEffect(() => {
    setTime([1, 30]);
  }, [isMailSent]);

  useEffect(() => {
    const timerID = setInterval(() => tick(), 1000);
    return () => clearInterval(timerID);
  });

  const handleAddEmail = async () => {
    try {
      if (!code) return setCodeError('Укажите код подтверждения');
      if (newEmail && userId) {
        const result = await addEmail({
          newEmail,
          userId,
          code,
        });

        if ('error' in result) {
          const { error } = result;
          if ('data' in error && isApiErrorWithMessage(error.data)) {
            return setCodeError(error.data.message);
          }
          return setCodeError('Неверный код подтверждения');
        }

        const profile = await getUserProfile();
        if (profile && step === steps.length - 1) {
          const convertedProfile = convertUserProfile(profile);
          dispatch(setUserProfile(convertedProfile));
          return navigate('/profile');
        }
        return setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    } catch (e) {
      console.log('checkEmailCodeError', e);
    }
  };

  const changeEmailInput = () => {
    setIsMailSent(!isMailSent);
  };

  const sendConfirmationCode = async () => {
    try {
      if (!newEmail) return setEmailError('Укажите адрес электронной почты');
      if (userId && newEmail && !emailError) {
        const response = await checkEmailAvialability(newEmail).unwrap();
        if (response.isExist && response.uniqueRole) {
          return setEmailError('Почтовый адрес уже используется');
        }
        const mailCodeResponse = await sendEmailCode({
          type: MailCodeTypes.addEmail,
          userId: userId,
          email: newEmail,
          name: nickname,
          resend: resendCode,
        }).unwrap();
        if (mailCodeResponse?.message === 'code.still.active') {
          setResendCode(true);
          setIsMailSent(true);
        }
        setIsMailSent(true);
        setTime([1, 30]);
      }
    } catch (e) {
      console.log('sendConfirmationCodeError ', e);
    }
  };
  return (
    <>
      <div className={styles['change-email']}>
        <CustomTypography style={{ marginBottom: 8 }} className={clsx('text-14')}>
          Адрес электронной почты
        </CustomTypography>
        <TextField
          disabled={isMailSent}
          value={newEmail}
          onChange={(e) => setNewEmail(e.target.value)}
          className={clsx('custom', styles.textfield)}
          onBlur={getEmailError}
          error={!!emailError}
          helperText={emailError || 'На этот адрес будет отправлен код подтверждения'}
          fullWidth
          variant="standard"
        />
        {isMailSent && (
          <>
            <CustomTypography style={{ marginBottom: 8 }} className={clsx('text-14')}>
              Введите код, отправленный на указанный адрес
            </CustomTypography>
            <TextField
              className={clsx('custom', styles.textfield)}
              value={code}
              onChange={(e) => setCode(e.target.value)}
              error={!!codeError}
              helperText={codeError ? codeError : ''}
              fullWidth
              variant="standard"
            />
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              {minute || second !== 0 ? (
                <CustomTypography className={clsx('text-14', 'custom-2')} color="grey">
                  Выслать код повторно через
                  {` ${minute.toString().padStart(2, '0')}:${second.toString().padStart(2, '0')}`}
                </CustomTypography>
              ) : (
                <Button variant="custom2" onClick={sendConfirmationCode}>
                  Выслать код повторно
                </Button>
              )}
              <Button onClick={changeEmailInput} variant="custom2" style={{ padding: 0 }}>
                Изменить почту
              </Button>
            </div>
          </>
        )}
        <Button
          className={styles['continue-button']}
          variant={'custom'}
          onClick={isMailSent ? handleAddEmail : sendConfirmationCode}
        >
          {isMailSent ? 'Подтвердить' : 'Получить код'}
        </Button>
      </div>
    </>
  );
};

const FieldDateOfBirth = ({
  userId = '0',
}: {
  userId: TUserProfile['id'];
  isMobile: TAppSlice['isMobile'];
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { data: dataSettings, isFetching: dataSettingsFetching } = useGetSettingsQuery();
  const dateNow = new Date();
  const [birthDay, setBirthDay] = useState(dateNow.getDate() - 1);
  const [birthYear, setBirthYear] = useState(dateNow.getFullYear() - (dataSettings?.min_age || 0));
  const months = [
    { name: 'январь', days: 31 },
    { name: 'февраль', days: +birthYear % 4 === 0 ? 29 : 28 },
    { name: 'март', days: 31 },
    { name: 'апрель', days: 30 },
    { name: 'май', days: 31 },
    { name: 'июнь', days: 30 },
    { name: 'июль', days: 31 },
    { name: 'август', days: 31 },
    { name: 'сентябрь', days: 30 },
    { name: 'октябрь', days: 31 },
    { name: 'ноябрь', days: 30 },
    { name: 'декабрь', days: 31 },
  ];
  const [birthMonth, setBirthMont] = useState(months[dateNow.getMonth()]?.name);
  const selectedMonth = months.find((month) => month.name === birthMonth);

  useEffect(() => {
    if (!dataSettingsFetching && dataSettings)
      setBirthYear(dateNow.getFullYear() - dataSettings.min_age);
  }, [dataSettings]);

  useEffect(() => {
    setBirthDay(Math.min(selectedMonth?.days || 0, birthDay));
  }, [birthMonth, birthYear]);

  const birthDate = new Date(
    +birthYear,
    months.findIndex((month) => month.name === birthMonth),
    +birthDay,
  );
  const dateError = isDateError(birthDate, dataSettings);

  const [updateUser] = useUpdateUserMutation();

  const handleContinue = async () => {
    const month = months.findIndex(({ name }) => name === birthMonth);
    if (dateError) return;
    await updateUser({
      userId,
      data: { birthdate: new Date(Date.UTC(+birthYear, month, birthDay)).toISOString() },
    }).unwrap();
    const profile = await getUserProfile();
    if (profile) {
      const convertedProfile = convertUserProfile(profile);
      dispatch(setUserProfile(convertedProfile));
      return navigate('/profile');
    }
  };

  return (
    <>
      <CustomTypography className={clsx('text-14', styles['input-title'])}>
        Дата рождения
      </CustomTypography>
      <div style={{ display: 'flex' }}>
        <CustomSelect
          className={styles.select}
          error={dateError}
          value={birthDay}
          renderValue={(value) => `0${value}`.slice(-2)}
          onChange={(e) => setBirthDay(e.target.value as number)}
        >
          {new Array(selectedMonth?.days).fill(null).map((_, index) => (
            <MenuItem className="custom-select" key={index} value={index + 1}>
              {index + 1}
            </MenuItem>
          ))}
        </CustomSelect>
        <CustomSelect
          className={styles.select}
          error={dateError}
          value={birthMonth}
          onChange={(e) => {
            setBirthMont(e.target.value);
          }}
        >
          {months.map((month) => (
            <MenuItem className="custom-select" key={month.name} value={month.name}>
              {month.name}
            </MenuItem>
          ))}
        </CustomSelect>
        <CustomSelect
          className={styles.select}
          error={dateError}
          value={birthYear}
          onChange={(e) => setBirthYear(e.target.value as number)}
        >
          {generateYearsBetween(
            new Date().getFullYear() - (dataSettings?.max_age || 120),
            new Date().getFullYear() - (dataSettings?.min_age || 0),
          ).map((year) => (
            <MenuItem className="custom-select" key={year} value={year}>
              {year}
            </MenuItem>
          ))}
        </CustomSelect>
      </div>
      {dateError && (
        <CustomTypography style={{ marginTop: 8 }} className={clsx('sf-14-reg')} color="error">
          Дата рождения не соответствует возрастным ограничениям
        </CustomTypography>
      )}
      <Button className={styles['continue-button']} variant={'custom'} onClick={handleContinue}>
        Продолжить
      </Button>
    </>
  );
};
