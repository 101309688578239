import React from 'react';
import LayersOutlinedIcon from '@mui/icons-material/LayersOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import { useTheme } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom-v5-compat';
import TopBar from 'src/components/TopBar';
import { Roles } from '../enums';
import { getImageURL } from '../helpers';
import { RootState } from '../redux/store';
import { sendLogoutRequest } from '../requests/oidc';
import { borderRadius } from './custom/Theme';

export const TopBarWrapper = () => {
  const navigate = useNavigate();
  const location = useLocation().pathname;
  const user = useSelector((state: RootState) => state.user.userProfile);
  const client = useSelector((state: RootState) => state.app.clientProfile);
  const roles = useSelector((state: RootState) => state.user.userProfile.roles);
  const disableMenu = !!user && (user.password_change_required || user.deleted);
  const theme = useTheme();
  const role = roles?.find((item) =>
    [Roles.ADMIN, Roles.EDITOR, Roles.OWNER].includes(item.role),
  ) || { role: Roles.USER };

  const customStylesProps = {
    ...(borderRadius && { borderRadius }),
    color: theme.palette.custom.onAccentColor,
    backgroundColor: theme.palette.custom.accent,
    hover: theme.palette.custom.accentHover,
  };

  const sectionName = location.startsWith(`/profile`)
    ? 'ID'
    : location.startsWith(`/applications`)
    ? 'Приложения'
    : '';

  const items = [
    {
      title: 'ID',
      onClick: () => {
        navigate('profile');
      },
      icon: <PersonOutlineOutlinedIcon />,
      allowedRoles: [Roles.ADMIN, Roles.EDITOR, Roles.OWNER, Roles.USER, Roles.TRUSTED_USER],
    },
    {
      title: 'Приложения',
      onClick: () => {
        navigate('applications');
      },
      icon: <LayersOutlinedIcon />,
      allowedRoles: [Roles.ADMIN, Roles.EDITOR, Roles.OWNER],
    },
  ];

  return (
    <TopBar
      projectInfo={{
        items,
        logoUrl: getImageURL(client?.avatar) || '',
        menuButtonDisabled: !!disableMenu,
        onExitClick: sendLogoutRequest,
        projectName: client?.name || '',
        sectionName: sectionName,
      }}
      userInfo={{
        role: role?.role,
        username: user.nickname,
        avatarUrl: getImageURL(user?.picture),
      }}
      customStyles={customStylesProps}
    />
  );
};
