import { Switch, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import clsx from 'clsx';
import React, { FC, useEffect, useState, ChangeEvent } from 'react';
import styles from './SentryPanel.module.css';
import { BaseSidePanel } from 'src/components/sidePanel/BaseSidePanel';
import { CustomTypography } from 'src/components/custom/CustomTypography';
import { getSentry, TSentry, updateSentry } from './requests';
import { setNotice } from 'src/redux/noticesSlice';
import { useDispatch } from 'react-redux';

type TSentryPanel = {
  isOpen: boolean;
  onClose: () => void;
};

/**
 * Панель редактирования настроек Sentry
 */
export const SentryPanel: FC<TSentryPanel> = ({ isOpen, onClose }) => {
  const [sentry, setSentry] = useState<TSentry>({
    dsn: null,
    user_id: '',
    enabled: false,
  });
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getSentry();
        setSentry(data);
      } catch (error) {
        console.log('fetchData error: ', error);
        dispatch(
          setNotice({
            id: Math.random(),
            isRead: false,
            message: 'Не удалось получить данные о Sentry',
            timestamp: new Date().toString(),
          }),
        );
      }
    };

    fetchData();
  }, []);

  const handleChange = (field: keyof TSentry) => (event: ChangeEvent<HTMLInputElement>) => {
    let value: string | boolean | null = null;
    switch (field) {
      case 'enabled':
        value = event.target.checked;
        break;
      case 'dsn':
        value = event.target.value === '' ? null : event.target.value;
        break;

      default:
        value = event.target.value;
        break;
    }
    setSentry({ ...sentry, [field]: value });
  };

  const handleSave = async () => {
    try {
      await updateSentry(sentry);
      onClose();
    } catch (error) {
      dispatch(
        setNotice({
          id: Math.random(),
          isRead: false,
          message: `Не удалось сохранить данные Sentry. ${error}`,
          timestamp: new Date().toString(),
        }),
      );
      console.error('Failed to save the sentry:', error);
    }
  };

  return (
    <BaseSidePanel
      handleClosePanel={onClose}
      isOpenPanel={isOpen}
      title="Настроить Sentry"
      description="Настройте отправку трассировок и ошибок в Sentry"
      isNoBackdrop={false}
      handleConfirm={handleSave}
    >
      <div className={styles.container}>
        <Box className={styles.inputWrapper}>
          <CustomTypography className={clsx('text-14', styles.label)}>DSN</CustomTypography>
          <TextField
            variant="outlined"
            fullWidth
            value={sentry.dsn || null}
            onChange={handleChange('dsn')}
          />
          <CustomTypography className={clsx('text-14', styles.description)} color="grey">
            DSN — это уникальный ключ, который выдается для каждого проекта в Sentry
          </CustomTypography>
        </Box>
        <Box className={styles.switchWrapper}>
          <CustomTypography className={clsx('text-14', styles.label)}>Активность</CustomTypography>
          <Switch checked={sentry.enabled} onChange={handleChange('enabled')} color="primary" />
        </Box>
        <CustomTypography className={clsx('text-14', styles.asterisk, styles['margin-top'])}>
          ID пользователя
        </CustomTypography>
        <TextField
          variant="outlined"
          fullWidth
          value={sentry.user_id || ''}
          onChange={handleChange('user_id')}
        />
        <CustomTypography className={clsx('text-14', styles.asterisk, styles['margin-top'])}>
          ID пользователя, по действиям которого нужно отправлять трассировки и ошибки
        </CustomTypography>
      </div>
    </BaseSidePanel>
  );
};
