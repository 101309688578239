import { SnackbarProvider } from 'notistack';
import React, { useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider, useDispatch } from 'react-redux';
import { App } from './App';
import { CustomSnackbar, ESnackBarVariant } from './components/custom/CustomSnackbar';
import { CLIENT_ID } from './constants';
import { ErrorBoundary } from './ErrorBoundary';
import './fonts.css';
import './index.css';
import { setClientProfile } from './redux/appSlice';
import { useGetClientInfoQuery } from './redux/services/clients';
import store from './redux/store';
import reportWebVitals from './reportWebVitals';
import { getImageURL } from './helpers';

declare module 'notistack' {
  interface VariantOverrides {
    customSnackbar: {
      avatar?: string | null;
      snackbarVariant: ESnackBarVariant;
    };
  }
}

const AppWithDynamicTitle: React.FC = () => {
  const dispatch = useDispatch();
  const { data: client } = useGetClientInfoQuery({ id: CLIENT_ID });

  useEffect(() => {
    if (client) {
      dispatch(setClientProfile(client));
      document.title = client.name || '';
      const link = document.querySelector("link[rel='icon']") as HTMLLinkElement;
      if (link) {
        link.href = getImageURL(client.avatar) || '';
      }
    }
  }, [client, dispatch]);

  return <App />;
};

const container = document.getElementById('root');
if (!container) {
  throw new Error('Failed to find the root element');
}
const root = createRoot(container);

root.render(
  <Provider store={store}>
    <ErrorBoundary>
      <SnackbarProvider
        maxSnack={1}
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'bottom',
        }}
        Components={{
          customSnackbar: CustomSnackbar,
        }}
      >
        <AppWithDynamicTitle />
      </SnackbarProvider>
    </ErrorBoundary>
  </Provider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
