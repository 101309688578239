import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import clsx from 'clsx';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CLIENT_ID, LOADING_TEXT } from 'src/constants';
import { Roles } from 'src/enums';
import { formatDate, getImageURL, getRoleName } from 'src/helpers';
import { setNotice, TNotice } from 'src/redux/noticesSlice';
import {
  TClientFull,
  useDeleteSessionMutation,
  useDeleteUserRoleClientMutation,
  useUpdateUserRoleClientMutation,
} from 'src/redux/services/clients';
import {
  TUser,
  TUserWithRole,
  useBlockUserMutation,
  useDeleteUserMutation,
  useUnblockUserMutation,
} from 'src/redux/services/users';
import { CustomMuiIcon } from '../custom/CustomMuiIcon';
import { CustomTypography } from '../custom/CustomTypography';
import { ThemeColors } from '../custom/Theme';
import { CustomRadioButton } from '../custom/CustomRadioButton';
import { IConfirmationModalProps } from '../modal/ConfirmationModal';
import { ModalInfo } from '../modal/ModalInfo';
import { ActionButtons } from '../shared/ActionButtons';
import { Card, ICardProps } from '../shared/Card';
import { MenuControls } from '../shared/MenuControls';
import styles from './UserCard.module.css';
import { getDisplayName } from './utils';
import { RootState } from 'src/redux/store';

export interface IUserCardProps extends ICardProps {
  items: TUserWithRole[];
  index: number;
  onClick: (userId?: number) => void;
  setConfirmModalProps: Dispatch<SetStateAction<IConfirmationModalProps>>;
  updateItems: (items: TUserWithRole[], totalCount: number) => void;
  client?: TClientFull;
}

const UserCardComponent: React.FC<IUserCardProps> = (props) => {
  const { items, index, onClick, setConfirmModalProps, updateItems, client } = props;
  const { user, role } = items[index] || {};
  const clientId = client?.client_id || CLIENT_ID;

  //Состояния
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [isRoleModalOpen, setIsRoleOpenModal] = useState(false);
  const [userRole, setUserRole] = useState<Roles>(role);
  const clientProfile = useSelector((state: RootState) => state.app.clientProfile);
  const PROJECT_NAME = clientProfile?.name || 'приложении';

  const dispatch = useDispatch();

  //Запросы
  const [deleteSession] = useDeleteSessionMutation();
  const [deleteUser] = useDeleteUserMutation();
  const [blockUser] = useBlockUserMutation();
  const [unblockUser] = useUnblockUserMutation();
  const [updateUserRoleClient] = useUpdateUserRoleClientMutation();
  const [deleteUserRoleClient] = useDeleteUserRoleClientMutation();

  //#region Шаблоны для отображения текста
  const textWrapper = (text: string) => {
    return user ? text || '' : LOADING_TEXT;
  };

  const getUserStatus = (user: TUser) => {
    let stateStatus: { text: string; color?: ThemeColors } = {
      text: 'Активный',
      color: undefined,
    };
    if (user?.blocked) {
      stateStatus = { text: 'Заблокированный', color: 'error' };
    } else if (user?.deleted) {
      const deleteDate = new Date(user?.deleted);
      stateStatus = { text: `Будет удален ${formatDate(deleteDate)}`, color: 'grey' };
    }

    return (
      <CustomTypography className={clsx('text-14', styles.infoTitle)} color={stateStatus.color}>
        {stateStatus.text}
      </CustomTypography>
    );
  };

  const getRadioButton = (
    item: { title: string; description: string; role: Roles },
    index: number,
  ) => {
    return (
      <div key={index}>
        <CustomRadioButton
          label={item.title}
          checked={userRole === item.role}
          onClick={() => {
            setUserRole(item.role);
          }}
        />
        <CustomTypography
          style={{ marginLeft: 40, marginBottom: 16 }}
          className={'text-12'}
          color="grey"
        >
          {item.description}
        </CustomTypography>
      </div>
    );
  };
  //#endregion

  //#region Выбор прав пользователя
  const baseRoles: { title: string; description: string; role: Roles }[] = [
    {
      title: 'Участник',
      description: 'Имеет доступ в приложение и может просматривать профиль приложения',
      role: Roles.USER,
    },
    {
      title: 'Администратор',
      description: `Имеет полный контроль над всеми пользователями ${
        clientId === CLIENT_ID ? 'и приложениями' : 'в приложении'
      }`,
      role: Roles.EDITOR,
    },
  ];

  const rolesForChoose = baseRoles;
  if (clientId === CLIENT_ID) {
    rolesForChoose.push({
      title: 'Администратор приложений',
      description:
        'Может создавать свои приложения, управлять полномочиями участников своих приложений, просматривать персональные данные участников своих приложений',
      role: Roles.ADMIN,
    });
  }

  const handleCancelSaveRole = () => {
    setIsRoleOpenModal(false);
    setUserRole(role);
  };

  const handleSaveRole = async (items: TUserWithRole[], role: Roles, userId: number) => {
    const notice = (isSuccess: boolean): TNotice => {
      return {
        id: Math.random(),
        isRead: false,
        message: isSuccess
          ? `Права пользователя ${getDisplayName(user)} в ${PROJECT_NAME} изменены.`
          : `Не удалось изменить права пользователя ${getDisplayName(user)} в ${PROJECT_NAME}.`,
        timestamp: new Date().toString(),
        avatar: user?.picture,
      };
    };

    try {
      if (role) {
        await updateUserRoleClient({
          clientId,
          userId: userId.toString(),
          role,
        }).unwrap();
        const updatedItems = items.map((item) =>
          item.user.id === userId ? { user: item.user, role } : item,
        );
        updateItems(updatedItems, updatedItems.length);
        dispatch(setNotice(notice(true)));
      } else {
        throw Error;
      }
    } catch (error) {
      const not = notice(false);
      dispatch(setNotice(not));
    }

    setIsRoleOpenModal(false);
  };
  //#endregion

  //#region Модальное окно подтверждения действия
  const onCloseConfirmModal = () => {
    setConfirmModalProps((prev) => {
      return { ...prev, isOpen: false };
    });
  };

  const handleDeleteSessions = async (userId: string, clientId: string) => {
    try {
      await deleteSession({ userId, clientId }).unwrap();
    } catch (error) {
      console.error('rejected', error);
    }
    onCloseConfirmModal();
  };

  const handleBlocked = async (items: TUserWithRole[], role: Roles, userId: number) => {
    try {
      let isBlocked = false;
      if (user.blocked) {
        await unblockUser({ id: user.id.toString() }).unwrap();
      } else {
        await blockUser({ id: user.id.toString() }).unwrap();
        isBlocked = true;
      }

      const updatedItems = items.map((item) =>
        item.user.id === userId ? { user: { ...item.user, blocked: isBlocked }, role } : item,
      );
      updateItems(updatedItems, items.length);
    } catch (error) {
      console.error('rejected', error);
    }
    onCloseConfirmModal();
  };

  const handleRemove = async (items: TUserWithRole[], id: number) => {
    try {
      if (clientId === CLIENT_ID) {
        await deleteUser({ id: id.toString() }).unwrap();
      } else {
        await deleteUserRoleClient({
          clientId,
          userId: id.toString(),
        }).unwrap();
      }
      const updatedItems = items.filter(({ user }) => user?.id !== id);
      updateItems(updatedItems, updatedItems?.length);
    } catch (error) {
      console.error('rejected', error);
    }
    onCloseConfirmModal();
  };

  const confirmModalPrams = [
    {
      onAction: () => {
        handleDeleteSessions(user?.id?.toString(), clientId);
      },
      actionButtonText: 'Завершить',
      mainMessage: [`Все сессии и токены для пользователя ${getDisplayName(user)} будут удалены`],
    },
    {
      onAction: () => {
        handleBlocked(items, role, user?.id);
      },
      actionButtonText: `${user?.blocked ? 'Разблокировать' : 'Заблокировать'}`,
      mainMessage: [
        `Пользователь ${getDisplayName(user)} ${
          user?.blocked ? 'получит' : 'потеряет'
        } доступ к приложениям, в которых для входа использовал аккаунт ${PROJECT_NAME} 
        Данные, связанные с аккаунтом, будут сохранены.`,
      ],
    },
    {
      onAction: () => {
        handleRemove(items, user?.id);
      },
      actionButtonText: 'Удалить',
      mainMessage:
        clientId === CLIENT_ID
          ? [
              `Аккаунт ${getDisplayName(user)} будет удален. 
        Данные, связанные с аккаунтом, удалятся навсегда. 
        Пользователь потеряет доступ к приложениям, в которых для входа использовал аккаунт ${PROJECT_NAME}`,
            ]
          : [`Пользователь ${getDisplayName(user)} будет удален из приложения.`],
    },
  ];
  //#endregion

  //#region Меню выбора действия над пользователем
  const handleOpenMenuControl = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenuControl = () => {
    setAnchorEl(null);
  };

  const userControls = [
    {
      icon: ManageAccountsOutlinedIcon,
      title: 'Изменить права',
      action: () => {
        handleCloseMenuControl();
        setIsRoleOpenModal(true);
      },
      addDivider: true,
    },
    {
      icon: LogoutOutlinedIcon,
      title: 'Завершить сеансы',
      action: async () => {
        handleCloseMenuControl();
        setConfirmModalProps({
          title: 'Завершить сеансы',
          isOpen: true,
          onClose: onCloseConfirmModal,
          ...confirmModalPrams[0],
        });
      },
    },
    {
      icon: LockOutlinedIcon,
      title: `${user?.blocked ? 'Разблокировать' : 'Заблокировать'} в ${PROJECT_NAME}`,
      action: async () => {
        handleCloseMenuControl();
        setConfirmModalProps({
          title: `${user?.blocked ? 'Разблокировать' : 'Заблокировать'} в ${PROJECT_NAME}`,
          isOpen: true,
          onClose: onCloseConfirmModal,
          ...confirmModalPrams[1],
        });
      },
    },
    {
      icon: DeleteOutlineOutlinedIcon,
      title: `Удалить ${clientId === CLIENT_ID ? 'аккаунт' : 'из приложения'}`,
      action: async () => {
        handleCloseMenuControl();
        setConfirmModalProps({
          title: 'Удалить аккаунт',
          isOpen: true,
          onClose: onCloseConfirmModal,
          ...confirmModalPrams[2],
        });
      },
    },
  ];
  //#endregionw

  return (
    <>
      <Card
        {...props}
        cardId={user?.id?.toString()}
        isImage
        avatarUrl={user?.picture}
        onClick={() => onClick(user.id)}
        DefaultIcon={PersonOutlineOutlinedIcon}
        content={
          <div className={styles.content}>
            <div className={styles.userInfo}>
              <Box className={styles.userMainInfo}>
                <CustomTypography className={clsx('text-14', styles.userTitle)}>
                  {textWrapper(user?.given_name)} {textWrapper(user?.family_name)}
                </CustomTypography>
                <CustomTypography color="grey" className={clsx('text-12')}>
                  {textWrapper(user?.nickname)}
                </CustomTypography>
                <CustomTypography color="grey" className={clsx('text-12')}>
                  {textWrapper(`Id ${user?.id}`)}
                </CustomTypography>
              </Box>
              <Box className={styles.userRole}>
                <CustomTypography color="grey" className={clsx('text-12')}>
                  Права:
                </CustomTypography>
                <CustomTypography className={clsx('text-14', styles.infoTitle)}>
                  {textWrapper(getRoleName(role))}
                </CustomTypography>
              </Box>
              <Box className={styles.userStatus}>
                <CustomTypography color="grey" className={clsx('text-12')}>
                  Статус:
                </CustomTypography>
                {getUserStatus(user)}
              </Box>
            </div>
            <div className={styles.moreButtonWrapper}>
              <Button
                data-id="open-menu-controls-button"
                onClick={handleOpenMenuControl}
                className={styles.moreButton}
              >
                <CustomMuiIcon Icon={MoreHorizOutlinedIcon} color="grey" />
              </Button>
              <MenuControls
                anchorEl={anchorEl}
                handleClosePopover={handleCloseMenuControl}
                controls={userControls}
              />
            </div>
          </div>
        }
      />
      <ModalInfo
        isOpen={isRoleModalOpen}
        title={'Изменить права'}
        onClose={() => setIsRoleOpenModal(false)}
      >
        <>
          <CustomTypography style={{ marginBottom: 16 }} className={clsx('text-14')}>
            Выберите права в {client ? client.name : PROJECT_NAME} для пользователя:
          </CustomTypography>
          <div style={{ display: 'flex', alignItems: 'center', marginBottom: 32 }}>
            <Avatar src={getImageURL(user?.picture)} className={styles.avatar}>
              {!user?.picture && (
                <CustomTypography className={clsx('text-14')}>
                  {getDisplayName(user, true)
                    ?.split(' ')
                    .map((name: string) => name[0]?.toUpperCase())
                    .join('')}
                </CustomTypography>
              )}
            </Avatar>
            <CustomTypography className={clsx('text-14')}>{getDisplayName(user)}</CustomTypography>
          </div>
          <CustomTypography style={{ marginBottom: 16 }} className={clsx('text-15-medium')}>
            Уровень полномочий
          </CustomTypography>
          {rolesForChoose.map((item, index) => getRadioButton(item, index))}
          <ActionButtons
            handleCancel={handleCancelSaveRole}
            confirmTitle={'Сохранить'}
            handleConfirm={() => handleSaveRole(items, userRole, user?.id)}
          />
        </>
      </ModalInfo>
    </>
  );
};

export const UserCard = UserCardComponent;
