import { yupResolver } from '@hookform/resolvers/yup';
import { Avatar, ListItem, Switch } from '@mui/material';
import Button from '@mui/material/Button';
import clsx from 'clsx';
import { FC, useEffect, useRef, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom-v5-compat';
import { TClientFull } from 'src/redux/services/clients';
import * as yup from 'yup';
import { getImageURL, getProviderTitleByType, isObjectEmpty } from '../../helpers';
import { ReactComponent as IdIcon } from '../../icons/Id.svg';
import { setApplicationFormChanged, setClientProfile } from '../../redux/appSlice';
import { setNotice } from '../../redux/noticesSlice';
import {
  EGetProviderAction,
  ProviderType,
  TMiscProvider,
  providerApi,
  useGetProvidersQuery,
} from '../../redux/services/provider';
import {
  TProfileField,
  TRule,
  settingsApi,
  useEditSettingsMutation,
  useGetProfileFieldsQuery,
  useGetSettingsQuery,
} from '../../redux/services/settings';
import { useGetUserQuery } from '../../redux/services/user';
import { RootState } from '../../redux/store';
import { EditEmailTemplates } from '../applications/old/EditEmailTemplates';
import { EditProfileField } from '../applications/old/EditProfileField';
import { EditRuleValidations } from '../applications/old/EditRuleValidations';
import { IEmailTemplate } from '../applications/old/EmailTemplate';
import { ProfileField } from '../applications/old/ProfileField';
import { CustomRadioButton } from '../custom/CustomRadioButton';
import { CustomTypography } from '../custom/CustomTypography';
import { AdvancedSettings } from '../extensions/Settings';
import { ConfirmationModal } from '../modal/ConfirmationModal';
import { EditProviders } from '../Providers/editProvidersPanel/EditProviders';
import { IconWithTooltip } from '../shared/IconWithTooltip';
import { ClientSettingsHeader } from './clientSettings/ClientSettingsHeader';
import { EditWidgetPanel } from './editWidgetPanel/EditWidgetPanel';
import { ViewWidget } from './editWidgetPanel/ViewWidget';
import styles from './Settings.module.css';
import { SettingsItem } from './SettingsItem';

export const editProfileSchema = (rules: TRule[]): yup.AnyObjectSchema => {
  if (!rules) return yup.object();
  return generateValidationSchema(rules.filter((rule) => rule.field_name !== 'password'));
};

export const generateValidationSchema = (rules: TRule[]) => {
  const schemaFields = rules.reduce((schema, field) => {
    if (!field.active) return schema;

    let fieldValidations = yup.string().nullable().notRequired();

    // Применение всех активных правил валидации для поля
    field.validations.forEach((validation) => {
      if (validation.active) {
        fieldValidations = fieldValidations.test({
          name: `${field.field_name}-regex`,
          message: validation.error,
          test: (value) => {
            if (field.required && (value === null || value === undefined || value === '')) {
              return false; // пропускаем проверку, если поле обязательное и пустое
            }
            // Применяем regex только если поле не пустое
            if (value === null || value === undefined || value === '') {
              return true; // пропускаем проверку, если поле пустое
            }
            const result = new RegExp(validation.regex).test(value);
            return result;
          },
        });
      }
    });

    return {
      ...schema,
      [field.field_name]: fieldValidations,
    };
  }, {});

  return yup.object().shape(schemaFields).required();
};

export enum RegistrationPolicyVariants {
  allowed = 'allowed',
  allowed_autoregistration_only = 'allowed_autoregistration_only',
  disabled = 'disabled',
}

export type TSettingsInputs = {
  avatar: File | string | null;
  registration_policy: string;
  allow_everyone_to_create_applications: boolean;
  authorize_only_admins: boolean;
  default_public_profile_claims_oauth: string;
  default_public_profile_claims_gravatar: string;
  phone_provider_enabled: boolean;
};

const schema = yup
  .object({
    registration_policy: yup.string().required('Обязательно поле'),
    allow_everyone_to_create_applications: yup.boolean().required('Обязательно поле'),
    authorize_only_admins: yup.boolean().required('Обязательно поле'),
  })
  .required();

interface TSettingsProps {
  selectedClient: TClientFull;
}

export const Settings: FC<TSettingsProps> = ({ selectedClient }) => {
  const dispatch = useDispatch();
  const savedCallback = useRef<() => void>();
  const { openedFromMenu } = useParams<{ openedFromMenu: string }>();
  const applicationFormChanged = useSelector(
    (state: RootState) => state.app.applicationFormChanged,
  );

  const { data: dataSettings } = useGetSettingsQuery();
  const { data: profileFields } = useGetProfileFieldsQuery();
  const { data: providers = [] } = useGetProvidersQuery({
    client_id: selectedClient.client_id,
    action: EGetProviderAction.all,
  });
  const [editSettings, editSettingsResult] = useEditSettingsMutation();

  const [avatarSrc, setAvatarSrc] = useState<string | null>(null);
  const [coverSrc, setCoverSrc] = useState<string | null>(null);
  const [saveModalOpen, setSaveModalOpen] = useState(false);
  const [profileModalOpen, setProfileModalOpen] = useState(false);
  const [providerModalOpen, setProviderModalOpen] = useState(false);
  const [isWidgetPanelOpen, setIsWidgetPanelOpen] = useState<boolean>(false);
  const [isEmailTemplatesModalOpen, setEmailTemplatesModalOpen] = useState(false);
  const [savePasswordPolicyModalOpen, setSavePasswordPolicyModalOpen] = useState(false);
  // TODO: для phoneProvider принимать и передавать только необходимые параметры: issuer, external_client_id, external_client_secret, phoneProvider.id
  // TODO: для emailProvider тоже принимать только необходимые параметры
  const [phoneProvider, setPhoneProvider] = useState<TMiscProvider | undefined>(undefined);
  const [emailProvider, setEmailProvider] = useState<TMiscProvider | undefined>(undefined);
  const [selectedProfileField, setSelectedProfileField] = useState<TProfileField | undefined>(
    undefined,
  );
  const { refetch: getUser } = useGetUserQuery();
  const emailTemplates: IEmailTemplate[] = dataSettings?.email_templates || [];
  const generalProfileFields = profileFields?.filter((item) => item.type === 'general');
  const customProfileFields = profileFields?.filter((item) => item.type === 'custom');

  useEffect(() => {
    if (editSettingsResult.isSuccess) {
      dispatch(
        setNotice({
          id: Math.random(),
          isRead: false,
          message: `Настройки личного кабинета сохранены.`,
          timestamp: new Date().toString(),
        }),
      );
    }
  }, [editSettingsResult]);

  useEffect(() => {
    getUser();
  }, [profileFields]);

  useEffect(() => {
    return () => {
      dispatch(setApplicationFormChanged(false));
    };
  }, []);

  useEffect(() => {
    if (providers.length) {
      if (selectedProfileField?.field === 'phone_number') {
        const phoneProvider = providers.find((provider) => provider.type === ProviderType.PHONE);

        if (phoneProvider && phoneProvider.type === ProviderType.PHONE) {
          setPhoneProvider(phoneProvider);
        } else {
          setPhoneProvider(undefined);
        }
      }

      if (selectedProfileField?.field === 'email') {
        const emailProvider = providers.find((provider) => provider.type === ProviderType.EMAIL);

        if (emailProvider && emailProvider.type === ProviderType.EMAIL) {
          setEmailProvider(emailProvider);
        } else {
          setEmailProvider(undefined);
        }
      }
    }
  }, [selectedProfileField, providers]);

  const methods = useForm<TSettingsInputs>({
    resolver: yupResolver(schema),
    defaultValues: {
      authorize_only_admins: dataSettings?.authorize_only_admins,
      allow_everyone_to_create_applications: dataSettings?.allow_everyone_to_create_applications,
      registration_policy: dataSettings?.registration_policy,
    },
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });

  const {
    control,
    resetField,
    handleSubmit,
    formState: { errors, dirtyFields },
  } = methods;

  useEffect(() => {
    if (!dataSettings) return;

    const fieldsToReset = [
      'authorize_only_admins',
      'allow_everyone_to_create_applications',
      'registration_policy',
    ] as const;

    fieldsToReset.forEach((fieldName) => {
      const fieldValue = dataSettings[fieldName];
      if (fieldValue !== undefined) {
        resetField(fieldName, { defaultValue: fieldValue });
      }
    });
  }, [dataSettings]);

  useEffect(() => {
    const isDirty = !isObjectEmpty(dirtyFields);
    if (applicationFormChanged !== isDirty) dispatch(setApplicationFormChanged(isDirty));
  }, [Object.values(dirtyFields)]);

  const closeSaveModal = () => setSaveModalOpen(false);

  const onSubmit: SubmitHandler<TSettingsInputs> = async (data) => {
    if (Object.keys(errors).length) return;

    const payload = Object.entries(data).map(([key, value]) => {
      return [key, value];
    });

    const isKeyOfGeneralSettings = (
      key:
        | string
        | number
        | boolean
        | string[]
        | File
        | {
            name: string;
            value: string;
          }[]
        | null,
    ) => {
      return (
        typeof key === 'string' &&
        [
          'registration_policy',
          'allow_everyone_to_create_applications',
          'authorize_only_admins',
          'default_public_profile_claims_oauth',
          'default_public_profile_claims_gravatar',
          'phone_provider_enabled',
        ].includes(key)
      );
    };

    const updatedClient = await editSettings(
      Object.fromEntries(payload.filter(([key]) => isKeyOfGeneralSettings(key))),
    ).unwrap();

    dispatch(providerApi.util.invalidateTags(['Providers']));
    dispatch(setClientProfile(updatedClient));

    dispatch(
      settingsApi.endpoints.getSettings.initiate(undefined, {
        subscribe: false,
        forceRefetch: true,
      }),
    );
  };

  const listGeneralProfileFields = generalProfileFields?.map((item) => {
    return (
      <ProfileField
        onClick={() => {
          setProfileModalOpen(true);
          setSelectedProfileField(item);
        }}
        key={item.field}
        profile={item}
      />
    );
  });

  const listCustomProfileFields = customProfileFields?.map((item) => {
    return (
      <ProfileField
        onClick={() => {
          setProfileModalOpen(true);
          setSelectedProfileField(item);
        }}
        key={item.field}
        profile={item}
        deleted={true}
      />
    );
  });

  //TODO Проверить есть ли необходимость в openedFromMenu

  return (
    <>
      <div className={'wrapper-scroll'}>
        <div className={'content'}>
          {!!openedFromMenu && (
            <div className={styles['header-text']}>
              <CustomTypography className={clsx('font-golos', 'text-24-medium', styles.title)}>
                Настройки личного кабинета
              </CustomTypography>
              <CustomTypography
                style={{ marginBottom: 14 }}
                className={clsx('text-14')}
                color="grey"
              >
                Добавляйте способы входа, управляйте доступом к личному кабинету и настраивайте
                внешний вид виджета для авторизации пользователей.
              </CustomTypography>
            </div>
          )}

          <ClientSettingsHeader
            coverSrc={coverSrc}
            setCoverSrc={setCoverSrc}
            avatarSrc={avatarSrc}
            client={selectedClient}
            setAvatarSrc={setAvatarSrc}
            openedFromMenu={openedFromMenu}
          />

          <SettingsItem
            title="Способы входа"
            onClick={() => {
              setProviderModalOpen(true);
            }}
            children={
              <div className={styles.providers}>
                {providers
                  .filter((provider) => provider.is_active)
                  .map((provider) => {
                    return (
                      <div key={provider.id} className={styles.provider}>
                        <Avatar
                          src={getImageURL(provider.avatar)}
                          className={styles['provider-icon-wrapper']}
                        >
                          {!provider.avatar && <IdIcon />}
                        </Avatar>
                        <div className={styles['provider-name-wrapper']}>
                          <CustomTypography className={clsx('text-14', styles['provider-name'])}>
                            {provider.name}
                          </CustomTypography>
                          <CustomTypography className={clsx('text-12')} color="grey">
                            {getProviderTitleByType(provider.type)}
                          </CustomTypography>
                        </div>
                        {selectedClient.required_providers_ids.includes(provider.id.toString()) && (
                          <IconWithTooltip
                            iconType="starFilled"
                            customTitle=""
                            buttonSx={{}}
                            description="Для входа в приложение требуется
                                 наличие способа входа в профиле пользователя"
                            customStyleButton={styles.requiredButton}
                          />
                        )}
                      </div>
                    );
                  })}
              </div>
            }
          />

          <SettingsItem title="Настройка профиля пользователя">
            <CustomTypography className={clsx('text-14', styles['input-subtitle'])} color="grey">
              Настройте параметры полей профиля и добавьте идентификаторы, которые должны быть у
              пользователя в профиле
            </CustomTypography>
            <CustomTypography
              style={{ marginTop: 32 }}
              className={clsx('text-17-regular', 'font-golos')}
            >
              Парольная политика
            </CustomTypography>
            <ListItem
              className={styles['password-item']}
              onClick={() => setSavePasswordPolicyModalOpen(true)}
            >
              <div className={styles['password-content']}>
                <div className={styles['password-head']}>
                  <CustomTypography className={clsx('text-14', styles['password-title'])}>
                    Пароль
                  </CustomTypography>
                  <CustomTypography color="grey" className="text-12">
                    password
                  </CustomTypography>
                </div>
              </div>
            </ListItem>
            <CustomTypography
              style={{ marginTop: 32 }}
              className={clsx('text-17-regular', 'font-golos')}
            >
              Основная информация
            </CustomTypography>
            {listGeneralProfileFields}
            <CustomTypography
              style={{ marginTop: 32 }}
              className={clsx('text-17-regular', 'font-golos')}
            >
              Дополнительная информация
            </CustomTypography>
            <Button
              variant="custom2"
              className={styles['add-button']}
              onClick={() => setProfileModalOpen(true)}
            >
              Добавить
            </Button>
            {listCustomProfileFields}
          </SettingsItem>

          <SettingsItem title="Шаблоны писем" onClick={() => setEmailTemplatesModalOpen(true)} />

          <form onSubmit={handleSubmit(onSubmit)}>
            <SettingsItem title="Настройки доступа">
              <div className={styles['input-wrapper']}>
                <div className={styles['switch-wrapper']}>
                  <div>
                    <CustomTypography className={clsx('text-14')}>
                      Ограниченный доступ для всех приложений
                    </CustomTypography>
                    <CustomTypography className={clsx('text-12')} color="grey">
                      Вход в любое приложение доступно только для пользователей с правами
                      “Администратор”
                    </CustomTypography>
                  </div>
                  <Controller
                    control={control}
                    name="authorize_only_admins"
                    defaultValue={false}
                    render={({ field }) => (
                      <Switch
                        checked={field.value}
                        onChange={(e) => field.onChange(e.target.checked)}
                      />
                    )}
                  />
                </div>
              </div>
              <div className={styles['input-wrapper']}>
                <div className={styles['switch-wrapper']}>
                  <div>
                    <CustomTypography className={clsx('text-14')}>
                      Регистрация пользователей с правами “Администратор приложений”
                    </CustomTypography>
                    <CustomTypography className={clsx('text-12')} color="grey">
                      Новые пользователи будут зарегистрированы с правами администратора приложений
                    </CustomTypography>
                  </div>
                  <Controller
                    control={control}
                    name="allow_everyone_to_create_applications"
                    defaultValue={false}
                    render={({ field }) => (
                      <Switch
                        checked={field.value}
                        onChange={(e) => field.onChange(e.target.checked)}
                      />
                    )}
                  />
                </div>
              </div>
              <div className={styles['input-wrapper']}>
                <CustomTypography className={clsx('text-14', styles['input-title'])}>
                  Запрет регистрации
                </CustomTypography>
                <div className={styles['radio-wrapper']}>
                  <Controller
                    control={control}
                    name={'registration_policy'}
                    render={({ field: { onChange, value, ref } }) => (
                      <>
                        <CustomRadioButton
                          inputRef={ref}
                          className={styles.radio}
                          label={
                            <CustomTypography className={clsx('text-14')}>
                              Регистрация запрещена
                            </CustomTypography>
                          }
                          checked={value === RegistrationPolicyVariants.disabled}
                          onClick={() => onChange(RegistrationPolicyVariants.disabled)}
                        />
                        <CustomRadioButton
                          inputRef={ref}
                          className={styles.radio}
                          label={
                            <CustomTypography className={clsx('text-14')}>
                              Регистрация разрешена
                            </CustomTypography>
                          }
                          checked={value === RegistrationPolicyVariants.allowed}
                          onClick={() => onChange(RegistrationPolicyVariants.allowed)}
                        />
                        <CustomRadioButton
                          inputRef={ref}
                          className={styles.radio}
                          label={
                            <CustomTypography className={clsx('text-14')}>
                              Разрешена только авторегистрация
                            </CustomTypography>
                          }
                          checked={
                            value === RegistrationPolicyVariants.allowed_autoregistration_only
                          }
                          onClick={() =>
                            onChange(RegistrationPolicyVariants.allowed_autoregistration_only)
                          }
                        />
                      </>
                    )}
                  />
                </div>
              </div>
              <div className={styles['submit-buttons']}>
                <Button
                  className={styles['create-button']}
                  type="submit"
                  variant="custom"
                  disabled={!applicationFormChanged}
                >
                  Сохранить
                </Button>
              </div>
            </SettingsItem>
          </form>

          <AdvancedSettings />

          <SettingsItem title="Внешний вид виджета" onClick={() => setIsWidgetPanelOpen(true)}>
            <ViewWidget
              appName={selectedClient.name}
              props={selectedClient}
              coverSrc={coverSrc}
              avatarSrc={avatarSrc}
            />
          </SettingsItem>

          <EditProviders isOpen={providerModalOpen} onClose={() => setProviderModalOpen(false)} />
          <EditEmailTemplates
            close={() => setEmailTemplatesModalOpen(false)}
            isOpen={isEmailTemplatesModalOpen}
            templates={emailTemplates}
          />
          <EditProfileField
            onClose={() => {
              setProfileModalOpen(false);
              setSelectedProfileField(undefined);
            }}
            isOpen={profileModalOpen}
            selectedProfile={selectedProfileField}
            phoneProvider={phoneProvider}
            emailProvider={emailProvider}
          />
          <EditRuleValidations
            close={() => setSavePasswordPolicyModalOpen(false)}
            field_name={'password'}
            isOpen={savePasswordPolicyModalOpen}
          />

          <EditWidgetPanel
            isOpen={isWidgetPanelOpen}
            onClose={() => setIsWidgetPanelOpen(false)}
            coverSrc={coverSrc}
            avatarSrc={avatarSrc}
            selectedClient={selectedClient}
          />

          <ConfirmationModal
            title="Сохранение изменений"
            mainMessage={['Изменения не сохранены. Продолжить без сохранения?']}
            actionButtonText="Продолжить"
            isOpen={saveModalOpen}
            onAction={() => {
              savedCallback.current?.();
              dispatch(setApplicationFormChanged(false));
              setSaveModalOpen(false);
            }}
            onClose={closeSaveModal}
          />
        </div>
      </div>
    </>
  );
};
