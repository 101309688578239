import Avatar from '@mui/material/Avatar';
import clsx from 'clsx';
import { FC, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { TClientFull } from 'src/redux/services/clients';
import { getImageURL, getProviderTitleByType } from '../../../helpers';
import { ReactComponent as IdIcon } from '../../../icons/Id.svg';
import { setApplicationFormChanged } from '../../../redux/appSlice';
import { EGetProviderAction, useGetProvidersQuery } from '../../../redux/services/provider';
import { CustomTypography } from '../../custom/CustomTypography';
import { ConfirmationModal } from '../../modal/ConfirmationModal';
import { EditProviders } from '../../Providers/editProvidersPanel/EditProviders';
import { IconWithTooltip } from '../../shared/IconWithTooltip';
import { EditWidgetPanel } from '../editWidgetPanel/EditWidgetPanel';
import { ViewWidget } from '../editWidgetPanel/ViewWidget';
import { SettingsItem } from '../SettingsItem';
import styles from './ClientSettings.module.css';
import { ClientSettingsHeader } from './ClientSettingsHeader';

type TClientSettings = {
  selectedClient: TClientFull;
};

export const ClientSettings: FC<TClientSettings> = ({ selectedClient }) => {
  const dispatch = useDispatch();
  const [avatarSrc, setAvatarSrc] = useState<string | null>(selectedClient.avatar);
  const [coverSrc, setCoverSrc] = useState<string | null>(null);
  const [saveModalOpen, setSaveModalOpen] = useState(false);
  const [providerModalOpen, setProviderModalOpen] = useState(false);
  const [isWidgetPanelOpen, setIsWidgetPanelOpen] = useState<boolean>(false);

  const savedCallback = useRef<() => void>();

  const { data: providers = [] } = useGetProvidersQuery({
    client_id: selectedClient.client_id,
    action: EGetProviderAction.all,
  });

  useEffect(() => {
    return () => {
      dispatch(setApplicationFormChanged(false));
    };
  }, []);

  const closeSaveModal = () => setSaveModalOpen(false);

  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <ClientSettingsHeader
          coverSrc={coverSrc}
          setCoverSrc={setCoverSrc}
          avatarSrc={avatarSrc}
          client={selectedClient}
          setAvatarSrc={setAvatarSrc}
        />
        <SettingsItem
          title="Способы входа"
          onClick={() => {
            setProviderModalOpen(true);
          }}
        >
          <div className={styles.providers}>
            {providers
              .filter((provider) => provider.is_active)
              .map((provider) => {
                return (
                  <div key={provider.id} className={styles.provider}>
                    <Avatar
                      src={getImageURL(provider.avatar)}
                      className={styles['provider-icon-wrapper']}
                    >
                      {!provider.avatar && <IdIcon />}
                    </Avatar>
                    <div className={styles['provider-name-wrapper']}>
                      <CustomTypography className={clsx('text-14', styles['provider-name'])}>
                        {provider.name}
                      </CustomTypography>
                      <CustomTypography className={clsx('text-12')} color="grey">
                        {getProviderTitleByType(provider.type)}
                      </CustomTypography>
                    </div>
                    {selectedClient.required_providers_ids.includes(provider.id.toString()) && (
                      <IconWithTooltip
                        iconType="starFilled"
                        customTitle=""
                        buttonSx={{}}
                        description="Для входа в приложение требуется
                                 наличие способа входа в профиле пользователя"
                        customStyleButton={styles.iconInfo}
                      />
                    )}
                  </div>
                );
              })}
          </div>
        </SettingsItem>

        <SettingsItem title="Внешний вид виджета" onClick={() => setIsWidgetPanelOpen(true)}>
          <ViewWidget
            appName={selectedClient.name}
            props={selectedClient}
            coverSrc={coverSrc}
            avatarSrc={avatarSrc}
          />
        </SettingsItem>

        <EditProviders onClose={() => setProviderModalOpen(false)} isOpen={providerModalOpen} />

        <EditWidgetPanel
          isOpen={isWidgetPanelOpen}
          onClose={() => setIsWidgetPanelOpen(false)}
          coverSrc={coverSrc}
          avatarSrc={avatarSrc}
          selectedClient={selectedClient}
        />

        <ConfirmationModal
          title="Сохранение изменений"
          mainMessage={['Изменения не сохранены. Продолжить без сохранения?']}
          actionButtonText="Продолжить"
          isOpen={saveModalOpen}
          onAction={() => {
            savedCallback.current?.();
            dispatch(setApplicationFormChanged(false));
            setSaveModalOpen(false);
          }}
          onClose={closeSaveModal}
        />
      </div>
    </div>
  );
};
