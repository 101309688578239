import React from 'react';
import styles from './ViewEmailTemplate.module.css';
import { COPYRIGHT, DASHBOARD_URL } from '../../../constants';
import { useTheme } from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';
import { getImageURL } from 'src/helpers';

interface ViewEmailTemplateProps {
  content: string;
}

export const ViewEmailTemplate: React.FC<ViewEmailTemplateProps> = ({ content }) => {
  const theme = useTheme();
  const clientProfile = useSelector((state: RootState) => state.app.clientProfile);
  const PROJECT_NAME = clientProfile?.name || 'PROJECT_NAME';
  const LOGO_URL = getImageURL(clientProfile?.avatar) || '';
  return (
    <div className={styles.email}>
      <table
        style={{ backgroundColor: '#F2F2F2', paddingTop: '20px', paddingBottom: '20px' }}
        width="100%"
        cellPadding="0"
        cellSpacing="0"
      >
        <tbody>
          <tr>
            <td width="100%" align="center">
              <table
                width="100%"
                style={{
                  backgroundColor: '#FFFFFF',
                  boxShadow: '0 5px 5px rgba(0, 0, 0, 0.35)',
                  width: '100%',
                  maxWidth: '580px',
                }}
                cellPadding="0"
                cellSpacing="0"
              >
                <tbody>
                  <tr>
                    <td>
                      <table
                        width="100%"
                        cellPadding="0"
                        cellSpacing="0"
                        style={{
                          verticalAlign: 'middle',
                          maxWidth: '580px',
                          textAlign: 'center',
                        }}
                      >
                        <tbody>
                          <tr>
                            <td
                              style={{
                                verticalAlign: 'middle',
                                padding: '20px',
                                borderBottom: '1px solid #bbbbbb',
                                display: 'flex',
                                justifyContent: 'space-between',
                              }}
                            >
                              <table
                                cellPadding="0"
                                cellSpacing="0"
                                style={{
                                  verticalAlign: 'middle',
                                  lineHeight: '28px',
                                  textAlign: 'left',
                                }}
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      width="36"
                                      height="28"
                                      style={{
                                        verticalAlign: 'middle',
                                        lineHeight: '28px',
                                      }}
                                    >
                                      <a
                                        href={DASHBOARD_URL}
                                        style={{
                                          color: theme.palette.custom.accent,
                                          fontFamily: 'Arial, sans-serif',
                                          fontSize: '14px',
                                          lineHeight: '28px',
                                          verticalAlign: 'middle',
                                          textDecoration: 'none',
                                          display: 'inline-block',
                                        }}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        <img
                                          src={LOGO_URL}
                                          width="28"
                                          height="28"
                                          style={{
                                            verticalAlign: 'middle',
                                            display: 'inline-block',
                                          }}
                                          alt="Logo"
                                        />
                                      </a>
                                    </td>
                                    <td
                                      height="28"
                                      valign="middle"
                                      style={{ verticalAlign: 'middle', lineHeight: '28px' }}
                                    >
                                      <a
                                        href={DASHBOARD_URL}
                                        style={{
                                          color: theme.palette.custom.accent,
                                          fontFamily: "'Montserrat', sans-serif",
                                          fontSize: '15px',
                                          fontWeight: 600,
                                          lineHeight: '28px',
                                          textDecoration: 'none',
                                          display: 'inline-block',
                                        }}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        {PROJECT_NAME}
                                      </a>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              <table
                                cellPadding="0"
                                cellSpacing="0"
                                style={{
                                  verticalAlign: 'middle',
                                  lineHeight: '28px',
                                  textAlign: 'right',
                                }}
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      align="right"
                                      height="28"
                                      valign="middle"
                                      style={{ verticalAlign: 'middle', lineHeight: '28px' }}
                                    >
                                      <a
                                        style={{
                                          margin: 0,
                                          padding: 0,
                                          color: theme.palette.custom.accent,
                                          textDecoration: 'none',
                                        }}
                                        href={DASHBOARD_URL}
                                      >
                                        {DASHBOARD_URL.replace('https://', '')}
                                      </a>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <table
                        width="100%"
                        style={{ backgroundColor: '#FFFFFF', maxWidth: '580px' }}
                        cellPadding="0"
                        cellSpacing="0"
                      >
                        <tbody>
                          <tr>
                            <td
                              style={{
                                color: 'rgba(0, 0, 0, .87)',
                                padding: '20px',
                                borderBottom: '1px solid #bbbbbb',
                              }}
                              dangerouslySetInnerHTML={{ __html: content }}
                            ></td>
                          </tr>
                        </tbody>
                      </table>
                      <table
                        width="100%"
                        style={{ backgroundColor: '#FFFFFF' }}
                        cellPadding="0"
                        cellSpacing="0"
                      >
                        <tbody>
                          <tr>
                            <td style={{ padding: '20px' }}>
                              <table>
                                <tbody>
                                  <tr>
                                    <td style={{ paddingBottom: '10px' }}>
                                      <a
                                        style={{
                                          color: theme.palette.custom.accent,
                                          textDecoration: 'none',
                                        }}
                                        href="mailto:example_root@mail.ru"
                                      >
                                        example_root@mail.ru
                                      </a>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              <table>
                                <tbody>
                                  <tr>
                                    <td>{COPYRIGHT}</td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
