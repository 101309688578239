import {
  Avatar,
} from '@mui/material';
import clsx from 'clsx';
import React, { memo, useState } from 'react';
import { CustomTypography } from '../../custom/CustomTypography';
import styles from '../../settings/Settings.module.css';
import { EditTrustedProviders } from './EditProviders';
import { EGetProviderAction, useGetProvidersQuery } from 'src/redux/services/provider';
import { getImageURL, getProviderTitleByType } from 'src/helpers';
import { useParams } from 'react-router-dom-v5-compat';
import { ReactComponent as IdIcon } from '../../../icons/Id.svg';
import { addProvidersTypes, addProviderType } from '../Settings';
import { SettingsItem } from 'src/components/settings/SettingsItem';

interface TrustedProvidersComponentProps {
  updateLicenses: () => void;
}

const TrustedProvidersComponent = ({
  updateLicenses,
}: TrustedProvidersComponentProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { clientId = '' } = useParams<{ clientId: string }>();
  const { data: providers = [] } = useGetProvidersQuery({
    client_id: clientId,
    action: EGetProviderAction.all,
    types: [addProviderType.LDAP, addProviderType.ALDPRO, addProviderType._1C, addProviderType.IDM],
  });

  return (
    <>
      <SettingsItem title="Доверенные провайдеры" onClick={() => setIsModalOpen(true)}>
        <div className={styles.providers}>
          {providers.map((provider) => {
            return (
              <div key={provider.id} className={styles.provider}>
                <Avatar
                  src={getImageURL(provider.avatar)}
                  className={styles['provider-icon-wrapper']}
                >
                  {!provider.avatar && <IdIcon />}
                </Avatar>
                <div className={styles['provider-name-wrapper']}>
                  <CustomTypography className={clsx('text-14', styles['provider-name'])}>
                    {provider.name}
                  </CustomTypography>
                  <CustomTypography className={clsx('text-12')} color="grey">
                    {getProviderTitleByType(provider.type, addProvidersTypes)}
                  </CustomTypography>
                </div>
              </div>
            );
          })}
        </div>
      </SettingsItem>

      <EditTrustedProviders onClose={() => setIsModalOpen(false)} isOpen={isModalOpen} updateLicenses={updateLicenses} />
    </>
  );
};

export const TrustedProviders = memo(TrustedProvidersComponent);
