import { TextField } from '@mui/material';
import React, { memo, useState } from 'react';
import { ConfirmationModal } from '../../modal/ConfirmationModal';
import { activateLicense } from './requests';
import { useDispatch } from 'react-redux';
import { setNotice } from 'src/redux/noticesSlice';

interface TModalLicenseProps {
  isOpen: boolean;
  onClose: () => void;
  loadLicenses: (value: number) => Promise<void>;
}

const ModalLicenseComponent: React.FC<TModalLicenseProps> = ({ isOpen, onClose, loadLicenses }) => {
  const [inputLicense, setInputLicense] = useState<string>('');
  const dispatch = useDispatch();

  const handleSaveLicense = async () => {
    if (!inputLicense) return;

    try {
      const { id } = await activateLicense(inputLicense, 'Описание лицензии');
      if (id) {
        loadLicenses(id);
      }
    } catch (error) {
      dispatch(
        setNotice({
          id: Math.random(),
          isRead: false,
          message: `Ошибка активации лицензии: ${error}`,
          timestamp: new Date().toString(),
          avatar: '',
        }),
      );
    }
    setInputLicense('');
    onClose();
  };

  return (
    <>
      <ConfirmationModal
        title="Введите значение"
        children={
          <TextField
            value={inputLicense}
            onChange={(e) => setInputLicense(e.target.value)}
            fullWidth
            variant="standard"
            style={{ marginBottom: 20 }}
          />
        }
        actionButtonText="Добавить"
        isOpen={isOpen}
        onAction={handleSaveLicense}
        onClose={onClose}
      />
    </>
  );
};

export const ModalLicense = memo(ModalLicenseComponent);
