import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import clsx from 'clsx';
import { FC, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { UploadAndDisplayImage } from '../settings/clientSettings/UploadAndDisplayImage';
import styles from './ProfileFields.module.css';
import { TCustomFields } from '../../redux/userSlice';
import {
  generatePassword,
  generateYearsBetween,
  getExternalAccountLabel,
  getMonthsByYear,
  isDateError,
  isObjectEmpty,
} from '../../helpers';
import { CustomSelect } from '../custom/CustomSelect';
import { useDispatch, useSelector } from 'react-redux';
import { setIsChangesUnsaved } from '../../redux/appSlice';
import { RootState } from '../../redux/store';
import { useNavigate } from 'react-router-dom-v5-compat';
import { EditProfileInputs } from './EditProfile';
import { UserInputs } from '../users/old/CreateUser';
import { PasswordTextfield } from '../custom/PasswordTextfield';
import { AccountTypes, useGetExternalAccountsQuery } from '../../redux/services/user';
import { ReactComponent as EmptySearchIcon } from '../../icons/EmptySearch.svg';
import { ReactComponent as CloseIcon } from '../../icons/Close.svg';
import { ReactComponent as SearchIcon } from '../../icons/Search.svg';
import { ReactComponent as AvatarIcon } from '../../icons/Avatar.svg';
import Modal from '@mui/material/Modal';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import { Roles } from '../../enums';
import { useGetProfileFieldsQuery, useGetSettingsQuery } from '../../redux/services/settings';
import InputAdornment from '@mui/material/InputAdornment';
import { IconWithTooltip } from '../shared/IconWithTooltip';
import { CustomTypography } from '../custom/CustomTypography';

type TProfileFields = {
  userProfile?: {
    id?: string | number;
    role?: Roles;
    picture?: string | null;
  };
  isCreateUser?: boolean;
  editUserByOwner?: boolean;
  isLoading?: boolean;
};

export const ProfileFields: FC<TProfileFields> = ({
  userProfile,
  isCreateUser,
  editUserByOwner,
  isLoading,
}) => {
  const {
    register,
    clearErrors,
    setValue,
    setError,
    watch,
    formState: { errors, dirtyFields },
  } = useFormContext<(UserInputs | EditProfileInputs) & TCustomFields>();
  const { data: externalAccounts } = useGetExternalAccountsQuery(
    userProfile?.id?.toString() || '',
    {
      skip: isCreateUser || !userProfile?.id,
    },
  );
  const accountsWithAvatars = externalAccounts?.filter((account) => !!account.avatar);
  const watchBirthDay = watch('birthDay');
  const watchBirthMonth = watch('birthMonth');
  const watchBirthYear = watch('birthYear');
  const navigate = useNavigate();
  const [searchAvatarValue, setSearchAvatarValue] = useState('');
  const filteredExternalAccounts = accountsWithAvatars?.filter((account) => {
    return getExternalAccountLabel(account)
      ?.toLowerCase()
      .includes(searchAvatarValue.toLowerCase());
  });
  const [availableAvatarsModalOpen, setAvailableAvatarsModalOpen] = useState(false);
  const [avatarSrc, setAvatarSrc] = useState<string | null>(null);
  const [editableFieldNames, setEditableFieldNames] = useState<string[]>([]);
  const setAvatarValue = (value: File | null) => setValue('picture', value, { shouldDirty: true });
  const setAvatarError = (error: string) => setError('picture', { message: error });
  const clearAvatarError = () => clearErrors('picture');
  const { data: dataSettings } = useGetSettingsQuery();
  const { data: listProfileFields } = useGetProfileFieldsQuery();
  const months = getMonthsByYear(watchBirthYear);
  const isChangesUnsaved = useSelector((state: RootState) => state.app.isChangesUnsaved);
  const dispatch = useDispatch();
  const birthDate = new Date(
    +watchBirthYear,
    months.findIndex((month) => month.name === watchBirthMonth),
    +watchBirthDay,
  );
  const selectedMonth = months.find((month) => month.name === watchBirthMonth);
  const dateError = isDateError(birthDate, dataSettings);

  const listCustomProfileFields =
    listProfileFields?.filter(
      (field) => field.type === 'custom' && field.active && (editUserByOwner || field.editable),
    ) || [];
  const listCustomFields = listCustomProfileFields.map((field) => {
    return (
      <div key={field.field}>
        <CustomTypography className={clsx('text-14', styles['input-title'])}>
          {field.title} {field.required && <span className={styles['required-indicator']}>*</span>}
        </CustomTypography>
        <TextField
          {...register(field.field, {
            onChange: () => {
              if (errors[field.field]) clearErrors(field.field);
            },
          })}
          className={clsx('custom', styles.textfield)}
          error={!!errors[field.field]}
          helperText={errors[field.field] ? errors[field.field]?.message : ''}
          fullWidth
          variant="standard"
        />
      </div>
    );
  });

  const listGeneralProfileFields =
    listProfileFields?.filter((field) => field.type === 'general') || [];

  const requiredIndicator = (field_name: string) => {
    if (listGeneralProfileFields.find((g) => g.field === field_name && g.required)) {
      return <span className={styles['required-indicator']}>*</span>;
    }
  };

  useEffect(() => {
    if (listProfileFields) {
      setEditableFieldNames(
        listProfileFields
          ? listProfileFields.filter((field) => !field.editable).map((field) => field.field)
          : [],
      );
    }
  }, [listProfileFields]);

  useEffect(() => {
    setValue('birthDay', String(Math.min(+(selectedMonth?.days || '0'), +watchBirthDay)));
    setValue('birthYear', '2000');
  }, [watchBirthMonth]);

  useEffect(() => {
    const isDirty =
      !isObjectEmpty(dirtyFields) &&
      Object.values(dirtyFields).some((field) => {
        if (Array.isArray(field)) return field.some((elem) => elem.value);
        return field === true;
      });
    if (isChangesUnsaved !== isDirty) dispatch(setIsChangesUnsaved(isDirty));
  }, [Object.values(dirtyFields)]);

  const [agreeModal, setAgreeModal] = useState(false);
  const [dataToDelete, setDataToDelete] = useState({ type: '', value: '' });

  const handleDelete = () => {
    if (dataToDelete.type === 'email') {
      setValue('email', '', { shouldDirty: true });
    } else if (dataToDelete.type === 'phone_number') {
      setValue('phone_number', '', { shouldDirty: true });
    }
    setAgreeModal(false);
  };

  const handleDeleteEmail = () => {
    const email = watch('email');
    setDataToDelete({ type: 'email', value: typeof email === 'string' ? email : '' });
    setAgreeModal(true);
  };

  const handleDeletePhone = () => {
    const phoneNumber = watch('phone_number');
    setDataToDelete({
      type: 'phone_number',
      value: typeof phoneNumber === 'string' ? phoneNumber : '',
    });
    setAgreeModal(true);
  };

  const handleEditPhone = () => {
    if (watch('phone_number')) {
      navigate('/profile/phone/change');
    } else {
      navigate('/profile/phone/add');
    }
  };

  const handleEditEmail = () => {
    if (watch('email')) {
      navigate('/profile/email/change');
    } else {
      navigate('/profile/email/add');
    }
  };

  const handleGeneratePassword = () => {
    const newPassword = generatePassword();
    setValue('new-password', newPassword, { shouldDirty: true });
    clearErrors('new-password');
  };

  return (
    <div className={styles['padding-wrapper']}>
      <CustomTypography className={clsx('font-golos', 'text-17-regular', styles.subtitle)}>
        Основная информация
      </CustomTypography>
      <CustomTypography className={clsx('text-14', styles['input-title'])}>
        Публичное имя {requiredIndicator('nickname')}
      </CustomTypography>
      <TextField
        {...register('nickname', {
          onChange: () => {
            if (errors.nickname) clearErrors('nickname');
          },
        })}
        disabled={editableFieldNames.includes('nickname')}
        className={clsx('custom', styles.textfield)}
        error={!!errors.nickname}
        helperText={errors.nickname ? errors.nickname.message : ''}
        fullWidth
        variant="standard"
      />
      <CustomTypography className={clsx('text-14', styles['input-title'])}>
        Имя {requiredIndicator('given_name')}
      </CustomTypography>
      <TextField
        {...register('given_name', {
          onChange: () => {
            if (errors.given_name) clearErrors('given_name');
          },
        })}
        disabled={editableFieldNames.includes('given_name')}
        className={clsx('custom', styles.textfield)}
        error={!!errors.given_name}
        helperText={errors.given_name ? errors.given_name.message : ''}
        fullWidth
        variant="standard"
      />
      <CustomTypography className={clsx('text-14', styles['input-title'])}>
        Фамилия {requiredIndicator('family_name')}
      </CustomTypography>
      <TextField
        {...register('family_name', {
          onChange: () => {
            if (errors.family_name) clearErrors('family_name');
          },
        })}
        disabled={editableFieldNames.includes('family_name')}
        className={clsx('custom', styles.textfield)}
        error={!!errors.family_name}
        helperText={errors.family_name ? errors.family_name.message : ''}
        fullWidth
        variant="standard"
      />
      <CustomTypography className={clsx('text-14', styles['input-title'])}>
        Логин {requiredIndicator('login')}
      </CustomTypography>
      <TextField
        {...register('login', {
          onChange: () => {
            if (errors.login) clearErrors('login');
          },
        })}
        disabled={editableFieldNames.includes('login')}
        className={clsx('custom', styles.textfield)}
        error={!!errors.login}
        helperText={errors.login ? errors.login.message : ''}
        fullWidth
        variant="standard"
      />
      {isCreateUser || editUserByOwner ? (
        <>
          <CustomTypography className={clsx('text-14', styles['input-title'])}>
            Электронная почта {requiredIndicator('email')}
          </CustomTypography>
          <TextField
            {...register('email', {
              onChange: () => {
                if (errors.email) clearErrors('email');
              },
            })}
            className={clsx('custom', styles.textfield)}
            error={!!errors.email}
            helperText={errors.email ? errors.email.message : ''}
            fullWidth
            variant="standard"
          />
          <CustomTypography className={clsx('text-14', styles['input-title'])}>
            Номер телефона {requiredIndicator('phone_number')}
          </CustomTypography>
          <TextField
            {...register('phone_number', {
              onChange: () => {
                if (errors.phone_number) clearErrors('phone_number');
              },
            })}
            className={clsx('custom', styles.textfield)}
            error={!!errors.phone_number}
            helperText={errors.phone_number ? errors.phone_number.message : ''}
            fullWidth
            variant="standard"
          />
        </>
      ) : (
        <>
          <CustomTypography className={clsx('text-14', styles['input-title'])}>
            Электронная почта {requiredIndicator('email')}
          </CustomTypography>
          <TextField
            disabled
            {...register('email', {
              onChange: () => {
                if (errors.email) clearErrors('email');
              },
            })}
            className={clsx('custom', styles.textfield)}
            error={!!errors.email}
            helperText={errors.email ? errors.email.message : ''}
            fullWidth
            variant="standard"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {!editableFieldNames.includes('email') && (
                    <IconWithTooltip iconType="edit" onClick={handleEditEmail} />
                  )}
                  {watch('email') && (
                    <IconWithTooltip iconType="delete" onClick={handleDeleteEmail} />
                  )}
                </InputAdornment>
              ),
            }}
          />
          <CustomTypography className={clsx('text-14', styles['input-title'])}>
            Номер телефона {requiredIndicator('phone_number')}
          </CustomTypography>
          <TextField
            disabled
            {...register('phone_number', {
              onChange: () => {
                if (errors.phone_number) clearErrors('phone_number');
              },
            })}
            className={clsx('custom', styles.textfield)}
            error={!!errors.phone_number}
            helperText={errors.phone_number ? errors.phone_number.message : ''}
            fullWidth
            variant="standard"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {!editableFieldNames.includes('phone_number') && (
                    <IconWithTooltip iconType="edit" onClick={handleEditPhone} />
                  )}
                  {watch('phone_number') && (
                    <IconWithTooltip iconType="delete" onClick={handleDeletePhone} />
                  )}
                </InputAdornment>
              ),
            }}
          />
        </>
      )}
      {isCreateUser && (
        <>
          <div className={clsx(styles.flex, styles['input-title'])}>
            <CustomTypography className={clsx('text-14')}>
              Пароль <span className={styles['required-indicator']}>*</span>
            </CustomTypography>
            <Button onClick={handleGeneratePassword} variant="custom2">
              Сгенерировать пароль
            </Button>
          </div>
          <PasswordTextfield
            {...register('new-password', {
              onChange: () => {
                if (errors['new-password']) clearErrors('new-password');
              },
            })}
            className={clsx('custom', styles.textfield)}
            error={!!errors['new-password']}
            helperText={errors['new-password'] ? errors['new-password'].message : ''}
            fullWidth
            variant="standard"
          />
        </>
      )}
      <div>
        <CustomTypography className={clsx('text-14', styles['input-title'])}>
          Дата рождения {requiredIndicator('birthdate')}
        </CustomTypography>
        <div style={{ marginBottom: 24 }}>
          <div style={{ display: 'flex' }}>
            <CustomSelect
              className={styles.select}
              error={dateError}
              value={watchBirthDay}
              disabled={editableFieldNames.includes('birthdate')}
              renderValue={(value) => `0${value}`.slice(-2)}
              onChange={(e) => setValue('birthDay', e.target.value, { shouldDirty: true })}
            >
              {new Array(selectedMonth?.days).fill(null).map((_, index) => (
                <MenuItem className="custom-select" key={index} value={index + 1}>
                  {index + 1}
                </MenuItem>
              ))}
            </CustomSelect>
            <CustomSelect
              className={styles.select}
              error={dateError}
              value={watchBirthMonth}
              disabled={editableFieldNames.includes('birthdate')}
              onChange={(e) => {
                setValue('birthMonth', e.target.value, { shouldDirty: true });
              }}
            >
              {months.map((month) => (
                <MenuItem className="custom-select" key={month.name} value={month.name}>
                  {month.name}
                </MenuItem>
              ))}
            </CustomSelect>
            <CustomSelect
              className={styles.select}
              error={dateError}
              value={watchBirthYear}
              disabled={editableFieldNames.includes('birthdate')}
              onChange={(e) =>
                setValue('birthYear', e.target.value.toString(), { shouldDirty: true })
              }
            >
              {generateYearsBetween(
                new Date().getFullYear() - (dataSettings?.max_age || 120),
                new Date().getFullYear() - (dataSettings?.min_age || 0),
              ).map((year) => (
                <MenuItem className="custom-select" key={year} value={year}>
                  {year}
                </MenuItem>
              ))}
            </CustomSelect>
          </div>
          {dateError && (
            <CustomTypography style={{ marginTop: 8 }} className={clsx('sf-14-reg')} color="error">
              Дата рождения не соответствует возрастным ограничениям
            </CustomTypography>
          )}
        </div>
        <CustomTypography className={clsx('text-14', styles['input-title'])}>
          Фото профиля {requiredIndicator('picture')}
        </CustomTypography>
        <UploadAndDisplayImage
          imgSrc={avatarSrc}
          setImgSrc={setAvatarSrc}
          componentName="edit-profile"
          setAvatarError={setAvatarError}
          clearAvatarError={clearAvatarError}
          pathToAvatar={userProfile?.picture || ''}
          setAvatarValue={setAvatarValue}
          DefaultIcon={<AvatarIcon title="Profile avatar" />}
          disabled={editableFieldNames.includes('picture')}
          onAvailableAvatarsButtonClick={
            accountsWithAvatars?.length
              ? () => {
                  setAvailableAvatarsModalOpen(true);
                }
              : undefined
          }
        />
        {errors.picture && (
          <CustomTypography color="error" className={clsx('text-14', styles['input-error'])}>
            {errors.picture.message}
          </CustomTypography>
        )}
        <CustomTypography className={clsx('text-14')} color="grey">
          Файл с расширением .jpg, .jpeg, .png, .svg. Максимальный размер - 1 МБ.
        </CustomTypography>
      </div>
      {listCustomFields.length > 0 && (
        <div className={styles['custom-fields']}>
          <CustomTypography className={clsx('font-golos', 'text-17-regular', styles.subtitle)}>
            Дополнительная информация
          </CustomTypography>
          {listCustomFields}
        </div>
      )}
      <div className={styles['submit-buttons']}>
        <Button onClick={() => navigate('profile')} variant="custom" color="secondary">
          Отмена
        </Button>
        <Button
          disabled={isLoading}
          className={styles['create-button']}
          type="submit"
          variant="custom"
        >
          Сохранить
        </Button>
      </div>
      <Modal
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        open={agreeModal}
        onClose={() => setAgreeModal(false)}
      >
        <div className={styles['save-modal']}>
          <div style={{ display: 'flex' }}>
            <CustomTypography
              style={{ marginBottom: 16 }}
              className={clsx('header-2-medium', 'font-golos')}
            >
              Подтверждение удаления
            </CustomTypography>
          </div>
          <CustomTypography style={{ marginBottom: 32 }} className={clsx('text-14')}>
            Вы уверены, что хотите удалить {dataToDelete.value}?
          </CustomTypography>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button variant="custom" color="secondary" onClick={() => setAgreeModal(false)}>
              Отмена
            </Button>
            <Button onClick={handleDelete} variant="custom" style={{ marginLeft: 24 }}>
              Удалить
            </Button>
          </div>
        </div>
      </Modal>
      <Modal
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        open={availableAvatarsModalOpen}
        onClose={() => setAvailableAvatarsModalOpen(false)}
      >
        <div className={styles['providers-with-avatars-modal']}>
          <div style={{ display: 'flex' }}>
            <CustomTypography className={clsx('header-2-medium', 'font-golos')}>
              Доступные аватары
            </CustomTypography>
            <IconButton
              onClick={() => setAvailableAvatarsModalOpen(false)}
              style={{ marginLeft: 'auto', marginBottom: 16 }}
            >
              <CloseIcon />
            </IconButton>
          </div>
          <CustomTypography className={clsx('text-15', styles['input-description'])} color="grey">
            Выберите аватар, который вы хотите разместить в профиле пользователя:
          </CustomTypography>
          <TextField
            value={searchAvatarValue}
            onChange={(e) => setSearchAvatarValue(e.target.value)}
            className={clsx(styles.search, 'custom')}
            fullWidth
            variant="standard"
            placeholder="Поиск"
            InputProps={{ startAdornment: <SearchIcon className={styles['search-icon']} /> }}
            inputProps={{ className: styles.input }}
          />
          <div className={styles['providers-wrapper']}>
            {!!accountsWithAvatars?.length && !filteredExternalAccounts?.length && (
              <div
                style={{
                  display: 'flex',
                  height: '100%',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <EmptySearchIcon />
                <CustomTypography
                  style={{ marginBottom: 8 }}
                  className={clsx('text-17')}
                  color="grey"
                >
                  По вашему запросу ничего не найдено
                </CustomTypography>
              </div>
            )}
            {filteredExternalAccounts?.map((account) => (
              <div
                className={styles.provider}
                onClick={() => {
                  setAvatarSrc(account.avatar as string);
                  setValue('picture', account.avatar as string, {
                    shouldDirty: true,
                  });
                  setAvailableAvatarsModalOpen(false);
                }}
                key={account.id}
              >
                <div className={styles['account-icon-wrapper']}>
                  <img src={account.avatar} className={styles['account-icon']} />
                </div>
                <div className={styles['account-name-wrapper']}>
                  <CustomTypography className={clsx('text-14', styles['account-name'])}>
                    {getExternalAccountLabel(account)}
                  </CustomTypography>
                  <CustomTypography className={clsx('text-12')} color="grey">
                    {account.type === AccountTypes._1C ? '1C' : account.type}
                  </CustomTypography>
                </div>
              </div>
            ))}
          </div>
        </div>
      </Modal>
    </div>
  );
};
