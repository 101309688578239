import React, { useCallback, useState } from 'react';
import { Navigate, Outlet, useParams } from 'react-router-dom-v5-compat';
import { useEffect } from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { setUserRoleInApp } from '../../redux/userSlice';
import { isOwnerOrEditor, showDisplay } from '../../helpers';
import { getUserRoleInApp } from '../../requests/user';
import { TopTabsApplication } from '../applications/old/TopTabsApplication';
import { useGetUserQuery } from '../../redux/services/user';

export const OwnerOrEditorRoute: React.FC = () => {
  const [loadedPage, setLoadedPage] = useState(false);
  const [accessAllowed, setAccessAllowed] = useState(false);
  const { id } = useParams();
  const dispatch = useDispatch();
  const { data: user, isFetching: userLoading, isUninitialized } = useGetUserQuery();
  const userRole = useSelector((state: RootState) => state.user.userProfile.roleRootApp);
  const userRoleLoading = useSelector((state: RootState) => state.user.isUserProfileLoading);
  const redirectToFillProfile =
    location.pathname !== '/fill-profile' && user?.password_change_required;

  useEffect(() => {
    const getRole = async () => {
      if (userLoading || isUninitialized || userRoleLoading) return;
      if (isOwnerOrEditor(userRole)) setAccessAllowed(true);
      else if (user?.sub && id) {
        const roleInApp = await getUserRoleInApp(user.sub, id);

        if (roleInApp && isOwnerOrEditor(roleInApp)) {
          dispatch(setUserRoleInApp(roleInApp));
          setAccessAllowed(true);
        }
      }
      setLoadedPage(true);
    };
    getRole();
  }, [location.pathname, userLoading, isUninitialized, userRole, userRoleLoading]);

  useEffect(() => {
    if (accessAllowed) {
      setLoadedPage(true);
      showDisplay();
    }
  }, [accessAllowed]);

  const OwnerOrEditorOutlet = useCallback(() => {
    if (accessAllowed) {
      if (redirectToFillProfile) return <Navigate to="/fill-profile" replace />;

      return (
        <>
          <TopTabsApplication />
          <Outlet />
        </>
      );
    }
    return <Navigate to={'/applications'} replace />;
  }, [accessAllowed, redirectToFillProfile]);

  return loadedPage ? <OwnerOrEditorOutlet /> : <LinearProgress />;
};
