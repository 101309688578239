import { yupResolver } from '@hookform/resolvers/yup';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import clsx from 'clsx';
import React, { FC, useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { CLIENT_ID } from '../../../constants';
import { isObjectEmpty } from '../../../helpers';
import {
  ProviderType,
  TKloudParams,
  TMiscProvider,
  useCreateProviderMutation,
  useUpdateProviderMutation,
} from '../../../redux/services/provider';
import { CustomTypography } from '../../custom/CustomTypography';
import { PasswordTextfield } from '../../custom/PasswordTextfield';
import { SharedFormProvider } from '../../shared/FormProvider';
import { ProviderSettingsSidePanel } from '../../sidePanel/ProviderSettingsSidePanel';
import { UploadAndDisplayImage } from '../../settings/clientSettings/UploadAndDisplayImage';
import styles from './PhoneProvider.module.css';
import { ConfirmationModal } from '../../modal/ConfirmationModal';

export type TPhoneProvider = {
  id: string;
  avatar: string;
  params?: TKloudParams;
};

type TPhoneProviderProps = {
  isOpen: boolean;
  close: (value?: boolean) => void;
  phoneProvider?: TMiscProvider;
  // TODO: в phoneProvider принимать только необходимые параметры: issuer, external_client_id, external_client_secret, phoneProvider.id
};

export type PhoneProviderInputs = {
  issuer: string;
  external_client_id: string;
  external_client_secret: string;
  // phone_provider_enabled: boolean;
  // phone_number_required: boolean;
  avatar: File | null;
  path_to_avatar: string;
  is_public: boolean;
};

const schema = yup.object({
  issuer: yup
    .string()
    .url('Неверный формат ссылки')
    .min(1, 'Обязательное поле')
    .max(2000, 'Адрес не может превышать 2000 символов')
    .nullable(true)
    .transform((v) => (typeof v === 'undefined' ? null : v)),
  external_client_id: yup
    .string()
    .max(255, 'Идентификатор не может превышать 255 символов')
    .required('Обязательное поле')
    .matches(/^[^\n ]*$/, {
      message: 'Идентификатор не может содержать пробелы',
    }),
  external_client_secret: yup
    .string()
    .max(255, 'Секретный ключ не может превышать 255 символов')
    .required('Обязательное поле')
    .matches(/^[^\n ]*$/, {
      message: 'Секретный ключ не может содержать пробелы',
    }),
});

export const PhoneProvider: FC<TPhoneProviderProps> = ({ isOpen, close, phoneProvider }) => {
  const phoneLogo = 'public/default/phone.svg';

  const methods = useForm<PhoneProviderInputs>({
    resolver: yupResolver(schema),
    mode: 'onBlur',
    defaultValues: {
      path_to_avatar: phoneLogo,
    },
    reValidateMode: 'onBlur',
  });
  const {
    register,
    handleSubmit,
    setValue,
    setError,
    control,
    formState: { dirtyFields, errors },
    clearErrors,
    reset,
  } = methods;

  const [saveModalOpen, setSaveModalOpen] = useState(false);
  const [overrideImage, setOverrideImage] = useState<File | string | null>(null);
  const [avatarSrc, setAvatarSrc] = useState<string | null>();
  const closeSaveModal = () => setSaveModalOpen(false);
  const [createProvider, createResult] = useCreateProviderMutation();
  const [updateProvider, updateResult] = useUpdateProviderMutation();

  useEffect(() => {
    if (isOpen && phoneProvider) {
      setFields(phoneProvider);
    }
    return () => {
      reset();
    };
  }, [isOpen]);

  useEffect(() => {
    if (createResult.isSuccess || updateResult.isSuccess) close(true);
  }, [createResult, updateResult]);

  const setAvatarValue = (value: File | null) => setValue('avatar', value, { shouldDirty: true });
  const setAvatarLink = (value: string) => {
    setValue('path_to_avatar', value, { shouldDirty: true });
  };
  const setAvatarError = (error: string) => setError('avatar', { message: error });
  const clearAvatarError = () => clearErrors('avatar');

  const setFields = async (provider?: Partial<TMiscProvider>) => {
    try {
      const { avatar, params, is_public } = provider || {};
      setValue('is_public', !!is_public);

      if (params) {
        (Object.keys(params) as Array<keyof TKloudParams>).forEach((field) => {
          setValue(field, (params as TKloudParams)?.[field] || '', { shouldDirty: !provider });
        });
      }

      if (avatar) {
        setOverrideImage(avatar);
        setValue('avatar', null);
        setValue('path_to_avatar', avatar, { shouldDirty: !provider });
      }
    } catch (e) {
      console.log(e);
    }
  };

  const onSubmit: SubmitHandler<PhoneProviderInputs> = (data) => {
    if (phoneProvider) {
      updateProvider({
        body: {
          type: ProviderType.PHONE,
          provider_id: phoneProvider.id,
          ...data,
        },
        client_id: CLIENT_ID,
      });
    } else {
      createProvider({
        body: {
          type: ProviderType.PHONE,
          name: 'Номер телефона',
          ...data,
        },
        client_id: CLIENT_ID,
      });
    }
  };

  const handleClose = () => {
    if (isObjectEmpty(dirtyFields)) close();
    else setSaveModalOpen(true);
  };

  return (
    <ProviderSettingsSidePanel
      handleClosePanel={handleClose}
      isOpenPanel={isOpen}
      title="Настройки подтверждения номера телефона"
      setPasteFields={() => {
        setFields();
      }}
      isNoBackdrop
    >
      <SharedFormProvider<PhoneProviderInputs>
        methods={methods}
        onSubmit={handleSubmit(onSubmit)}
        handleCancel={handleClose}
        isDisabled={createResult.isLoading || updateResult.isLoading}
      >
        <div className={styles['provider-form']}>
          <div>
            <CustomTypography className={clsx('text-14', styles['input-title'], styles.asterisk)}>
              Базовый адрес авторизации (issuer)
            </CustomTypography>
            <TextField
              {...register('issuer', {
                onChange: () => {
                  if (errors.issuer) {
                    clearErrors('issuer');
                  }
                },
              })}
              className="custom"
              error={!!errors.issuer}
              helperText={errors.issuer ? errors.issuer.message : ''}
              fullWidth
              variant="standard"
              style={{ marginBottom: 32 }}
            />
            <CustomTypography className={clsx('text-14', styles['input-title'], styles.asterisk)}>
              Идентификатор ресурса (client_id)
            </CustomTypography>
            <TextField
              {...register('external_client_id', {
                onChange: () => {
                  if (errors.external_client_id) {
                    clearErrors('external_client_id');
                  }
                },
              })}
              className="custom"
              error={!!errors.external_client_id}
              helperText={errors.external_client_id ? errors.external_client_id.message : ''}
              fullWidth
              variant="standard"
              autoComplete="off"
            />
            <CustomTypography className={clsx('text-14', styles['input-subtitle'])} color="grey">
              Уникальный идентификатор подключаемого ресурса
            </CustomTypography>

            <CustomTypography className={clsx('text-14', styles['input-title'], styles.asterisk)}>
              Секретный ключ (Client_secret)
            </CustomTypography>
            <PasswordTextfield
              {...register('external_client_secret', {
                required: true,
                onChange: () => {
                  if (errors.external_client_secret) clearErrors('external_client_secret');
                },
              })}
              className="custom"
              error={!!errors.external_client_secret}
              helperText={
                errors.external_client_secret ? errors.external_client_secret.message : ''
              }
              fullWidth
              variant="standard"
              autoComplete="off"
            />
            <CustomTypography className={clsx('text-14', styles['input-subtitle'])} color="grey">
              Секретный ключ ресурса
            </CustomTypography>
            <div className={styles['switch-wrapper']}>
              <CustomTypography className={clsx('text-14')}>
                Использовать для входа по коду
              </CustomTypography>
              <Controller
                control={control}
                name="is_public"
                defaultValue={false}
                render={({ field }) => (
                  <Switch
                    checked={field.value}
                    onChange={(e) => field.onChange(e.target.checked)}
                  />
                )}
              />
            </div>
            <CustomTypography className={clsx('text-14', styles['input-subtitle'])} color="grey">
              Будут доступны в приложениях как способ входа с помощью одноразовых паролей
            </CustomTypography>
            <CustomTypography className={clsx('text-14', styles['input-title'])}>
              Изображение телефона
            </CustomTypography>
            <UploadAndDisplayImage
              overRideImageSrc={overrideImage}
              setAvatarError={setAvatarError}
              clearAvatarError={clearAvatarError}
              pathToAvatar={phoneProvider?.avatar || phoneLogo}
              setAvatarValue={setAvatarValue}
              componentName="update-phone-provider"
              setAvatarLink={setAvatarLink}
              imgSrc={avatarSrc}
              setImgSrc={setAvatarSrc}
            />
            {errors.avatar && (
              <CustomTypography color="error" className={clsx('text-14', styles['input-error'])}>
                {errors.avatar.message}
              </CustomTypography>
            )}
            <CustomTypography className={clsx('text-14', styles['input-subtitle'])} color="grey">
              Файл с расширением .jpg, .jpeg, .png, .svg. Максимальный размер - 1 МБ.
            </CustomTypography>
          </div>
        </div>
      </SharedFormProvider>

      <ConfirmationModal
        title="Сохранение изменений"
        mainMessage={['Изменения не сохранены. Продолжить без сохранения?']}
        actionButtonText="Продолжить"
        isOpen={saveModalOpen}
        onAction={() => {
          close();
          setSaveModalOpen(false);
        }}
        onClose={closeSaveModal}
      />
    </ProviderSettingsSidePanel>
  );
};
