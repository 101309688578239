import Button from '@mui/material/Button';
import { ChangeEvent, FC, FocusEvent, useEffect, useState, useRef } from 'react';
import { ReactComponent as AppIcon } from '../../../icons/App.svg';
import clsx from 'clsx';
import styles from './CreateApplication.module.css';
import { UploadAndDisplayImage } from '../../settings/clientSettings/UploadAndDisplayImage';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Switch from '@mui/material/Switch';
import { Controller, FormProvider, SubmitHandler, useFieldArray, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { connect, useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/rootReducer';
import {
  DescriptionField,
  RedirectUrisField,
} from '../../settings/clientSettings/ClientSettingsFields';
import { setApplicationFormChanged } from '../../../redux/appSlice';
import { isObjectEmpty, isUrl } from '../../../helpers';
import { CustomSelect } from '../../custom/CustomSelect';
import { useNavigate } from 'react-router-dom-v5-compat';
import { CustomTypography } from '../../custom/CustomTypography';
import { ConfirmationModal } from '../../modal/ConfirmationModal';
import { useCreateClientMutation } from 'src/redux/services/clients';

type Inputs = {
  name: string;
  description: string;
  domain: string;
  redirectUri: {
    name: string;
    value: string;
  }[];
  logoutUri: {
    name: string;
    value: string;
  }[];
  request_uris: {
    name: string;
    value: string;
  }[];
  response_types: string[];
  grant_types: string[];
  avatar: File | null;
  token_endpoint_auth_method: string;
  introspection_endpoint_auth_method: string;
  revocation_endpoint_auth_method: string;
  id_token_signed_response_alg: string;
  subject_type: string;
  require_auth_time: boolean;
  require_signed_request_object: boolean;
  is_visible: boolean;
  authorize_only_admins: boolean;
};

export enum EAuthMethodType {
  client_secret_basic = 'client_secret_basic',
  client_secret_post = 'client_secret_post',
  client_secret_jwt = 'client_secret_jwt',
  private_key_jwt = 'private_key_jwt',
  none = 'none',
}

export enum ESigningAlgTypes {
  RS256 = 'RS256',
  PS256 = 'PS256',
}

export enum ESubjectTypeVariant {
  public = 'public',
  pairwise = 'pairwise',
}

export enum EResponseTypes {
  code_token = 'code token',
  code_id_token_token = 'code id_token token',
  code_id_token = 'code id_token',
  code = 'code',
  id_token = 'id_token',
  none = 'none',
}

export enum EGrantTypes {
  authorization_code = 'authorization_code',
  implicit = 'implicit',
  refresh_token = 'refresh_token',
  device_flow = 'urn:ietf:params:oauth:grant-type:device_code',
}

export const redirectUriSchema = yup.object({
  value: yup
    .string()
    .max(2000, 'Ссылка не может превышать 2000 символов')
    .test('is-url', 'Неверный формат ссылки', (value?: string) => {
      if (!value) return true;
      return isUrl(value);
    }),
});

export const logoutUriSchema = yup.object({
  value: yup
    .string()
    .max(2000, 'Ссылка не может превышать 2000 символов')
    .test('is-url', 'Неверный формат ссылки', (value?: string) => {
      if (!value) return true;
      return isUrl(value);
    }),
});

export const requestUriSchema = yup.object({
  value: yup
    .string()
    .max(2000, 'Ссылка не может превышать 2000 символов')
    .test('is-url', 'Неверный формат ссылки', (value?: string) => {
      if (!value) return true;
      return isUrl(value);
    }),
});

const schema = yup
  .object({
    name: yup
      .string()
      .max(50, 'Название не может превышать 50 символов')
      .required('Обязательное поле'),
    description: yup.string().max(255).min(0),
    domain: yup
      .string()
      .max(2000, 'Ссылка не может превышать 2000 символов')
      .test('is-url', 'Неверный формат ссылки', (value?: string) => {
        if (!value) return true;
        return isUrl(value);
      })
      .required('Обязательное поле'),
    redirectUri: yup.array().of(redirectUriSchema).required(),
    logoutUri: yup.array().of(logoutUriSchema).required(),
    request_uris: yup.array().of(requestUriSchema).required(),
  })
  .required();

const mapStateToProps = (state: RootState) => ({
  userId: state.user.userProfile.id,
});

const CreateApplicationComponent: FC<{ userId?: string }> = ({ userId }) => {
  const setAvatarValue = (value: File | null) => setValue('avatar', value, { shouldDirty: true });
  const setAvatarError = (error: string) => setError('avatar', { message: error });
  const clearAvatarError = () => clearErrors('avatar');
  const [avatarSrc, setAvatarSrc] = useState<string | null>(null);
  const [saveModalOpen, setSaveModalOpen] = useState(false);
  const savedCallback = useRef<() => void>();
  const applicationFormChanged = useSelector(
    (state: RootState) => state.app.applicationFormChanged,
  );
  const navigate = useNavigate();
  const [createClient] = useCreateClientMutation();
  const dispatch = useDispatch();

  const methods = useForm<Inputs>({
    resolver: yupResolver(schema),
    defaultValues: {
      name: '',
      avatar: null,
      description: '',
      domain: '',
      redirectUri: [{ value: '' }],
      logoutUri: [{ value: '' }],
      request_uris: [{ value: '' }],
      response_types: [EResponseTypes.code],
      id_token_signed_response_alg: ESigningAlgTypes.RS256,
      introspection_endpoint_auth_method: EAuthMethodType.client_secret_basic,
      revocation_endpoint_auth_method: EAuthMethodType.client_secret_basic,
      token_endpoint_auth_method: EAuthMethodType.client_secret_basic,
      subject_type: ESubjectTypeVariant.public,
      grant_types: [EGrantTypes.authorization_code],
      require_auth_time: false,
      require_signed_request_object: false,
      is_visible: false,
      authorize_only_admins: false,
    },
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });

  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors, dirtyFields },
    setError,
    clearErrors,
    trigger,
    getValues,
    watch,
  } = methods;

  const {
    fields: requestUris,
    append: requestAppend,
    remove: requestRemove,
  } = useFieldArray({
    control,
    name: 'request_uris',
  });
  const watchResponseTypes = watch('response_types');
  const watchGrantTypes = watch('grant_types');

  useEffect(() => {
    const isDirty =
      !isObjectEmpty(dirtyFields) &&
      Object.values(dirtyFields).some((field) => {
        if (Array.isArray(field))
          return field.some((elem) => (typeof elem === 'object' ? elem.value : elem));
        return field === true;
      });
    if (applicationFormChanged !== isDirty) dispatch(setApplicationFormChanged(isDirty));
  }, [Object.values(dirtyFields)]);

  useEffect(() => {
    return () => {
      dispatch(setApplicationFormChanged(false));
    };
  }, []);

  const closeSaveModal = () => setSaveModalOpen(false);

  const {
    fields: redirectUris,
    append: redirectAppend,
    remove: redirectRemove,
  } = useFieldArray({
    control,
    name: 'redirectUri',
  });

  const {
    fields: logoutUris,
    append: logoutAppend,
    remove: logoutRemove,
  } = useFieldArray({
    control,
    name: 'logoutUri',
  });

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    let duplicateRedirectUri;

    if (data.redirectUri.every((uri) => !uri.value)) {
      setError(`redirectUri.0.value`, { message: 'Обязательное поле' });
      return;
    }
    if (Object.keys(errors).length) return;

    data.redirectUri.reduce((acc: string[], item, index) => {
      if (acc.includes(item.value)) {
        setError(`redirectUri.${index}.value`, {
          message: 'Поле с таким адресом уже существует',
        });
        duplicateRedirectUri = true;
      }
      acc.push(item.value);
      return acc;
    }, [] as string[]);

    if (duplicateRedirectUri) return;
    const { redirectUri, logoutUri, request_uris, ...restData } = data;
    try {
      const res = await createClient({
        user_id: userId,

        redirect_uris: redirectUri.map((uri) => uri.value).filter((uri) => !!uri),
        post_logout_redirect_uris: logoutUri.map((uri) => uri.value).filter((uri) => !!uri),
        request_uris: request_uris.map((uri) => uri.value).filter((uri) => !!uri),
        ...restData,
      }).unwrap();
      navigate(`/applications/${res.client_id}`);
    } catch (error) {
      console.error('Error creating application:', error);
    }
  };
  return (
    <div className={'wrapper-scroll'}>
      <div className={'content'}>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)} className={styles['create-client-form']}>
            <div className={styles['padding-wrapper']}>
              <CustomTypography className={clsx('font-golos', 'text-24-medium', styles.title)}>
                Создать приложение
              </CustomTypography>
              <CustomTypography className={clsx('font-golos', 'text-17-regular', styles.subtitle)}>
                Основная информация
              </CustomTypography>
              <CustomTypography className={clsx('text-14', styles.asterisk, styles['input-title'])}>
                Название приложения
              </CustomTypography>
              <TextField
                {...register('name', {
                  onBlur: (event: FocusEvent<HTMLInputElement>) => {
                    setValue('name', event.target.value.trim());
                  },
                  onChange: () => {
                    if (errors.name) clearErrors('name');
                  },
                })}
                className="custom"
                error={!!errors.name}
                helperText={errors.name ? errors.name.message : ''}
                fullWidth
                variant="standard"
              />
              <CustomTypography className={clsx('text-14', styles['input-subtitle'])} color="grey">
                Имя приложения, отображаемое пользователям
              </CustomTypography>
              <CustomTypography className={clsx('text-14', styles['input-title'])}>
                Описание приложения
              </CustomTypography>
              <TextField
                {...register('description', {
                  onChange: (event: ChangeEvent<HTMLInputElement>) => {
                    if (event.target.value.length > 255) {
                      setError('description', {
                        message: 'Невозможно ввести более 255 символов',
                        type: 'validate',
                      });
                      setValue('description', event.target.value.slice(0, 255));
                    } else {
                      clearErrors('description');
                    }
                  },
                })}
                className="custom"
                fullWidth
                variant="standard"
                error={!!errors.description}
                helperText={errors.description ? errors.description.message : ''}
                multiline
              />
              <DescriptionField control={control} />
              <div>
                <CustomTypography className={clsx('text-14', styles['input-title'])}>
                  Логотип приложения
                </CustomTypography>
                <UploadAndDisplayImage
                  imgSrc={avatarSrc}
                  setImgSrc={setAvatarSrc}
                  componentName="create-client"
                  setAvatarError={setAvatarError}
                  clearAvatarError={clearAvatarError}
                  setAvatarValue={setAvatarValue}
                  DefaultIcon={<AppIcon fill="#ced0d9" />}
                />
                {errors.avatar && (
                  <CustomTypography
                    color="error"
                    className={clsx('text-14', styles['input-error'])}
                  >
                    {errors.avatar.message}
                  </CustomTypography>
                )}
                <CustomTypography className={clsx('text-14')} color="grey">
                  Файл с расширением .jpg, .jpeg, .png, .svg. Максимальный размер - 1 МБ.
                </CustomTypography>
              </div>
              <div className={styles['switch-wrapper']}>
                <CustomTypography className={clsx('text-14')}>
                  Отображать в каталоге
                </CustomTypography>
                <Controller
                  control={control}
                  name="is_visible"
                  defaultValue={false}
                  render={({ field }) => (
                    <Switch
                      checked={field.value}
                      onChange={(e) => field.onChange(e.target.checked)}
                    />
                  )}
                />
              </div>
              <div className={styles['switch-wrapper']}>
                <CustomTypography className={clsx('text-14')}>Ограниченный доступ</CustomTypography>
                <Controller
                  control={control}
                  name="authorize_only_admins"
                  defaultValue={false}
                  render={({ field }) => (
                    <Switch
                      checked={field.value}
                      onChange={(e) => field.onChange(e.target.checked)}
                    />
                  )}
                />
              </div>
            </div>
            <div className={styles.divider} />
            <div className={styles['padding-wrapper']}>
              <CustomTypography className={clsx('font-golos', 'text-17-regular', styles.subtitle)}>
                Параметры приложения
              </CustomTypography>
              <CustomTypography className={clsx('text-14', styles.asterisk, styles['input-title'])}>
                Адрес приложения
              </CustomTypography>
              <TextField
                {...register('domain', {
                  onChange: () => {
                    if (errors.domain) clearErrors('domain');
                  },
                })}
                className="custom"
                error={!!errors.domain}
                helperText={errors.domain ? errors.domain.message : ''}
                fullWidth
                variant="standard"
              />
              <CustomTypography className={clsx('text-14', styles['input-subtitle'])} color="grey">
                Адрес приложения в формате «протокол://доменное имя:порт»
              </CustomTypography>
              <>
                {redirectUris.map((uri, index) => {
                  return (
                    <div key={uri.id}>
                      <CustomTypography
                        className={clsx(
                          'text-14',

                          styles.asterisk,
                          styles['input-title'],
                        )}
                      >
                        Возвратный URL #{index + 1} (Redirect_uri)
                      </CustomTypography>
                      <div className={styles['field-item']}>
                        <TextField
                          {...register(`redirectUri.${index}.value`, {
                            onChange: () => {
                              if (errors?.redirectUri?.[index])
                                clearErrors(`redirectUri.${index}.value`);
                            },
                          })}
                          className={clsx('custom', styles['add-text-field'])}
                          onBlur={() => {
                            if (getValues('redirectUri').every((uri) => !uri.value))
                              setError(`redirectUri.0.value`, { message: 'Обязательное поле' });
                            else {
                              if (errors?.redirectUri?.[0]) clearErrors(`redirectUri.0.value`);
                              trigger(`redirectUri.${index}.value`);
                            }
                          }}
                          error={!!errors.redirectUri?.[index]}
                          helperText={
                            errors.redirectUri ? errors?.redirectUri?.[index]?.value?.message : ''
                          }
                          variant="standard"
                        />
                        {redirectUris.length > 1 ? (
                          <Button
                            variant="custom"
                            color="secondary"
                            onClick={() => redirectRemove(index)}
                            className={clsx(styles['delete-button'])}
                          >
                            Удалить
                          </Button>
                        ) : (
                          <RedirectUrisField
                            name="redirectUri"
                            control={control}
                            onClick={() => redirectAppend({ value: '', name: '' })}
                            className={styles['add-button']}
                          />
                        )}
                      </div>
                      {index === 0 && (
                        <CustomTypography
                          className={clsx('text-14', styles['input-subtitle'])}
                          color="grey"
                        >
                          Адрес, на который пользователь переадресовывается после авторизации
                        </CustomTypography>
                      )}
                    </div>
                  );
                })}
                {redirectUris.length > 1 && (
                  <RedirectUrisField
                    name="redirectUri"
                    control={control}
                    onClick={() => redirectAppend({ value: '', name: '' })}
                    className={clsx(styles['add-button'], styles['add-button-bottom'])}
                  />
                )}
              </>
              <>
                {logoutUris.map((uri, index) => {
                  return (
                    <div key={uri.id}>
                      <CustomTypography className={clsx('text-14', styles['input-title'])}>
                        URL выхода #{index + 1} (post_logout_redirect_uri)
                      </CustomTypography>
                      <div className={styles['field-item']}>
                        <TextField
                          {...register(`logoutUri.${index}.value`, {
                            required: true,
                            onChange: () => {
                              if (errors?.logoutUri?.[index])
                                clearErrors(`logoutUri.${index}.value`);
                            },
                          })}
                          className={clsx('custom', styles['add-text-field'])}
                          error={!!errors.logoutUri?.[index]}
                          helperText={
                            errors.logoutUri ? errors?.logoutUri?.[index]?.value?.message : ''
                          }
                          variant="standard"
                        />
                        {logoutUris.length > 1 ? (
                          <Button
                            variant="custom"
                            color="secondary"
                            onClick={() => logoutRemove(index)}
                            className={clsx(styles['delete-button'])}
                          >
                            Удалить
                          </Button>
                        ) : (
                          <RedirectUrisField
                            name="logoutUri"
                            control={control}
                            onClick={() => logoutAppend({ value: '', name: '' })}
                            className={styles['add-button']}
                          />
                        )}
                      </div>
                      {index === 0 && (
                        <CustomTypography
                          className={clsx('text-14', styles['input-subtitle'])}
                          style={{ width: '85%' }}
                          color="grey"
                        >
                          Адрес, на который переадресовывается пользователь после выхода. Если
                          значение не указано, то используется «Возвратный URL»
                        </CustomTypography>
                      )}
                    </div>
                  );
                })}
                {logoutUris.length > 1 && (
                  <RedirectUrisField
                    name="logoutUri"
                    control={control}
                    onClick={() => logoutAppend({ value: '', name: '' })}
                  />
                )}
              </>
              <>
                {requestUris.map((uri, index) => {
                  return (
                    <div key={uri.id}>
                      <CustomTypography className={clsx('text-14', styles['input-title'])}>
                        URL запроса аутентификации или восстановления после аутентификации #
                        {index + 1} (request_uris)
                      </CustomTypography>
                      <div className={styles['field-item']}>
                        <TextField
                          {...register(`request_uris.${index}.value`, {
                            onChange: () => {
                              if (errors.request_uris?.[index])
                                clearErrors(`request_uris.${index}.value`);
                            },
                          })}
                          className={clsx('custom', styles['add-text-field'])}
                          error={!!errors.request_uris?.[index]}
                          helperText={
                            errors.request_uris ? errors?.request_uris?.[index]?.value?.message : ''
                          }
                          variant="standard"
                        />
                        <Button
                          variant="custom"
                          color="secondary"
                          onClick={() => {
                            if (requestUris.length > 1) requestRemove(index);
                            else setValue('request_uris', [{ value: '', name: 'request_uris' }]);
                          }}
                          className={clsx(styles['delete-button'])}
                        >
                          Удалить
                        </Button>
                      </div>
                    </div>
                  );
                })}
                <RedirectUrisField
                  control={control}
                  onClick={() => requestAppend({ value: '', name: '' })}
                  className={clsx(styles['add-button'], styles['add-button-bottom'])}
                  name="request_uris"
                />
              </>
              <CustomTypography className={clsx('text-14', styles['input-title'])}>
                response_types
              </CustomTypography>
              <div className={styles['type-buttons-wrapper']}>
                {Object.values(EResponseTypes).map((type) => (
                  <Button
                    variant={
                      watchResponseTypes.find((findType) => findType === type)
                        ? 'contained'
                        : 'outlined'
                    }
                    className={styles.typeButton}
                    disabled={
                      type === EResponseTypes.code || type === EResponseTypes.none
                        ? false
                        : !watchGrantTypes.includes(EGrantTypes.implicit)
                    }
                    onClick={() => {
                      setValue(
                        'response_types',
                        watchResponseTypes.find((findType) => findType === type)
                          ? watchResponseTypes.filter((filterType) => filterType !== type)
                          : [...watchResponseTypes, type],
                        { shouldDirty: true },
                      );
                    }}
                    key={type}
                  >
                    {type}
                  </Button>
                ))}
              </div>
              <CustomTypography className={clsx('text-14', styles['input-title'])}>
                grant_types
              </CustomTypography>
              <div className={styles['type-buttons-wrapper']}>
                {Object.values(EGrantTypes).map((type) => (
                  <Button
                    className={styles.typeButton}
                    variant={
                      watchGrantTypes.find((findType) => findType === type)
                        ? 'contained'
                        : 'outlined'
                    }
                    onClick={() => {
                      const newGrantTypes = watchGrantTypes.find((findType) => findType === type)
                        ? watchGrantTypes.filter((filterType) => filterType !== type)
                        : [...watchGrantTypes, type];
                      if (!newGrantTypes.includes(EGrantTypes.implicit))
                        setValue(
                          'response_types',
                          watchResponseTypes.filter(
                            (type) => type === EResponseTypes.code || type === EResponseTypes.none,
                          ),
                        );

                      setValue('grant_types', newGrantTypes, { shouldDirty: true });
                    }}
                    key={type}
                  >
                    {type}
                  </Button>
                ))}
              </div>
              <CustomTypography className={clsx('text-14', styles['input-title'])}>
                Метод аутентификации клиента для конечной точки получения токена
                (token_endpoint_auth_method)
              </CustomTypography>
              <Controller
                control={control}
                name="token_endpoint_auth_method"
                render={({ field }) => (
                  <CustomSelect
                    style={{ width: '100%', marginBottom: 32 }}
                    value={field.value}
                    onChange={(e) => field.onChange(e.target.value)}
                  >
                    {Object.keys({ ...EAuthMethodType }).map((variant) => (
                      <MenuItem key={variant} value={variant} className="custom-select">
                        {variant}
                      </MenuItem>
                    ))}
                  </CustomSelect>
                )}
              />
              <CustomTypography className={clsx('text-14', styles['input-title'])}>
                Метод аутентификации, используемый при доступе к конечной точке проверки токена
                (introspection_endpoint_auth_method)
              </CustomTypography>
              <Controller
                control={control}
                name="introspection_endpoint_auth_method"
                render={({ field }) => (
                  <CustomSelect
                    style={{ width: '100%', marginBottom: 32 }}
                    value={field.value}
                    onChange={(e) => field.onChange(e.target.value)}
                  >
                    {Object.keys({ ...EAuthMethodType }).map((variant) => (
                      <MenuItem key={variant} value={variant} className="custom-select">
                        {variant}
                      </MenuItem>
                    ))}
                  </CustomSelect>
                )}
              />
              <CustomTypography className={clsx('text-14', styles['input-title'])}>
                Метод аутентификации, используемый при доступе к конечной точке отзыва токенов
                (revocation_endpoint_auth_method)
              </CustomTypography>
              <Controller
                control={control}
                name="revocation_endpoint_auth_method"
                render={({ field }) => (
                  <CustomSelect
                    style={{ width: '100%', marginBottom: 32 }}
                    value={field.value}
                    onChange={(e) => field.onChange(e.target.value)}
                  >
                    {Object.keys({ ...EAuthMethodType }).map((variant) => (
                      <MenuItem key={variant} value={variant} className="custom-select">
                        {variant}
                      </MenuItem>
                    ))}
                  </CustomSelect>
                )}
              />
              <CustomTypography className={clsx('text-14', styles['input-title'])}>
                Алгоритм подписи, используемый при создании подписанного ID-токена
                (id_token_signed_response_alg)
              </CustomTypography>
              <Controller
                control={control}
                name="id_token_signed_response_alg"
                render={({ field }) => (
                  <CustomSelect
                    style={{ width: '100%' }}
                    value={field.value}
                    onChange={(e) => field.onChange(e.target.value)}
                  >
                    {Object.keys({ ...ESigningAlgTypes }).map((variant) => (
                      <MenuItem key={variant} value={variant} className="custom-select">
                        {variant}
                      </MenuItem>
                    ))}
                  </CustomSelect>
                )}
              />
              <div className={styles['switch-wrapper']}>
                <CustomTypography className={clsx('text-14')}>require_auth_time</CustomTypography>
                <Controller
                  control={control}
                  name="require_auth_time"
                  defaultValue={false}
                  render={({ field }) => (
                    <Switch
                      checked={field.value}
                      onChange={(e) => field.onChange(e.target.checked)}
                    />
                  )}
                />
              </div>
              <div className={styles['switch-wrapper']}>
                <CustomTypography className={clsx('text-14')}>
                  require_signed_request_object
                </CustomTypography>
                <Controller
                  control={control}
                  name="require_signed_request_object"
                  defaultValue={false}
                  render={({ field }) => (
                    <Switch
                      checked={field.value}
                      onChange={(e) => field.onChange(e.target.checked)}
                    />
                  )}
                />
              </div>
              <CustomTypography className={clsx('text-14', styles['input-title'])}>
                Способ передачи ID пользователя в идентификационном токене (subject_type)
              </CustomTypography>
              <Controller
                control={control}
                name="subject_type"
                defaultValue={ESubjectTypeVariant.public}
                render={({ field }) => (
                  <CustomSelect
                    style={{ width: '100%', marginBottom: 32 }}
                    value={field.value}
                    onChange={(e) => field.onChange(e.target.value)}
                  >
                    {['public', 'pairwise'].map((type) => (
                      <MenuItem key={type} value={type} className="custom-select">
                        {type}
                      </MenuItem>
                    ))}
                  </CustomSelect>
                )}
              />
              <div className={styles['submit-buttons']}>
                <Button
                  onClick={() => {
                    if (applicationFormChanged) {
                      savedCallback.current = () => navigate(-1);
                      return setSaveModalOpen(true);
                    }
                    navigate(-1);
                  }}
                  variant="custom"
                  color="secondary"
                >
                  Отмена
                </Button>
                <Button className={styles['create-button']} type="submit" variant="custom">
                  Создать
                </Button>
              </div>
            </div>
          </form>
        </FormProvider>

        <ConfirmationModal
          title="Сохранение изменений"
          mainMessage={['Изменения не сохранены. Продолжить без сохранения?']}
          actionButtonText="Продолжить"
          isOpen={saveModalOpen}
          onAction={() => {
            savedCallback.current?.();
            dispatch(setApplicationFormChanged(false));
            setSaveModalOpen(false);
          }}
          onClose={closeSaveModal}
        />
      </div>
    </div>
  );
};

export const CreateApplication = connect(mapStateToProps)(CreateApplicationComponent);
