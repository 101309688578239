import React, { FC, useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import clsx from 'clsx';
import styles from './DeleteProfile.module.css';
import { TUser, useLazyGetUsersQuery } from '../../redux/services/client';
import { CLIENT_ID } from '../../constants';
import { useDeleteUsersMutation } from '../../redux/services/owner';
import { CustomButton } from '../custom/CustomButton';
import { useDispatch, useSelector } from 'react-redux';
import { setNotice } from '../../redux/noticesSlice';
import { useNavigate, useParams } from 'react-router-dom-v5-compat';
import { CustomTypography } from '../custom/CustomTypography';
import { RootState } from 'src/redux/store';

export const DeleteProfileAsAdmin: FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [deleteOption] = useState('account');

  const [getUsers] = useLazyGetUsersQuery();
  const [deleteUsers, deleteResult] = useDeleteUsersMutation();
  const client = useSelector((state: RootState) => state.app.clientProfile);
  const PROJECT_NAME = client?.name || 'PROJECT_NAME';

  useEffect(() => {
    if (deleteResult.isSuccess && deleteResult.data?.errors.length) {
      dispatch(
        setNotice({
          error: 'Ошибка',
          id: Math.random(),
          isRead: false,
          message: deleteResult.data?.errors[0],
          timestamp: new Date().toString(),
        }),
      );
    }
  }, [deleteResult]);

  const { clientId, userId = '' } = useParams<{ clientId: string; userId: string }>();
  const [selectedUser, setSelectedUser] = useState<
    { user: Partial<TUser>; role: string } | null | undefined
  >(null);

  useEffect(() => {
    const start = async () => {
      const { data: users } = await getUsers({
        client_id: clientId || '',
        number_of_skip: '0',
        sort_direction: 'asc',
        search_string: '',
        search_param_user_id: userId,
      });

      setSelectedUser(users?.find((user) => user.user.id === Number(userId)));
    };
    start();
  }, [clientId, userId]);

  const handleDelete = async () => {
    try {
      if (userId && deleteOption === 'account') {
        await deleteUsers({
          checked_ids: [parseInt(userId, 10)],
        });
      } else if (userId && deleteOption === 'accountAndApplicationsWithUsers') {
        await deleteUsers({
          checked_ids: [parseInt(userId, 10)],
        });
      }
      if (clientId === CLIENT_ID) navigate('/users');
      else navigate(`/applications/${clientId}`);
    } catch (e) {
      console.log('handleDelete error:', e);
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles['delete-account']}>
        <CustomTypography
          style={{ marginBottom: 24 }}
          className={clsx('text-24-medium', 'font-golos')}
        >
          Удалить аккаунт {PROJECT_NAME}
        </CustomTypography>
        <CustomTypography style={{ marginBottom: 8 }} className={clsx('text-14')}>
          Аккаунт{' '}
          <strong>
            {selectedUser?.user.nickname || selectedUser?.user.login || selectedUser?.user.id}
          </strong>{' '}
          будет удален. Данные, связанные с аккаунтом, удалятся навсегда. Пользователь потеряет
          доступ к приложениям, в которых для входа использовал аккаунт {PROJECT_NAME}.
        </CustomTypography>
        <CustomTypography style={{ marginBottom: 8 }} className={clsx('text-14')}>
          Вместе с аккаунтом будут удалены приложения, в которых удаляемый пользователь является
          владельцем.
        </CustomTypography>
        <div style={{ marginTop: 32, display: 'flex', justifyContent: 'flex-end' }}>
          <Button onClick={() => navigate(-1)} variant="custom" color="secondary">
            Отмена
          </Button>
          <CustomButton
            onClick={handleDelete}
            variant="custom"
            color="error"
            style={{ marginLeft: 24 }}
          >
            Удалить аккаунт
          </CustomButton>
        </div>
      </div>
    </div>
  );
};
