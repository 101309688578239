import { yupResolver } from '@hookform/resolvers/yup';
import Switch from '@mui/material/Switch';
import clsx from 'clsx';
import 'codemirror/lib/codemirror.css';
import 'codemirror/mode/htmlmixed/htmlmixed';
import { FC } from 'react';
import { Controlled as CodeMirror } from 'react-codemirror2';
import {
  Control,
  Controller,
  FormProvider,
  SubmitHandler,
  useForm,
  useWatch,
} from 'react-hook-form';
import { CustomTypography } from 'src/components/custom/CustomTypography';
import { BaseSidePanel } from 'src/components/sidePanel/BaseSidePanel';
import yup from 'src/customYup';
import styles from './EditWidgetPanel.module.css';
import { ViewWidget } from './ViewWidget';
import { WidgetColor } from './WidgetColor';
import { TWidgetInputs } from 'src/redux/types';
import { TClientFull, useUpdateClientMutation } from 'src/redux/services/clients';

export enum EWidgetSwitches {
  title = 'widget_title',
  showAvatar = 'show_avatar_in_widget',
  hideHeader = 'hide_widget_header',
  hideFooter = 'hide_widget_footer',
  buttonColor = 'widget_colors.button_color',
  fontColor = 'widget_colors.font_color',
  linkColor = 'widget_colors.link_color',
  info = 'widget_info',
}

const schema = yup.object({
  widget_colors: yup
    .object({
      button_color: yup
        .string()
        .required('Обязательное поле')
        .matches(/^#[0-9a-fA-F]{3}$|^#[0-9a-fA-F]{6}$/, 'Цвет должен быть в формате hex'),
      font_color: yup
        .string()
        .required('Обязательное поле')
        .matches(/^#[0-9a-fA-F]{3}$|^#[0-9a-fA-F]{6}$/, 'Цвет должен быть в формате hex'),
      link_color: yup
        .string()
        .required('Обязательное поле')
        .matches(/^#[0-9a-fA-F]{3}$|^#[0-9a-fA-F]{6}$/, 'Цвет должен быть в формате hex'),
    })
    .required(),
});

interface IEditWidgetPanelProps {
  isOpen: boolean;
  onClose: () => void;
  avatarSrc: string | null;
  coverSrc: string | null;
  selectedClient: TClientFull;
}

export const EditWidgetPanel: FC<IEditWidgetPanelProps> = ({
  isOpen,
  onClose,
  avatarSrc,
  coverSrc,
  selectedClient,
}) => {
  const [updateClient] = useUpdateClientMutation();

  const methods = useForm<TWidgetInputs>({
    resolver: yupResolver(schema),
    defaultValues: {
      show_avatar_in_widget: selectedClient?.show_avatar_in_widget || false,
      hide_widget_header: selectedClient?.hide_widget_header || false,
      hide_widget_footer: selectedClient?.hide_widget_footer || false,
      widget_title: selectedClient?.widget_title || '',
      widget_colors: selectedClient?.widget_colors || {
        button_color: '',
        font_color: '',
        link_color: '',
      },
      widget_info: selectedClient?.widget_info || '',
    },
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });
  const { handleSubmit, reset, control } = methods;

  const widget_title = useWatch({ control, name: EWidgetSwitches.title });
  const show_avatar_in_widget = useWatch({ control, name: EWidgetSwitches.showAvatar });
  const hide_widget_header = useWatch({ control, name: EWidgetSwitches.hideHeader });
  const hide_widget_footer = useWatch({ control, name: EWidgetSwitches.hideFooter });
  const button_color = useWatch({ control, name: EWidgetSwitches.buttonColor });
  const font_color = useWatch({ control, name: EWidgetSwitches.fontColor });
  const link_color = useWatch({ control, name: EWidgetSwitches.linkColor });
  const widget_info = useWatch({ control, name: EWidgetSwitches.info });

  const renderControlledSwitch = (
    name: EWidgetSwitches.showAvatar | EWidgetSwitches.hideHeader | EWidgetSwitches.hideFooter,
    control: Control<TWidgetInputs, any>,
  ) => {
    return (
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value } }) => (
          <Switch checked={Boolean(value)} onChange={(e) => onChange(e.target.checked)} />
        )}
      />
    );
  };

  const onSubmit: SubmitHandler<TWidgetInputs> = async (data) => {
    await Promise.all([
      updateClient({
        id: selectedClient.client_id,
        data,
      }),
    ]);
  };

  const handleSave = () => {
    handleSubmit(onSubmit)();
    onClose();
  };

  const handleClose = () => {
    reset();
    onClose();
  }

  return (
    <BaseSidePanel
      handleClosePanel={handleClose}
      isOpenPanel={isOpen}
      title="Настроить внешний вид виджета"
      isNoBackdrop={false}
      handleConfirm={handleSave}
    >
      <div className={styles.container}>
        <div className={styles.switchWrapper}>
          <CustomTypography className="text-14">
            Показывать логотип приложения на виджете
          </CustomTypography>
          {renderControlledSwitch(EWidgetSwitches.showAvatar, control)}
        </div>
        <div className={styles.switchWrapper}>
          <CustomTypography className="text-14">Скрыть шапку</CustomTypography>
          {renderControlledSwitch(EWidgetSwitches.hideHeader, control)}
        </div>
        <div className={styles.switchWrapper}>
          <CustomTypography className="text-14">Скрыть подвал</CustomTypography>
          {renderControlledSwitch(EWidgetSwitches.hideFooter, control)}
        </div>
        <FormProvider {...methods}>
          <WidgetColor />
        </FormProvider>
        <div className={styles.infoContainer}>
          <CustomTypography className={clsx('text-14', styles.infoText)}>
            Дополнительное информационное поле
          </CustomTypography>
          <Controller
            name={'widget_info'}
            control={control}
            render={({ field: { onChange, value } }) => (
              <CodeMirror
                className={styles.codeMirror}
                value={value}
                options={{
                  mode: 'htmlmixed',
                  lineNumbers: true,
                }}
                onBeforeChange={(editor, data, value) => {
                  onChange(value);
                }}
                onChange={() => {}}
              />
            )}
          />
        </div>
        <div className={styles.widgetWrapper}>
          <CustomTypography className={clsx('text-14', styles.preview)}>Превью:</CustomTypography>
          <ViewWidget
            props={{
              widget_title,
              show_avatar_in_widget,
              hide_widget_header,
              hide_widget_footer,
              widget_colors: {
                button_color,
                font_color,
                link_color,
              },
              widget_info,
            }}
            appName={selectedClient?.name || ''}
            avatarSrc={avatarSrc}
            coverSrc={coverSrc}
          />
        </div>
      </div>
    </BaseSidePanel>
  );
};
