import React, { useState } from 'react';
import { ProviderTypeConfig } from 'src/helpers';
import { TMiscProvider } from 'src/redux/services/provider';
import { IEditProviderProps } from '../Providers/editPanel/EditProvider';
import { License } from './license/License';
import { Sentry } from './sentry/Sentry';
import { TrustedProviders } from './trustedProviders/TrustedProviders';

export const addProvidersTypes: ProviderTypeConfig = {
  ['LDAP']: 'LDAP',
  ['ALDPRO']: 'ALDPRO',
  ['1C']: '1C',
  ['IDM']: 'IDM',
  ['QRCODE']: 'Device flow',
};

export enum addProviderType {
  LDAP = 'LDAP',
  ALDPRO = 'ALDPRO',
  _1C = '1C',
  IDM = 'IDM',
}

export enum addMethodsType {
  QRCODE = 'QRCODE',
  ESIA = 'ESIA',
}
export interface ITrustedProviderProps<T = TMiscProvider> extends IEditProviderProps {
  providerToEdit: Partial<T & { provider_id: string; license_id: number }> | null;
}

export const AdvancedSettings: React.FC = () => {
  const [updateLicense, setUpdateLicense] = useState<boolean>(false);

  const updateLicenseHandler = () => {
    setUpdateLicense((prev) => !prev);
  };

  return (
    <>
      <TrustedProviders updateLicenses={updateLicenseHandler} />
      <License updateLicense={updateLicense} />
      <Sentry />
    </>
  );
};
