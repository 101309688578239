import { BACKEND_URL, CLIENT_ID } from '../constants';
import { TCustomFields, TUserProfile } from '../redux/userSlice';
import { getAccessToken } from '../service/auth';
import { sendLogoutRequest } from '../requests/oidc';
import { Roles } from 'src/enums';
import { TClient } from 'src/redux/services/clients';

export enum PrivateScopesMode {
  SET_PRIVATE = 'SET_PRIVATE',
  SET_PUBLIC_OAUTH = 'SET_PUBLIC_OAUTH',
  SET_PUBLIC_GRAVATAR = 'SET_PUBLIC_GRAVATAR',
}

export interface ISettingsUser {
  profile_privacy: boolean;
  [key: string]: any;
}

export interface IPublicProfile extends TUserProfile {
  [key: string]:
    | string
    | undefined
    | boolean
    | number
    | number[]
    | null
    | TCustomFields
    | { client_id: string; role: Roles }[];
}

export const getUserRoleInApp = async (
  userId: string,
  clientId: string,
): Promise<TUserProfile['roleRootApp']> => {
  try {
    const accessToken = await getAccessToken();

    const response = await fetch(BACKEND_URL + `/api/v1/clients/${clientId}/users/${userId}/role`, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + accessToken,
      },
    });
    const { role } = await response.json();
    return role;
  } catch (e) {
    console.error('getUserRoleInApp error: ', e);
  }
};

export const getFirstClientInfo = async (): Promise<TClient | undefined> => {
  try {
    const response = await fetch(BACKEND_URL + `/api/v1/clients/` + CLIENT_ID, {
      method: 'GET',
    });
    return await response.json();
  } catch (e) {
    console.error('getFirstClientInfo error: ', e);
  }
};

export const checkAppsBeforeDelete = async (userId: string) => {
  try {
    const accessToken = await getAccessToken();
    const response = await fetch(`${BACKEND_URL}/api/user/v1/check_apps_before_delete/${userId}`, {
      headers: { Authorization: 'Bearer ' + accessToken },
    });
    const responseJson = response.json();
    return responseJson;
  } catch (e) {
    console.error('checkAppsBeforeDelete error: ', e);
  }
};

export const checkCredentials = async (identifier: string, password: string) => {
  try {
    const body = 'identifier=' + identifier + '&password=' + password;
    let verified;

    const response = await fetch(`${BACKEND_URL}/api/v1/auth/check_credentials`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body,
    });

    if (response?.ok) ({ verified } = await response.json());

    return !!verified;
  } catch (e) {
    console.error('checkCredentials error: ', e);
  }
};

export const checkIdentifier = async (identifier: string) => {
  let isActive;
  try {
    const body = 'identifier=' + encodeURIComponent(identifier);

    const response = await fetch(BACKEND_URL + '/api/v1/auth/check_identifier', {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded ',
      },
      method: 'POST',
      body,
    });
    if (response) ({ is_active: isActive } = await response.json());

    return !!isActive;
  } catch (e) {
    console.error('checkIdentifier error: ', e);
  }
};

export const getPublicProfile = async (userEmail: string): Promise<IPublicProfile | undefined> => {
  try {
    const accessToken = await getAccessToken();
    const response = await fetch(
      `${BACKEND_URL}/api/user/v1/profile?email=${encodeURIComponent(userEmail)}`,
      {
        headers: {
          Authorization: 'Bearer ' + accessToken,
        },
      },
    );

    if (!response.ok) {
      console.error('getPublicProfile error: ', response.statusText);
    }

    return await response.json();
  } catch (e) {
    console.error('getPublicProfile error: ', e);
  }
};

export const getVCard = async (userEmail: string) => {
  try {
    const accessToken = await getAccessToken();
    const response = await fetch(
      `${BACKEND_URL}/api/user/v1/profile/vcard?email=${encodeURIComponent(userEmail)}`,
      {
        headers: {
          Authorization: 'Bearer ' + accessToken,
        },
      },
    );
    return await response.blob();
  } catch (e) {
    console.error('getVCard error: ', e);
  }
};

export const getSettingsUser = async (): Promise<ISettingsUser | undefined> => {
  try {
    const accessToken = await getAccessToken();
    const response = await fetch(`${BACKEND_URL}/api/user/v1/settings`, {
      headers: {
        Authorization: 'Bearer ' + accessToken,
      },
    });
    return await response.json();
  } catch (e) {
    console.error('getSettingsUser error: ', e);
  }
};

export const setSettingsUser = async (settings: Partial<ISettingsUser>) => {
  try {
    const accessToken = await getAccessToken();

    // Преобразование объекта settings в JSON
    const body = JSON.stringify({
      profile_privacy: settings.profile_privacy,
    });

    await fetch(`${BACKEND_URL}/api/user/v1/settings`, {
      method: 'PUT',
      headers: {
        Authorization: 'Bearer ' + accessToken,
        'Content-Type': 'application/json',
      },
      body,
    });
  } catch (e) {
    console.error('setSettingsUser error: ', e);
  }
};
