import React, { FC } from 'react';
import Button from '@mui/material/Button';
import clsx from 'clsx';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import PostAddOutlinedIcon from '@mui/icons-material/PostAddOutlined';
import { CustomMuiIcon } from '../../custom/CustomMuiIcon';
import { CustomTypography } from '../../custom/CustomTypography';
import styles from './EmptyList.module.css';

interface IEmptyProvidersProps {
  title: string;
  onClick: () => void;
}

export const EmptyProviders: FC<IEmptyProvidersProps> = ({ title, onClick }) => {
  return (
    <div className={styles.emptyContent}>
      <CustomMuiIcon className={styles.emptyIcon} Icon={SearchOutlinedIcon} color="grey" />
      <CustomTypography className={clsx('text-17', styles.emptyText)} color="grey">
        Список пуст
      </CustomTypography>
      <CustomTypography color="grey" className={clsx('text-14', styles.emptyText)}>
        {`Нет доступных ${title}`}
      </CustomTypography>
      <Button
        onClick={onClick}
        variant="custom"
        className={clsx('text-14')}
        color="secondary"
        startIcon={<PostAddOutlinedIcon />}
      >
        Создать
      </Button>
    </div>
  );
};
