import React, { memo, useState } from 'react';
import { SentryPanel } from './SentryPanel';
import { SettingsItem } from 'src/components/settings/SettingsItem';

const SentryComponent = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <SettingsItem title="Sentry" onClick={() => setIsModalOpen(true)} />
      <SentryPanel isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
    </>
  );
};

export const Sentry = memo(SentryComponent);
