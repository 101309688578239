import PostAddOutlinedIcon from '@mui/icons-material/PostAddOutlined';
import { Tooltip } from '@mui/material';
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import clsx from 'clsx';
import React, { FC, useState } from 'react';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import {
  TRuleValidation,
  useGetRuleValidationsByFieldNameQuery,
  useGetRuleValidationsQuery,
} from '../../../redux/services/settings';
import { CustomTypography } from '../../custom/CustomTypography';
import { EditRuleValidation } from './EditRuleValidation';
import styles from './EditRuleValidations.module.css';
import { RuleValidation } from './RuleValidation';
import { useSelector } from 'react-redux';
import { RootState } from 'src/redux/rootReducer';

export type TEditRuleValidations = {
  isOpen: boolean;
  field_name: string;
  close: () => void;
};

export const EditRuleValidations: FC<TEditRuleValidations> = ({ isOpen, field_name, close }) => {
  const handleDrawerClose = () => {
    close();
  };

  const [ruleValidationModalOpen, setRuleValidationModalOpen] = useState(false);
  const [ruleValidationField, setRuleValidationField] = useState<TRuleValidation | undefined>(
    undefined,
  );

  const { data: ruleValidations = [] } = useGetRuleValidationsQuery();
  const { data: ruleValidationsField = [] } = useGetRuleValidationsByFieldNameQuery(field_name);

  const listTitleRuleValidations = ruleValidations
    .map((ruleValidation) => (
      <RuleValidation
        key={ruleValidation.id}
        profile={ruleValidation}
        onEdit={() => {
          setRuleValidationField(ruleValidation);
          setRuleValidationModalOpen(true);
        }}
        checkBox={!!ruleValidationsField.find((f) => f.id === ruleValidation.id)}
        field_name={field_name}
      />
    ))
    .reverse()
    .sort((a, b) => {
      if (a.props.checkBox && !b.props.checkBox) {
        return -1;
      }
      if (!a.props.checkBox && b.props.checkBox) {
        return 1;
      }
      return 0;
    });

  const headerText = `Настройка правил валидации для '${field_name}'`;
  const isMobile = useSelector((state: RootState) => state.app.isMobile);
  return (
    <Drawer
      classes={{ paper: styles['drawer-paper'] }}
      onClose={() => handleDrawerClose()}
      open={isOpen}
      anchor={isMobile ? 'bottom' : 'right'}
      variant="temporary"
    >
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <CustomTypography className={clsx('text-24-medium', 'font-golos')}>
            {headerText}
          </CustomTypography>
          <Tooltip title="Создать">
            <Button
              data-id="side-panel-create-button"
              variant="custom"
              component="label"
              color="secondary"
              onClick={() => setRuleValidationModalOpen(true)}
              startIcon={<PostAddOutlinedIcon className={styles['create-button-icon']} />}
            />
          </Tooltip>
          <IconButton
            data-id="side-panel-close-button"
            className={styles['close-button']}
            onClick={handleDrawerClose}
          >
            <CloseOutlinedIcon />
          </IconButton>
        </div>
        <CustomTypography className={styles.description}>
          Выберите правила валидации, которые хотите применить для поля:
        </CustomTypography>
        {listTitleRuleValidations}
      </div>
      <EditRuleValidation
        isOpen={ruleValidationModalOpen}
        close={() => {
          setRuleValidationField(undefined);
          setRuleValidationModalOpen(false);
        }}
        selectedRuleValidation={ruleValidationField}
      />
    </Drawer>
  );
};
