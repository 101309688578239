import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { BACKEND_URL } from '../../constants';
import { getAccessToken } from '../../service/auth';
import { TSentry } from './client';
import { EClaimPrivacy } from '../../components/profile/PublicStatusPopover';
import { PrivateScopesMode } from '../../requests/user';
import { ProviderType } from './provider';
import { IEmailTemplate } from 'src/components/applications/old/EmailTemplate';
import { TClientFull } from './clients';

export enum TargetType {
  user = 'USER',
  client = 'CLIENT',
  provider = 'PROVIDER',
}

export type TRuleValidation = {
  id: number;
  active: boolean;
  title: string;
  error: string;
  regex: string;
};

export type TCreateRuleValidation = {
  active?: boolean;
  title: string;
  error: string;
  regex: string;
};

export type TUpdateRuleValidation = Partial<TCreateRuleValidation>;

export type TRule = {
  target: TargetType;
  field_name: string;
  default?: string;
  editable: boolean;
  required: boolean;
  unique: boolean;
  active: boolean;
  validations: TRuleValidation[];
};

export type TRules = TRule[];

export type TCreateRule = {
  target?: TargetType;
  field_name: string;
  default?: string;
  editable?: boolean;
  required?: boolean;
  unique?: boolean;
  active?: boolean;
};

export type TUpdateRule = {
  default?: string;
  editable?: boolean;
  required?: boolean;
  unique?: boolean;
  active?: boolean;
};

export type TUpdateProfileField = {
  field?: string;
  title?: string;
  default?: string;
  mapping_vcard?: string;
  required?: boolean;
  unique?: boolean;
  active?: boolean;
  editable?: boolean;
  allowed_as_login?: boolean;
  claim?: PrivateScopesMode;
};

export type TCreateProfileField = {
  field: string;
  title: string;
  default?: string;
  mapping_vcard?: string;
  required: boolean;
  unique: boolean;
  active: boolean;
  editable: boolean;
  allowed_as_login?: boolean;
  claim: PrivateScopesMode;
};

export interface TProfileField {
  type: 'general' | 'custom';
  field: string;
  title: string;
  default?: string;
  required: boolean;
  unique: boolean;
  active: boolean;
  editable: boolean;
  claim: EClaimPrivacy;
  mapping_vcard?: string;
  allowed_as_login?: boolean;
}

export type TProfileFields = TProfileField[];

export interface TProviderRule {
  type: ProviderType;
  license: 'license' | 'free';
  allowedScopes: ProviderScope[];
  unique: boolean;
  editable: boolean;
  requireable: boolean;
  deletable: boolean;
  title: string;
}
export type TProviderRules = TProviderRule[];

export enum ProviderScope {
  login = 'login',
  trusted = 'trusted',
  otp = 'otp',
  internal = 'internal',
}

export interface TLicenseInfo {
  value: string;
  expirationTime: number;
  providers: string[];
}

export type TSettings = {
  registration_policy: string;
  allow_everyone_to_create_applications: boolean;
  authorize_only_admins: boolean;
  allowed_symbols: string;
  ldap_password_error: string;
  allowed_special_symbols: string;
  min_uppercase_count: number;
  number: number;
  spec_char: number;
  length_char_min: number;
  length_char_max: number;
  max_age: number;
  min_age: number;
  default_public_profile_claims_oauth: string;
  default_public_profile_claims_gravatar: string;
  allowed_login_fields: string;
  email_templates: IEmailTemplate[];
  sentry: TSentry;
};

export type TUpdateSettings = Partial<TSettings>;

export const settingsApi = createApi({
  reducerPath: 'settingsApi',
  tagTypes: ['Settings', 'ProfileFields', 'Rules'],
  baseQuery: fetchBaseQuery({
    baseUrl: `${BACKEND_URL}/api/v1/settings`,
    prepareHeaders: async (headers) => {
      const accessToken = await getAccessToken();
      headers.set('authorization', `Bearer ${accessToken}`);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getSettings: builder.query<TSettings, void>({
      query: () => '',
      providesTags: ['Settings'],
    }),

    editSettings: builder.mutation<TClientFull, TUpdateSettings>({
      query: (body) => ({ method: 'PUT', body, url: '' }),
      invalidatesTags: ['Settings'],
    }),

    getRules: builder.query<TRules, void>({
      query: () => 'rules',
      providesTags: ['Rules'],
    }),

    getProfileFields: builder.query<TProfileFields, void>({
      query: () => `profile_fields`,
      providesTags: ['ProfileFields'],
    }),

    createProfileField: builder.mutation<void, TCreateProfileField>({
      query: (body) => ({ method: 'POST', body, url: 'profile_fields' }),
      invalidatesTags: ['ProfileFields'],
    }),

    updateProfileField: builder.mutation<void, { field_name: string; body: TUpdateProfileField }>({
      query: ({ field_name, body }) => ({
        method: 'PUT',
        body,
        url: `profile_fields/${field_name}`,
      }),
      invalidatesTags: ['ProfileFields'],
    }),

    deleteProfileField: builder.mutation<void, string>({
      query: (field_name) => ({ method: 'DELETE', url: `profile_fields/${field_name}` }),
      invalidatesTags: ['ProfileFields'],
    }),

    getRuleValidations: builder.query<TRuleValidation[], void>({
      query: () => 'rules_validations',
      providesTags: ['Rules'],
    }),

    getRuleValidationsByFieldName: builder.query<TRuleValidation[], string>({
      query: (field_name) => ({ method: 'GET', url: `rules_validations/${field_name}` }),
      providesTags: ['Rules'],
    }),

    deleteRuleValidation: builder.mutation<void, number>({
      query: (id) => ({ method: 'DELETE', url: `rules_validations/${id}` }),
      invalidatesTags: ['Rules'],
    }),

    createRuleValidation: builder.mutation<void, TCreateRuleValidation>({
      query: (body) => ({ method: 'POST', body, url: 'rules_validations' }),
      invalidatesTags: ['Rules'],
    }),

    updateRuleValidation: builder.mutation<void, { id: number; body: TUpdateRuleValidation }>({
      query: ({ id, body }) => ({ method: 'PUT', body, url: `rules_validations/${id}` }),
      invalidatesTags: ['Rules'],
    }),

    addRuleValidationToRule: builder.mutation<void, { field_name: string; id: number }>({
      query: ({ field_name, id }) => ({
        method: 'POST',
        url: `rules/${field_name}/rules_validations/${id}`,
      }),
      invalidatesTags: ['Rules'],
    }),

    removeRuleValidationFromRule: builder.mutation<void, { field_name: string; id: number }>({
      query: ({ field_name, id }) => ({
        method: 'DELETE',
        url: `rules/${field_name}/rules_validations/${id}`,
      }),
      invalidatesTags: ['Rules'],
    }),
  }),
});

export const {
  useGetSettingsQuery,
  useEditSettingsMutation,
  useGetRulesQuery,
  useGetProfileFieldsQuery,
  useCreateProfileFieldMutation,
  useUpdateProfileFieldMutation,
  useDeleteProfileFieldMutation,
  useGetRuleValidationsQuery,
  useGetRuleValidationsByFieldNameQuery,
  useDeleteRuleValidationMutation,
  useCreateRuleValidationMutation,
  useUpdateRuleValidationMutation,
  useAddRuleValidationToRuleMutation,
  useRemoveRuleValidationFromRuleMutation,
} = settingsApi;
