import { Box } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import clsx from 'clsx';
import DOMPurify from 'dompurify';
import { FC } from 'react';
import { useParams } from 'react-router-dom-v5-compat';
import {
  EGetProviderAction,
  ProviderType,
  useGetProvidersQuery,
} from 'src/redux/services/provider';
import { BACKEND_URL, COPYRIGHT } from '../../../constants';
import { getImageURL, replaceJSX } from '../../../helpers';
import { ReactComponent as IdIcon } from '../../../icons/Id.svg';
import { CustomTypography } from '../../custom/CustomTypography';
import styles from './ViewWidget.module.css';
import { TWidgetInputs } from 'src/redux/types';
import { useGetSettingsQuery } from 'src/redux/services/settings';

export interface IViewWidgetProps {
  coverSrc: string | null;
  avatarSrc: string | null;
  props: Partial<TWidgetInputs>;
  appName: string;
}

export const ViewWidget: FC<IViewWidgetProps> = ({
  coverSrc,
  avatarSrc,
  props: {
    hide_widget_header,
    hide_widget_footer,
    show_avatar_in_widget,
    widget_title,
    widget_colors,
    widget_info,
  },
  appName,
}) => {
  const { clientId = '' } = useParams<{ clientId: string }>();
  const { data: dataSettings } = useGetSettingsQuery();

  const { data: providers = [] } = useGetProvidersQuery({
    client_id: clientId,
    onlyActive: true,
    action: EGetProviderAction.all,
  });

  let label = 'Введите';
  let allowedLoginFields: string[] = [];
  if (dataSettings?.allowed_login_fields) {
    allowedLoginFields = dataSettings?.allowed_login_fields.trim().split(' ');
    for (let index = 0; index < allowedLoginFields.length; index++) {
      const element = allowedLoginFields[index];

      if (allowedLoginFields.length > 1 && index !== 0) {
        label += index === allowedLoginFields.length - 1 ? ' или' : ',';
      }

      switch (element) {
        case 'login':
          label += ' логин';
          break;
        case 'email':
          label += ' почту';
          break;
        case 'phone_number':
          label += ' телефон';
          break;
      }
    }
  }

  return (
    <Box className={styles.widget}>
      {!hide_widget_header && (
        <CustomTypography className={styles['widget-title']}>
          Авторизуйтесь пользователем Trusted
        </CustomTypography>
      )}
      {coverSrc && (
        <img
          key={'cover' + coverSrc}
          src={`${coverSrc?.startsWith('data:') ? '' : BACKEND_URL + '/'}${coverSrc}`}
          width="100%"
          style={{ display: 'block', marginBottom: 8 }}
        />
      )}
      <div style={{ padding: '0 24px' }}>
        {!coverSrc && <div className={styles.divider} />}
        <div className={styles['title-container']}>
          <div>
            {show_avatar_in_widget && avatarSrc && (
              <img
                src={`${avatarSrc?.startsWith('data:') ? '' : BACKEND_URL + '/'}${avatarSrc}`}
                width="40px"
                height="40px"
                style={{ margin: '0 10px' }}
              />
            )}
          </div>
          <CustomTypography className={clsx(styles['widget-subtitle'])}>
            {replaceJSX(
              widget_title || '',
              'WIDGET_APP_NAME',
              <span key={'key'} style={{ color: widget_colors?.button_color }}>
                {appName}
              </span>,
            )}
          </CustomTypography>
        </div>
        <TextField label={label} variant="standard" fullWidth className={styles['text-field']} />
        <div className={styles['widget-buttons']}>
          <Button style={{ color: widget_colors?.button_color, fontWeight: 400 }}>
            СОЗДАТЬ АККАУНТ
          </Button>
          <Button
            className={styles['widget-second-button']}
            style={{
              background: widget_colors?.button_color,
              color: widget_colors?.font_color,
            }}
          >
            ДАЛЕЕ
          </Button>
        </div>
        <div className={styles.divider}></div>
        <div>
          <CustomTypography color="grey" className={clsx('text-12', styles['widget-text'])}>
            Доступные способы авторизации
          </CustomTypography>
          <div className={styles.providers}>
            {providers.map((provider) => {
              if (provider.type === ProviderType.CREDENTIALS) {
                return null;
              }
              return (
                <div key={provider.id} className={styles.provider}>
                  <Avatar src={getImageURL(provider.avatar)} className={styles.providerIcon}>
                    {!provider.avatar && <IdIcon />}
                  </Avatar>
                </div>
              );
            })}
          </div>
        </div>
        {widget_info && (
          <div className={styles.preview}>
            <div
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(widget_info),
              }}
            />
          </div>
        )}
        {!hide_widget_footer && (
          <div className={styles.footer}>
            <CustomTypography
              style={{
                marginLeft: 'auto',
                fontSize: 12,
                width: 'fit-content',
              }}
            >
              {COPYRIGHT}
            </CustomTypography>
          </div>
        )}
      </div>
    </Box>
  );
};
