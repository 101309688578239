import { ReactNode } from 'react';
import { FieldValues, Path, PathValue, UseFormReturn } from 'react-hook-form';
import { ProviderHeader } from '../Providers/ProviderHeader';
import { ConfirmationModal } from '../modal/ConfirmationModal';
import { ModalCloseOnly } from '../modal/ModalCloseOnly';
import { ProviderSettingsSidePanel } from '../sidePanel/ProviderSettingsSidePanel';
import { SharedFormProvider } from './FormProvider';

interface IProviderSidePanelProps<T extends FieldValues> {
  isOpen: boolean;
  onClose: () => void;
  pasteFields: () => void;
  methods: UseFormReturn<T, object>;
  onSubmit: () => void;
  isOpenSaveModal: boolean;
  isClipboardModalOpen: boolean;
  onCloseModal: () => void;
  watchDescription: string;
  text: Path<T>;
  overrideImage: string | null;
  loading: boolean;
  children: ReactNode;
  avatar: string | null;
}

export const ProviderSidePanel = <T extends FieldValues>({
  isOpen,
  onClose,
  pasteFields,
  methods,
  onSubmit,
  isOpenSaveModal,
  isClipboardModalOpen,
  onCloseModal,
  watchDescription,
  text,
  overrideImage,
  loading,
  children,
  avatar,
}: IProviderSidePanelProps<T>) => {
  const {
    setValue,
    setError,
    clearErrors,
  } = methods;
    const setAvatarValue = (value: PathValue<T, Path<T>>) => setValue(text, value, { shouldDirty: true });
    const setAvatarError = (error: string) => setError(text, { message: error });
    const clearAvatarError = () => clearErrors(text);

  return (
    <ProviderSettingsSidePanel
      handleClosePanel={onClose}
      isOpenPanel={isOpen}
      title="Редактировать способ входа по смс"
      setPasteFields={pasteFields}
      isNoBackdrop
    >
      <SharedFormProvider<T>
        methods={methods}
        onSubmit={onSubmit}
        handleCancel={onClose}
        isDisabled={loading}
      >
        <div>
          <ProviderHeader
            pathToAvatar={avatar}
            watchDescription={watchDescription}
            overrideImage={overrideImage}
            setAvatarError={setAvatarError}
            clearAvatarError={clearAvatarError}
            setAvatarValue={setAvatarValue}
          />
          {children}
        </div>
      </SharedFormProvider>

      <ConfirmationModal
        title="Сохранение изменений"
        mainMessage={['Изменения не сохранены. Продолжить без сохранения?']}
        actionButtonText="Продолжить"
        isOpen={isOpenSaveModal}
        onAction={() => {
          onCloseModal();
          onClose();
        }}
        onClose={onCloseModal}
      />

      <ModalCloseOnly
        isOpen={isClipboardModalOpen}
        onCloseAction={onCloseModal}
        title="Вставить настройки"
        message="Скопированные настройки не подходят для смс провайдера."
      />
    </ProviderSettingsSidePanel>
  );
};
