import BlockOutlinedIcon from '@mui/icons-material/BlockOutlined';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom-v5-compat';
import { Roles } from 'src/enums';
import { RootState } from 'src/redux/rootReducer';
import { TClientFull, useLazyGetClientInfoQuery } from 'src/redux/services/clients';
import { CLIENT_ID } from '../../constants';
import { CustomMuiIcon } from '../custom/CustomMuiIcon';
import { CustomTypography } from '../custom/CustomTypography';
import { ClientSettings } from './clientSettings/ClientSettings';
import { Settings } from './Settings';

const SettingsWrapperComponent = () => {
  const { clientId = '' } = useParams<{ clientId: string }>();
  const [selectedClient, setSelectedClient] = useState<TClientFull | null>(null);
  const [getProfile] = useLazyGetClientInfoQuery();
  const roleInApp = useSelector((state: RootState) => state.user.userProfile.roleRootApp);

  useEffect(() => {
    const fetchClientInfo = async () => {
      const fetchedClient = await getProfile({ id: clientId }).unwrap();
      setSelectedClient(fetchedClient);
    };

    fetchClientInfo();
  }, []);

  if ((roleInApp === Roles.ADMIN || roleInApp === Roles.USER) && clientId === CLIENT_ID) {
    return (
      <div className="wrapper">
        <div
          className="content"
          style={{
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div>
            <CustomTypography className="text-16">Не достаточно прав доступа</CustomTypography>
            <CustomTypography className="text-14" color="grey">
              Для редактирования настроек главного приложения
            </CustomTypography>
            <CustomMuiIcon
              Icon={BlockOutlinedIcon}
              style={{ width: 40, height: 40 }}
              color="grey"
            />
          </div>
        </div>
      </div>
    );
  }

  if (!selectedClient) {
    return <CustomTypography>Загрузка...</CustomTypography>;
  }

  return (
    <>
      {clientId === CLIENT_ID ? (
        <Settings selectedClient={selectedClient} />
      ) : (
        <ClientSettings selectedClient={selectedClient} />
      )}
    </>
  );
};

export const SettingsWrapper = SettingsWrapperComponent;
