import React, { useState, useEffect, KeyboardEvent, FC } from 'react';
import clsx from 'clsx';
import styles from './AddPhoneBySms.module.css';
import { Button, TextField } from '@mui/material';
import { useAddPhoneMutation, useLazyGetUserQuery } from '../../redux/services/user';
import { useNavigate, useParams } from 'react-router-dom-v5-compat';
import { NumericFormatCustom } from './NumericFormatCustom';
import { useCallPhoneMutation, useLazyCheckPhoneExistsQuery } from '../../redux/services/phone';
import { connect, useDispatch } from 'react-redux';
import { RootState } from '../../redux/store';
import { setUserProfile, TUserProfile } from '../../redux/userSlice';
import { CustomTypography } from '../custom/CustomTypography';
import { convertUserProfile } from 'src/helpers';

const mapStateToProps = (state: RootState) => ({
  currentPhone: state.user.userProfile.phone_number,
});

type TAddPhoneComponent = {
  currentPhone?: TUserProfile['phone_number'];
};

export const AddPhoneComponent: FC<TAddPhoneComponent> = ({ currentPhone }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [phoneNumber, setPhoneNumber] = useState('');
  const [phoneNumberError, setPhoneNumberError] = useState('');
  const [code, setCode] = useState('');
  const [codeError, setCodeError] = useState('');
  const [isPhoneCalled, setIsPhoneCalled] = useState(false);
  const [[minute, second], setTime] = useState([1, 30]);
  const [codeType, setCodeType] = useState('');
  const [codeLength, setCodeLength] = useState(0);
  const [userGetProfileData] = useLazyGetUserQuery();
  const [addPhone] = useAddPhoneMutation();
  const [callPhone] = useCallPhoneMutation();
  const [checkPhoneExists] = useLazyCheckPhoneExistsQuery();
  const { action = '' } = useParams<{ action: string }>();

  // код может быть длинной от 4 до 8 символов
  const dynamicText = codeLength === 4 ? `4 последние цифры` : `${codeLength} последних цифр`;

  useEffect(() => {
    if (action === 'change' && currentPhone) {
      const newValue = currentPhone.slice(1);
      setPhoneNumber(newValue);
    } else setPhoneNumber('');
  }, [currentPhone, action]);

  useEffect(() => {
    setTime([1, 30]);
  }, [isPhoneCalled]);

  useEffect(() => {
    const timerID = setInterval(() => tick(), 1000);
    return () => clearInterval(timerID);
  });

  const tick = () => {
    if (minute === 0 && second === 0) {
      setTime([0, 0]);
    } else if (second == 0) {
      setTime([minute - 1, 59]);
    } else {
      setTime([minute, second - 1]);
    }
  };

  const showMessage = (codeType: string) => {
    switch (codeType) {
      case 'number':
        return `На указанный номер поступит бесплатный звонок. Укажите ${dynamicText} входящего номера`;
      case 'voice':
        return 'На указанный номер поступит бесплатный звонок c голосовым сообщением с кодом авторизации';
      case 'sms':
        return 'На указанный номер отправлено СМС сообщение с кодом авторизации';
    }
  };

  const getPhoneNumberError = () => {
    const phoneNumberTest = /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/;
    if (!phoneNumber) return 'Укажите номер телефона';
    if (!phoneNumberTest.test(phoneNumber)) {
      return 'Неверный формат номера телефона';
    }
  };

  const getCodeError = () => {
    if (!code) return 'Укажите код';
  };

  const codeInput = () => {
    setIsPhoneCalled((isPhoneCalled) => !isPhoneCalled);
    setCodeError('');
    setCode('');
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPhoneNumber(event.target.value);
    if (phoneNumberError) setPhoneNumberError('');
  };

  const handleCallPhone = async () => {
    if (getPhoneNumberError()) return;
    try {
      const checkPhoneResponse = await checkPhoneExists(phoneNumber).unwrap();
      if (checkPhoneResponse?.isExist) {
        return setPhoneNumberError('Номер телефона используется другим пользователем');
      }

      const result = await callPhone(phoneNumber).unwrap();

      if (result?.success) {
        setIsPhoneCalled(true);
        setTime([1, 30]);
        setCodeType(result.code_type);
        if (result?.code_length) setCodeLength(result.code_length);
      } else if (result?.message) {
        // message в result появлется в случае ошибки в запросе
        setPhoneNumberError(result.message);
      }
    } catch (e) {
      console.log('handleCallPhone ', e);
    }
  };

  const handleCheckPhoneCode = async () => {
    if (getCodeError()) return;
    try {
      await addPhone({
        code,
        phone_number: '+7' + phoneNumber,
      }).unwrap();

      const profile = await userGetProfileData().unwrap();
      if (profile) {
        const convertedProfile = convertUserProfile(profile);
        dispatch(setUserProfile(convertedProfile));
      }

      navigate('/profile/edit');
    } catch (e) {
      console.log('handleCheckPhoneCode', e);
    }
  };

  return (
    <div className={'wrapper'}>
      <div className={'content'}>
        <div className={styles['change-email']}>
          <CustomTypography
            style={{ marginBottom: 24 }}
            className={clsx('text-24-medium', 'font-golos')}
          >
            {action === 'add' ? 'Добавить телефон' : 'Изменить номер телефона'}
          </CustomTypography>
          {!isPhoneCalled ? (
            <>
              <CustomTypography style={{ marginBottom: 8 }} className={clsx('text-14')}>
                Укажите номер телефона
              </CustomTypography>
              <TextField
                value={phoneNumber}
                onChange={handleChange}
                className={clsx('custom', styles.textfield)}
                onBlur={() => {
                  const phoneNumberErr = getPhoneNumberError();
                  if (phoneNumberErr) setPhoneNumberError(phoneNumberErr);
                }}
                onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                    const phoneNumberErr = getPhoneNumberError();
                    if (phoneNumberErr) setPhoneNumberError(phoneNumberErr);
                    handleCallPhone();
                  }
                }}
                error={!!phoneNumberError}
                helperText={phoneNumberError ? phoneNumberError : ''}
                fullWidth
                InputProps={{
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  inputComponent: NumericFormatCustom as any,
                }}
                variant="standard"
                autoFocus
              />
              <CustomTypography className={clsx('text-14')} color="grey">
                На указаный номер поступит бесплатный звонок
              </CustomTypography>
            </>
          ) : (
            <>
              <CustomTypography style={{ marginBottom: 8 }} className={clsx('text-14')}>
                {showMessage(codeType)}
              </CustomTypography>
              <TextField
                className={clsx('custom', styles.textfield)}
                value={code}
                onChange={(e) => {
                  setCode(e.target.value);
                  if (codeError) setCodeError('');
                }}
                onBlur={() => {
                  const codeErr = getCodeError();
                  if (codeErr) setCodeError(codeErr);
                }}
                onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                    if (isPhoneCalled) {
                      return handleCheckPhoneCode();
                    }
                  }
                }}
                error={!!codeError}
                helperText={codeError ? codeError : ''}
                fullWidth
                variant="standard"
                autoFocus
              />
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                {minute || second !== 0 ? (
                  <CustomTypography className={clsx('text-14')} color="grey">
                    Позвонить повторно через
                    {` ${minute.toString().padStart(2, '0')}:${second.toString().padStart(2, '0')}`}
                  </CustomTypography>
                ) : (
                  <Button
                    variant="custom2"
                    className={clsx('text-14')}
                    style={{ padding: 0 }}
                    onClick={handleCallPhone}
                  >
                    Позвонить повторно
                  </Button>
                )}
                <Button
                  onClick={codeInput}
                  variant="custom2"
                  className={clsx('text-14')}
                  style={{ padding: 0 }}
                >
                  Изменить номер
                </Button>
              </div>
            </>
          )}
          <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 48 }}>
            <Button onClick={() => navigate('/profile/edit')} variant="custom" color="secondary">
              Отмена
            </Button>
            <Button
              variant="custom"
              style={{ marginLeft: 24 }}
              onClick={() => {
                if (isPhoneCalled) {
                  return handleCheckPhoneCode();
                }
                handleCallPhone();
              }}
            >
              {isPhoneCalled ? 'Подтвердить' : 'Продолжить'}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export const AddPhone = connect(mapStateToProps)(AddPhoneComponent);
