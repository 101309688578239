import React, { memo, useEffect, useState } from 'react';
import LicenseCard from './LicenseCard';
import { getLicenses, TLicenseInfo } from './requests';
import { ModalLicense } from './ModalLicense';
import { SettingsItem } from 'src/components/settings/SettingsItem';

interface ILicenseComponentProps {
  updateLicense: boolean;
}

const LicenseComponent = ({
  updateLicense
}: ILicenseComponentProps) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [licenses, setLicenses] = useState<TLicenseInfo[]>([]);

  useEffect(() => {
    loadLicenses();
  }, [updateLicense]);

  const loadLicenses = async () => {
    const licenseList = await getLicenses();
    setLicenses(licenseList);
  };

  return (
    <>
      <SettingsItem title="Лицензия" onClick={() => setIsModalOpen(true)}>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '8px', width: '100%' }}>
          {licenses.map((license) => (
            <LicenseCard
              key={license.value}
              licenseName={license.value}
              licenseId={license.id}
              expirationTime={license.expiration_time}
              licenseKeys={license.flags}
              connectedProvidersList={license.providers}
              updateLicenses={loadLicenses}
            />
          ))}
        </div>
      </SettingsItem>

      <ModalLicense
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        loadLicenses={loadLicenses}
      />
    </>
  );
};

export const License = memo(LicenseComponent);
