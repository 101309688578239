import { yupResolver } from '@hookform/resolvers/yup';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { debounce, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import OutlinedInput from '@mui/material/OutlinedInput';
import clsx from 'clsx';
import React, { Dispatch, FC, FocusEvent, KeyboardEvent, SetStateAction, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { CustomTypography } from '../custom/CustomTypography';
import styles from './ScopeChips.module.css';
import { TChips } from './createPanel/CreateProvider';
import { EditProviderInputs } from './editPanel/EditProvider';

const schema = yup.object({
  scopes: yup.string().max(255, 'Разрешения не могут превышать 255 символов'),
});

type TScopeChipsProps = {
  chips: Array<TChips>;
  setChips: Dispatch<SetStateAction<Array<TChips>>>;
};

export const ScopeChips: FC<TScopeChipsProps> = ({ chips, setChips }) => {
  const [chipMap, setChipMap] = useState(new Map());
  const {
    register,
    setValue,
    formState: { errors },
    getValues,
  } = useForm<EditProviderInputs>({
    resolver: yupResolver(schema),
    mode: 'all',
  });
  const hasError = errors.scopes || !!chipMap.size;
  const theme = useTheme();

  const handleChipChange = (e: React.ChangeEvent<HTMLInputElement>, key: string) => {
    if (e.target.value.length > 255) {
      setChipMap((prev) => {
        const copy = new Map(prev);
        copy.set(key, e.target.value);
        return copy;
      });
    } else {
      setChipMap((prev) => {
        const copy = new Map(prev);
        copy.delete(key);
        return copy;
      });
    }
  };
  const handleChipChangeDebounce = debounce(handleChipChange, 300);

  return (
    <>
      <Box
        className={clsx(styles['chips-wrapper'], {
          [styles['chips-input']]: !hasError,
          [styles['chips-input-error']]: hasError,
        })}
        sx={
          hasError
            ? {
                borderColor: theme.palette.custom.error,
              }
            : {
                color: theme.palette.custom.secondary,
                '&:hover': {
                  borderColor: theme.palette.custom.mainGrey,
                },
              }
        }
        tabIndex={0}
      >
        {chips.map((chip) =>
          chip.changing ? (
            <input
              key={chip.key}
              style={{ width: 80, border: 'none', outline: 'none', margin: '0 10px' }}
              autoFocus
              defaultValue={chip.value}
              tabIndex={-1}
              onChange={(e) => {
                handleChipChangeDebounce(e, chip.key);
              }}
              onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
                if (e.key === 'Enter' || e.key === ' ') e.preventDefault();
                if (
                  (e.key === 'Enter' || e.key === ' ') &&
                  (e.target as HTMLInputElement).value.length <= 255
                )
                  setChips((chips) => {
                    if (!(e.target as HTMLInputElement).value.trim())
                      setChips((chips) => chips.filter((chipValue) => chip.key !== chipValue.key));
                    const index = chips.findIndex((elem) => chip.key === elem.key);
                    const arrCopy = chips.slice();
                    arrCopy[index].changing = false;
                    arrCopy[index].value = (e.target as HTMLInputElement).value;
                    return arrCopy;
                  });
              }}
              onBlur={(e: FocusEvent<HTMLInputElement>) => {
                if (e.target.value.length <= 255)
                  setChips((chips) => {
                    if (!e.target.value.trim())
                      setChips((chips) => chips.filter((chipValue) => chip.key !== chipValue.key));
                    const index = chips.findIndex((elem) => chip.key === elem.key);
                    const arrCopy = chips.slice();
                    arrCopy[index].changing = false;
                    arrCopy[index].value = e.target.value;
                    return arrCopy;
                  });
              }}
            />
          ) : (
            <Chip
              sx={{
                '& svg:hover path': {
                  stroke: theme.palette.custom.main,
                },
              }}
              className={clsx('text-14', styles.chip)}
              deleteIcon={<CloseOutlinedIcon />}
              onClick={() => {
                setChips((chips) => {
                  const index = chips.findIndex((elem) => chip.key === elem.key);
                  const arrCopy = chips.slice();
                  arrCopy[index].changing = true;
                  return arrCopy;
                });
              }}
              key={chip.key}
              label={chip.value.length > 20 ? `${chip.value.slice(0, 20)}...` : chip.value}
              variant="outlined"
              onDelete={() => {
                setChips((chips) => chips.filter((chipValue) => chip.key !== chipValue.key));
              }}
            />
          ),
        )}
        <OutlinedInput
          onKeyDown={(e) => {
            const scopeValue = getValues('scopes');
            if (e.key === 'Enter' || e.key === ' ') e.preventDefault();
            if (
              (e.key === 'Enter' || e.key === ' ') &&
              scopeValue.trim() &&
              scopeValue.length <= 255
            ) {
              setChips((chips) => [
                ...chips,
                { key: String(new Date().getTime()), value: scopeValue.trim() },
              ]);
              setValue('scopes', '');
            }
            if (e.key === 'Backspace' && !scopeValue) {
              setChips((chips) => chips.slice(0, chips.length - 1));
              if (chipMap.size !== 0) {
                setChipMap((prev) => {
                  const copy = new Map(prev);
                  const key = chips[chips.length - 1].key;
                  copy.delete(key);
                  return copy;
                });
              }
            }
          }}
          {...register('scopes', {
            onBlur: () => {
              const scopeValue = getValues('scopes');
              if (scopeValue.trim() && scopeValue.length <= 255) {
                setChips((chips) => [
                  ...chips,
                  { key: String(new Date().getTime()), value: scopeValue.trim() },
                ]);
                setValue('scopes', '');
              }
            },
          })}
          className={styles['scopes-input']}
        />
      </Box>
      {(errors.scopes || chipMap.size !== 0) && (
        <CustomTypography color="error" className={clsx('text-14', styles['scopes-input-error'])}>
          Разрешения не могут превышать 255 символов
        </CustomTypography>
      )}
    </>
  );
};
