import React, { FC } from 'react';
import { TEsiaParams, TMiscProvider, TOauthProvider } from 'src/redux/services/provider';
import { addMethodsType } from '../Settings';
import { EditEsiaProvider } from './edit/EditEsiaProvider';
import { EditQRCodeProvider } from './edit/EditQRCodeProvider';

type TEditMethodsProps = {
  provider: TOauthProvider | TMiscProvider | null;
  onClose: () => void;
};

export const EditMethods: FC<TEditMethodsProps> = ({ provider, onClose }) => {
  return (
    <>
      <EditEsiaProvider
        providerToEdit={
          provider as TMiscProvider & {
            params: TEsiaParams;
            provider_id: string;
            avatar?: File | null;
          }
        }
        isOpen={provider?.type === addMethodsType.ESIA}
        onClose={onClose}
      />
      <EditQRCodeProvider
        providerToEdit={provider as TMiscProvider}
        isOpen={provider?.type === addMethodsType.QRCODE}
        onClose={onClose}
      />
    </>
  );
};
