import React from 'react';
import { EditMethods } from './trustedProviders/EditMethods';
import { TMiscProvider, TOauthProvider } from 'src/redux/services/provider';

interface IAddEditMethodsProps {
  provider: TOauthProvider | TMiscProvider | null;
  onClose: () => void;
}

export const AddEditMethods: React.FC<IAddEditMethodsProps> = ({
  provider,
  onClose,
}) => {
  return (
    <>
      <EditMethods onClose={onClose} provider={provider} />
    </>
  );
};