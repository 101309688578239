import Box from '@mui/material/Box';
import React, { FC, useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom-v5-compat';
import { cardHeight, screenDevices } from 'src/enums';
import { useResizeObserver } from 'src/hooks/useResizeObserver';
import { RootState } from 'src/redux/rootReducer';
import { useGetClientInfoQuery, useLazyGetUsersClientQuery } from 'src/redux/services/clients';
import { TUserWithRole } from 'src/redux/services/users';
import { IQueryPropsWithClientId } from 'src/redux/types';
import { ConfirmationModal, IConfirmationModalProps } from '../modal/ConfirmationModal';
import { ListItems } from '../shared/listElements';
import { IUserCardProps, UserCard } from '../users/UserCard';
import styles from './ClientDetails.module.css';
import { ClientDetailsAddInfo } from './ClientDetailsAddInfo';
import { ClientDetailsHeader } from './ClientDetailsHeader';

const mapStateToProps = (state: RootState) => ({
  screenSize: state.app.screenSize,
});

interface IClientDetailsProps {
  screenSize: number;
}

const ClientDetailsComponent: FC<IClientDetailsProps> = ({ screenSize }) => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { ref, width } = useResizeObserver();
  const { data: client } = useGetClientInfoQuery({ id: id ?? '' });
  const [getUsers, { data: usersData }] = useLazyGetUsersClientQuery();

  const [modalProps, setModalProps] = useState<IConfirmationModalProps>({
    isOpen: false,
    onAction: () => {},
    onClose: () => {},
    title: '',
    actionButtonText: '',
    mainMessage: [],
  });
  const query = (offset: number, search?: string): IQueryPropsWithClientId => {
    return {
      query: {
        limit: '10',
        offset,
        search: search || '',
      },
      clientId: id ?? '',
    };
  };

  const getCardHeight = useCallback(
    (screenSize: number) => {
      switch (screenSize) {
        case screenDevices.Desktop:
          return cardHeight.Desktop;
        case screenDevices.Tablet:
          return 160;
        case screenDevices.miniTablet:
          return 190;
        case screenDevices.Mobile:
          return 210;
        default:
          return 170;
      }
    },
    [screenDevices, cardHeight],
  );

  const height = getCardHeight(width);
  const handleEventClick = (userId?: number) => {
    userId && navigate(`/applications/user/${id}/${userId}`);
  };

  return (
    <Box data-id="clientDetails" className="wrapper">
      <Box className="content content_maxWidth">
        {client?.client_id ? (
          <div className={styles.client}>
            <Box className={styles.mainInfo}>
              <ClientDetailsHeader client={client} description={client?.description} />
            </Box>
            <Box className={styles.addInfo}>
              <ClientDetailsAddInfo client={client} users={usersData?.items} />
            </Box>
            <Box className={styles.usersList} ref={ref}>
              <ListItems<TUserWithRole, IQueryPropsWithClientId, IUserCardProps>
                heightListItem={height}
                padding={cardHeight.Indent}
                query={query}
                getItems={getUsers}
                RowElement={UserCard}
                isFullHeight={screenSize <= screenDevices.Tablet}
                rowElementProps={{
                  height: height,
                  padding: cardHeight.Indent,
                  client: client,
                  onClick: handleEventClick,
                  setConfirmModalProps: setModalProps,
                }}
              />
              <ConfirmationModal
                isOpen={modalProps.isOpen}
                onAction={modalProps.onAction}
                onClose={modalProps.onClose}
                title={modalProps.title}
                actionButtonText={modalProps.actionButtonText}
                mainMessage={modalProps.mainMessage}
              />
            </Box>
          </div>
        ) : (
          <div>Приложение не найдено</div>
        )}
      </Box>
    </Box>
  );
};

export const ClientDetails = connect(mapStateToProps)(ClientDetailsComponent);
