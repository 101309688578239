import clsx from 'clsx';
import React, { FC, useEffect, useRef, useState } from 'react';
import styles from './EditProviders.module.css';
import { ReactComponent as IdIcon } from '../../../icons/Id.svg';
import {
  EGetProviderAction,
  ProviderType,
  TMiscProvider,
  TOauthProvider,
  useActivateProvidersMutation,
  useDeactivateProvidersMutation,
  useDeleteProviderMutation,
  useGetProvidersQuery,
} from '../../../redux/services/provider';
import { ChooseProvider } from '../ChooseProvider';

import { Avatar } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom-v5-compat';
import {
  clientsApi,
  useGetClientInfoQuery,
  useUpdateClientMutation,
} from 'src/redux/services/clients';
import { CLIENT_ID } from '../../../constants';
import { getImageURL, getProviderTitleByType } from '../../../helpers';
import { RootState } from '../../../redux/rootReducer';
import { CustomCheckbox } from '../../custom/CustomCheckbox';
import { CustomTypography } from '../../custom/CustomTypography';
import { ConfirmationModal } from '../../modal/ConfirmationModal';
import { IconWithTooltip } from '../../shared/IconWithTooltip';
import { CreateSidePanel } from '../../sidePanel/CreateSidePanel';
import { EditEthereumProvider } from '../editPanel/EditEthereumProvider';
import { EditKloudProvider } from '../editPanel/EditKloudProvider';
import { EditProvider } from '../editPanel/EditProvider';
import { EditSmsProvider } from '../editPanel/EditSmsProvider';
import { addMethodsType, addProvidersTypes } from '../../extensions/Settings';
import { EmptyProviders } from './EmptyList';
import { Filter } from './Filter';
import { AddEditMethods } from 'src/components/extensions/AddEditMethods';
import { setNotice } from 'src/redux/noticesSlice';

type TEditProviders = {
  isOpen: boolean;
  onClose: () => void;
};

export const EditProviders: FC<TEditProviders> = ({ isOpen, onClose }) => {
  const { clientId = '' } = useParams<{ clientId: string }>();

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [providerToEdit, setProviderToEdit] = useState<TOauthProvider | TMiscProvider | null>(null);
  const [fetchingProviderIds, setFetchingProviderIds] = useState<string[]>([]);
  const [isCreateFormOpen, setIsCreateFormOpen] = useState(false);
  const [providerToDelete, setProviderToDelete] = useState<TOauthProvider | TMiscProvider | null>(
    null,
  );
  const [providers, setProviders] = useState<(TOauthProvider | TMiscProvider)[]>([]);
  const [requiredProvidersIds, setRequiredProvidersIds] = useState<string[]>([]);

  const userId = useSelector((state: RootState) => state.user.userProfile.id);
  const { data: client, isFetching: getApplicationFetching } = useGetClientInfoQuery(
    { id: clientId },
    {
      skip: !userId || !clientId,
    },
  );
  const anchorPopover = useRef<HTMLButtonElement>(null);
  const { data: appProviders, isFetching: providersFetching } = useGetProvidersQuery({
    client_id: clientId,
    action: EGetProviderAction.all,
  });

  const dispatch = useDispatch();
  const [deleteProvider] = useDeleteProviderMutation();
  const [activateProvider] = useActivateProvidersMutation();
  const [deactivateProvider] = useDeactivateProvidersMutation();
  const [updateClient, { isLoading: updateClientLoading }] = useUpdateClientMutation();

  const editProviderTypes: string[] = [
    ProviderType.GOOGLE,
    ProviderType.YANDEX,
    ProviderType.VK,
    ProviderType.MAILRU,
    ProviderType.CUSTOM,
    ProviderType.OAUTH,
    ProviderType.KLOUD,
    ProviderType.SMS,
    ProviderType.ETHEREUM,
    ...Object.values(addMethodsType),
  ];

  const onlyRemoveProviderTypes: string[] = [
    ProviderType.KLOUD,
    ProviderType.SMS,
    ProviderType.ETHEREUM,
  ];

  useEffect(() => {
    if (client) {
      setRequiredProvidersIds(client.required_providers_ids);
    }
  }, [client]);

  useEffect(() => {
    !!appProviders?.length && setProviders(appProviders);
  }, [appProviders]);

  const closeDeleteModal = () => {
    setDeleteModalOpen(false);
    setProviderToDelete(null);
  };

  const handleDeleteProvider = async () => {
    if (providerToDelete) {
      setFetchingProviderIds((ids) => [...ids, providerToDelete.id]);

      await deleteProvider({
        client_id: clientId,
        provider_id: providerToDelete.id,
      });
      setProviderToDelete(null);
      setDeleteModalOpen(false);

      setFetchingProviderIds(fetchingProviderIds.filter((id) => id !== providerToDelete.id));
    }
  };

  const handleCopyProvider = async (provider: TOauthProvider | TMiscProvider) => {
    await navigator.clipboard.writeText(JSON.stringify(provider));
  };

  const handleActivateProvider = async (provider: TOauthProvider | TMiscProvider) => {
    if (!userId) return;
    setFetchingProviderIds((ids) => [...ids, provider.id]);

    if (!provider.is_active) {
      await activateProvider({
        body: {
          providers: [parseInt(provider.id, 10)],
        },
        client_id: clientId,
      });
    } else {
      await deactivateProvider({
        body: {
          providers: [parseInt(provider.id, 10)],
        },
        client_id: clientId,
      });
    }

    dispatch(
      clientsApi.endpoints.getClientInfo.initiate(
        { id: clientId },
        {
          subscribe: false,
          forceRefetch: true,
        },
      ),
    );

    setFetchingProviderIds(fetchingProviderIds.filter((id) => id !== provider.id));
  };

  const handleChangeRequiredProviders = async (
    provider: TOauthProvider | TMiscProvider,
    isRequired: boolean,
  ) => {
    if (!userId || updateClientLoading || getApplicationFetching || !client) return;

    //TODO исправить тип id на string
    const updatedProvidersIds = isRequired
      ? requiredProvidersIds.filter((id) => id !== provider.id.toString())
      : [...requiredProvidersIds, provider.id.toString()];

    try {
      await updateClient({
        id: client.client_id,
        data: {
          required_providers_ids: updatedProvidersIds,
        },
      }).unwrap();
      setRequiredProvidersIds(updatedProvidersIds);
    } catch (error) {
      dispatch(
        setNotice({
          id: Math.random(),
          isRead: false,
          message: 'Ошибка при обновлении провайдера',
          timestamp: new Date().toString(),
          avatar: '',
        }),
      );
      console.error(error);
    }

    dispatch(
      clientsApi.endpoints.getClientInfo.initiate(
        { id: client.client_id },
        {
          subscribe: false,
          forceRefetch: true,
        },
      ),
    );
  };

  return (
    <>
      <CreateSidePanel
        handleClosePanel={onClose}
        isOpenPanel={isOpen}
        title={'Настроить способы входа'}
        createAction={() => setIsCreateFormOpen(true)}
        isNoBackdrop={false}
      >
        <div className={styles.wrapper}>
          <Filter
            anchorPopover={anchorPopover}
            items={appProviders}
            clientId={clientId}
            updateProviders={(value: (TOauthProvider | TMiscProvider)[]) => setProviders(value)}
          />
          <div className={styles.content}>
            {providers.length === 0 ? (
              <EmptyProviders title="Способов входа" onClick={() => setIsCreateFormOpen(true)} />
            ) : (
              providers
                .slice()
                .sort((a, b) => {
                  if (a.is_active !== b.is_active) return !a.is_active ? 1 : -1;
                  return a.client_id === CLIENT_ID ? 1 : -1;
                })
                .map((provider) => {
                  const isRequired = requiredProvidersIds.includes(provider.id.toString());

                  return (
                    <div
                      className={clsx(styles.provider, {
                        [styles['checked-provider']]: provider.is_active,
                      })}
                      key={provider.id}
                    >
                      <CustomCheckbox
                        onClick={() => {
                          handleActivateProvider(provider);
                        }}
                        checked={provider.is_active}
                        className={styles['provider-checkbox']}
                        disabled={
                          !!fetchingProviderIds.find((id) => id === provider.id) ||
                          (clientId === CLIENT_ID && provider.type === ProviderType.CREDENTIALS)
                        }
                      />
                      <Avatar
                        src={getImageURL(provider.avatar)}
                        className={styles['provider-icon']}
                      >
                        {!provider.avatar && <IdIcon />}
                      </Avatar>
                      <div className={styles['provider-name-wrapper']}>
                        <CustomTypography className={clsx('text-14', styles['provider-name'])}>
                          {provider.name}
                        </CustomTypography>
                        <CustomTypography className={clsx('text-12')} color="grey">
                          {getProviderTitleByType(provider.type, addProvidersTypes)}
                        </CustomTypography>
                      </div>
                      {editProviderTypes.includes(provider.type) && (
                        <div className={styles['provider-buttons']}>
                          {!onlyRemoveProviderTypes.includes(provider.type) &&
                            getProviderTitleByType(provider.type, addProvidersTypes) ===
                              ProviderType.OAUTH && (
                              <IconWithTooltip
                                iconType={isRequired ? 'starFilled' : 'star'}
                                onClick={() => handleChangeRequiredProviders(provider, isRequired)}
                                description={`Для входа в приложение ${
                                  !isRequired && 'не'
                                } требуется  наличие способа входа в профиле пользователя`}
                              />
                            )}
                          <IconWithTooltip
                            dataAttribute={'delete-provider-button'}
                            iconType="delete"
                            onClick={() => {
                              setProviderToDelete(provider);
                              setDeleteModalOpen(true);
                            }}
                            disabled={
                              !!fetchingProviderIds.find((id) => id === provider.id) ||
                              providersFetching
                            }
                          />
                          {!onlyRemoveProviderTypes.includes(provider.type) && (
                            <>
                              <IconWithTooltip
                                dataAttribute={'edit-provider-button'}
                                iconType="edit"
                                onClick={() => setProviderToEdit(provider)}
                              />
                              <IconWithTooltip
                                dataAttribute={'copy-provider-button'}
                                iconType="copy"
                                onClick={() => handleCopyProvider(provider)}
                              />
                            </>
                          )}
                        </div>
                      )}
                    </div>
                  );
                })
            )}
          </div>
        </div>
      </CreateSidePanel>
      <ChooseProvider isOpen={isCreateFormOpen} onClose={() => setIsCreateFormOpen(false)} />
      <EditEthereumProvider
        providerToEdit={providerToEdit as TMiscProvider}
        isOpen={providerToEdit?.type === ProviderType.ETHEREUM}
        onClose={() => {
          setProviderToEdit(null);
        }}
      />
      <EditSmsProvider
        providerToEdit={providerToEdit as TMiscProvider}
        isOpen={providerToEdit?.type === ProviderType.SMS}
        onClose={() => {
          setProviderToEdit(null);
        }}
      />
      <EditKloudProvider
        providerToEdit={providerToEdit as TMiscProvider}
        isOpen={providerToEdit?.type === ProviderType.KLOUD}
        onClose={() => {
          setProviderToEdit(null);
        }}
      />
      <EditProvider
        providerToEdit={providerToEdit as TOauthProvider}
        isOpen={
          providerToEdit !== null &&
          [
            ProviderType.OAUTH,
            ProviderType.YANDEX,
            ProviderType.VK,
            ProviderType.MAILRU,
            ProviderType.GOOGLE,
            ProviderType.CUSTOM,
          ].includes(providerToEdit.type as ProviderType)
        }
        onClose={() => {
          setProviderToEdit(null);
        }}
      />
      <AddEditMethods
        provider={providerToEdit}
        onClose={() => {
          setProviderToEdit(null);
        }}
      />

      <ConfirmationModal
        isOpen={deleteModalOpen}
        onClose={closeDeleteModal}
        onAction={handleDeleteProvider}
        disabled={!!fetchingProviderIds.find((id) => id === providerToDelete?.id)}
        title="Удалить способ входа"
        mainMessage={[
          'Способ входа будет удален. Если способ входа используется в приложении, пользователи не смогут войти в него, используя данный способ входа.',
        ]}
      />
    </>
  );
};
