import { BACKEND_URL } from 'src/constants';
import { getAccessToken } from 'src/service/auth';

export enum ProviderScope {
  login = 'login',
  trusted = 'trusted',
  otp = 'otp',
  internal = 'internal',
}

export interface TLicenseInfo {
  id: string;
  value: string;
  expiration_time: number;
  providers: { name: string; id: string }[];
  flags: string[];
}

/**
 * Получает список лицензий с сервера.
 * @param {QueryParams} [params] - Объект с дополнительными параметрами запроса (например, фильтрация по флагу).
 * @returns {Promise<TLicenseInfo[]>} - массив лицензий.
 */
export const getLicenses = async (flag?: string): Promise<TLicenseInfo[]> => {
  try {
    const accessToken = await getAccessToken();
    const query = new URLSearchParams();

    // Добавляем параметр flag только если он передан
    if (flag) {
      query.append('flag', flag);
    }

    const response = await fetch(
      `${BACKEND_URL}/api/v1/licenses${flag ? '?' + query.toString() : ''}`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    );

    if (!response.ok) {
      throw new Error('Failed to get licenses');
    }

    return response.json();
  } catch (error) {
    console.log('getLicenses error: ', error);
    throw error;
  }
};

/**
 * Активирует лицензию на сервере.
 * @param {string} value - Уникальное значение лицензии.
 * @param {string} description - Описание лицензии или ключей.
 */
export const activateLicense = async (value: string, description: string): Promise<{id : number}> => {
  try {
    const accessToken = await getAccessToken();

    const response = await fetch(`${BACKEND_URL}/api/v1/licenses`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ value, description }),
    });

    if (!response.ok) {
      const errorResponse = await response.json();
      throw new Error(errorResponse.message || 'Failed to activate license');
    }

    return response.json();
  } catch (error) {
    console.log('activateLicense error: ', error);
    throw error;
  }
};

/**
 * Удаляет лицензию на сервере.
 * @param {string} id - Идентификатор лицензии для удаления.
 */
export const deleteLicense = async (id: string): Promise<boolean> => {
  try {
    const accessToken = await getAccessToken();

    const response = await fetch(`${BACKEND_URL}/api/v1/licenses/${id}`, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (!response.ok) {
      const errorResponse = await response.json();
      throw new Error(errorResponse.message || 'Failed to delete license');
    }

    return true;
  } catch (error) {
    console.log('deleteLicense error: ', error);
    throw error;
  }
};
