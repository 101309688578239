import { yupResolver } from '@hookform/resolvers/yup';
import { FC, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';
import {
  TCreateProvider,
  useCreateProviderMutation,
} from '../../../../redux/services/provider';
import styles from './CreateProvider.module.css';

import { useParams } from 'react-router-dom-v5-compat';
import { isObjectEmpty } from '../../../../helpers';
import { ConfirmationModal } from '../../../modal/ConfirmationModal';
import { ModalCloseOnly } from '../../../modal/ModalCloseOnly';
import { ProviderHeader } from '../../../Providers/ProviderHeader';
import { SharedFormProvider } from '../../../shared/FormProvider';
import { ProviderSettingsSidePanel } from '../../../sidePanel/ProviderSettingsSidePanel';
import { ProviderFooter } from '../TrustedProviderFooter';
import { addMethodsType } from '../../Settings';

export type CreateQRCodeProviderInputs = {
  name: string;
  description: string;
  avatar: File | null;
  path_to_avatar: string;
};

const schema = yup.object({
  name: yup
    .string()
    .required('Обязательное поле')
    .max(50, 'Название не может превышать 50 символов')
    .matches(/[^ ]+/, {
      message: 'Название не может состоять только из пробелов',
    })
    .matches(/^[^ ]+( *[^ ]+)*?$/, 'Название не может содержать пробелы в начале и конце'),
  description: yup
    .string()
    .max(255, 'Описание не может превышать 255 символов')
    .matches(/^$|[^ ]+/, {
      message: 'Описание не может состоять только из пробелов',
    }),
});

export const CreateQRCodeProvider: FC<TCreateProvider> = ({ isOpen, onClose, pathToAvatar }) => {
  const methods = useForm<CreateQRCodeProviderInputs>({
    resolver: yupResolver(schema),
    defaultValues: {
      description: '',
      path_to_avatar: pathToAvatar,
    },
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });

  const {
    handleSubmit,
    watch,
    setValue,
    formState: { dirtyFields },
    setError,
    clearErrors,
    reset,
  } = methods;

  const [saveModalOpen, setSaveModalOpen] = useState(false);
  const [clipboardModalOpen, setClipboardModalOpen] = useState(false);
  const { clientId = '' } = useParams<{ clientId: string }>();
  const [overrideImage, setOverrideImage] = useState<File | string | null>(null);
  const watchDescription = watch('description');
  const [createProvider, createResult] = useCreateProviderMutation();

  useEffect(() => {
    if (createResult.isSuccess) onClose(true);
  }, [createResult]);

  useEffect(() => {
    return () => {
      reset();
      setOverrideImage(null);
    };
  }, [isOpen]);

  const closeSaveModal = () => setSaveModalOpen(false);
  const closeClipboardModal = () => setClipboardModalOpen(false);
  const setAvatarValue = (value: File | null) => setValue('avatar', value, { shouldDirty: true });
  const setAvatarLink = (value: string) => {
    setValue('path_to_avatar', value, { shouldDirty: true });
  };
  const setAvatarError = (error: string) => setError('avatar', { message: error });
  const clearAvatarError = () => clearErrors('avatar');

  const handleClose = () => {
    if (isObjectEmpty(dirtyFields)) onClose();
    else setSaveModalOpen(true);
  };

  const setFields = async () => {
    try {
      const text = await navigator.clipboard.readText();
      const provider: Partial<
        Omit<CreateQRCodeProviderInputs, 'avatar'> & {
          id: string;
          isPublic: boolean;
          client_id: string;
          avatar: string;
          type: addMethodsType.QRCODE;
        }
      > = JSON.parse(text);
      const { type, avatar, ...restInputs } = provider || {};
      delete restInputs.id;
      delete restInputs.client_id;
      if (type !== addMethodsType.QRCODE) {
        setClipboardModalOpen(true);
      } else {
        if (avatar) {
          setOverrideImage(avatar);
          setValue('avatar', null);
          setValue('path_to_avatar', avatar, { shouldDirty: !provider });
        }
        if (restInputs) {
          (
            Object.keys(restInputs) as Array<keyof Omit<CreateQRCodeProviderInputs, 'avatar'>>
          ).forEach((field) => {
            setValue(field, restInputs?.[field] || '', { shouldDirty: true });
          });
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const onSubmit: SubmitHandler<CreateQRCodeProviderInputs> = (data) => {
    const { avatar, ...rest } = data;

    createProvider({
      body: {
        type: addMethodsType.QRCODE,
        ...rest,
        avatar: avatar ? avatar : null,
      },
      client_id: clientId,
    });
  };

  return (
    <ProviderSettingsSidePanel
      handleClosePanel={handleClose}
      isOpenPanel={isOpen}
      title="Создать способ входа QR-код"
      setPasteFields={() => {
        setFields();
      }}
      isNoBackdrop
    >
      <SharedFormProvider<CreateQRCodeProviderInputs>
        methods={methods}
        onSubmit={handleSubmit(onSubmit)}
        handleCancel={handleClose}
        isDisabled={createResult.isLoading}
        acceptTitle="Создать"
      >
        <div className={styles['create-provider-form']}>
          <ProviderHeader
            watchDescription={watchDescription}
            overrideImage={overrideImage}
            setAvatarError={setAvatarError}
            clearAvatarError={clearAvatarError}
            setAvatarValue={setAvatarValue}
            setAvatarLink={setAvatarLink}
            pathToAvatar={pathToAvatar}
          />
          <ProviderFooter type={addMethodsType.QRCODE} clientId={clientId} />
        </div>
      </SharedFormProvider>

      <ConfirmationModal
        title="Сохранение изменений"
        mainMessage={['Изменения не сохранены. Продолжить без сохранения?']}
        actionButtonText="Продолжить"
        isOpen={saveModalOpen}
        onAction={() => {
          onClose();
          setSaveModalOpen(false);
        }}
        onClose={closeSaveModal}
      />

      <ModalCloseOnly
        isOpen={clipboardModalOpen}
        onCloseAction={closeClipboardModal}
        title="Вставить настройки"
        message="Скопированные настройки не подходят для QR-код провайдера."
      />
    </ProviderSettingsSidePanel>
  );
};
