import React, { FC, ReactNode } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, IconButton, Button } from '@mui/material';
import clsx from 'clsx';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import { CustomMuiIcon } from '../custom/CustomMuiIcon';
import { CustomTypography } from '../custom/CustomTypography';
import styles from './Settings.module.css';

export interface ISettingsItemProps {
  title: string;
  onClick?: () => void;
  children?: ReactNode;
}

const SettingsItemComponent: FC<ISettingsItemProps> = ({ title, onClick, children }) => {
  return (
    <Accordion className={clsx(styles.panel, styles.accordion)}>
      <AccordionSummary
        className={styles['accorion-summary']}
        classes={{ content: styles['accorion-summary-content'] }}
        expandIcon={
          <IconButton size="large" disableRipple={false}>
            <CustomMuiIcon Icon={KeyboardArrowDownOutlinedIcon} />
          </IconButton>
        }
      >
        <CustomTypography className={clsx('font-golos', 'text-17-regular')}>
          {title}
        </CustomTypography>
      </AccordionSummary>
      <AccordionDetails
        style={{ alignItems: 'flex-start' }}
        className={styles['accordion-details']}
      >
        {onClick && (
          <Button variant="custom2" className={styles['add-button']} onClick={() => onClick()}>
            Настроить
          </Button>
        )}
        {children}
      </AccordionDetails>
    </Accordion>
  );
};

export const SettingsItem = SettingsItemComponent;
