import React from 'react';
import { ThemeColors } from './Theme';
import { useTheme } from '@mui/material/styles';

type TFieldsToCusom = 'fill' | 'ellipse' | 'stroke';

export const CustomIcon = ({
  Icon,
  color,
  ...props
}: React.SVGProps<SVGSVGElement> & {
  title?: string;
} & {
  Icon: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string;
    }
  >;
  color?: ThemeColors;
  fieldsToCustom?: TFieldsToCusom[];
}) => {
  const theme = useTheme();

  const getColor = () => {
    if (color === 'grey') return theme.palette.custom.mainGrey;
    if (color === 'error') return theme.palette.custom.error;
    if (color === 'link') return theme.palette.custom.link;
    return theme.palette.custom.main;
  };

  return <Icon {...props} stroke={getColor()} />;
};
