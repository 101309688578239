import clsx from 'clsx';
import React, { FC } from 'react';
import styles from './Modal.module.css';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';
import { ReactComponent as CloseIcon } from '../../icons/Close.svg';
import { CustomTypography } from '../custom/CustomTypography';

interface IModalCloseOnly {
  isOpen: boolean;
  onCloseAction: () => void;
  title: string;
  message: string;
}

export const ModalCloseOnly: FC<IModalCloseOnly> = ({ isOpen, onCloseAction, title, message }) => {
  //TODO Заменить на общий компонент ConfirmModal
  
  return (
    <Modal open={isOpen} onClose={onCloseAction}>
      <div className={styles['modal-container']}>
        <div style={{ display: 'flex' }}>
          <CustomTypography className={clsx('header-2-medium', 'font-golos')}>
            {title}
          </CustomTypography>
          <IconButton onClick={onCloseAction} style={{ marginLeft: 'auto', marginBottom: 16 }}>
            <CloseIcon />
          </IconButton>
        </div>
        <CustomTypography style={{ marginBottom: 32 }} className={clsx('text-14')}>
          {message}
        </CustomTypography>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            onClick={() => {
              onCloseAction();
            }}
            variant="custom"
            style={{ marginLeft: 24 }}
          >
            Закрыть
          </Button>
        </div>
      </div>
    </Modal>
  );
};
