import Popover from '@mui/material/Popover';
import clsx from 'clsx';
import React, { FC, MouseEvent, ReactElement, ReactNode } from 'react';
import styles from './ExternalAccountActionPopover.module.css';
import { ReactComponent as ActionsIcon } from '../../icons/Actions.svg';
import IconButton from '@mui/material/IconButton';

export const ExternalAccountActionPopover: FC<{
  anchorEl: HTMLButtonElement | null;
  setAnchorEl: React.Dispatch<React.SetStateAction<HTMLButtonElement | null>>;
  children: ReactNode;
}> = ({ children, anchorEl, setAnchorEl }) => {
  const handleOpenPopover = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = (event: MouseEvent<ReactElement>) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        onClick={(event) => handleOpenPopover(event)}
        className={clsx(styles['actions-icon'], {
          [styles['active-actions-icon']]: !!anchorEl,
        })}
      >
        <ActionsIcon />
      </IconButton>
      <Popover
        classes={{
          paper: clsx(styles.paper),
        }}
        onClose={handleClosePopover}
        anchorEl={anchorEl}
        open={!!anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {children}
      </Popover>
    </>
  );
};
