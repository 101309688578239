import React, { useEffect, useState } from 'react';
import { CustomTypography } from '../../custom/CustomTypography';
import { Button, MenuItem } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { CustomSelect } from '../../custom/CustomSelect';
import styles from './LicenseSelect.module.css';
import clsx from 'clsx';
import { getLicenses, TLicenseInfo } from './requests';
import { ModalLicense } from './ModalLicense';

interface TLicenseSelect {
  flag: string;
  currentLicense?: number;
}

export const LicenseSelect = ({ flag, currentLicense }: TLicenseSelect) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [licenses, setLicenses] = useState<TLicenseInfo[]>([]);
  const { control, setValue } = useFormContext();

  useEffect(() => {
    loadLicenses();
    if (!currentLicense) {
      setValue("license_id", "(нет лицензии)",);
    }
  }, []);  

  const loadLicenses = async (id?: number) => {
    const licenseList = await getLicenses(flag);
    setLicenses(licenseList);
    if (id) {
      setValue("license_id", id, { shouldDirty: true});
    }
  };


  return (
    <div>
      <div className={clsx(styles.selectTitleWrapper)}>
        <CustomTypography className='text-14'>Лицензия</CustomTypography>
        <Button variant="custom2" onClick={() => setIsModalOpen(true)}>
          Добавить
        </Button>
      </div>
      <Controller
        control={control}
        name="license_id"
        render={({ field }) => (
          <CustomSelect
            data-id="license-select"
            defaultValue={currentLicense}
            onChange={(e) => {
              field.onChange(e.target.value === '(нет лицензии)' ? '' : e.target.value);
            }}
            className={styles.select}
            value={field.value === '' ? '(нет лицензии)' : field.value}
          >
            <MenuItem
              className="custom-select"
              value={'(нет лицензии)'}
              data-id="empty-license-item"
            >
              (нет лицензии)
            </MenuItem>
            {licenses.map((license, index) => (
              <MenuItem
                className="custom-select"
                key={license.value}
                value={license.id}
                data-id={`license-item-${index}`}
              >
                {license.value}
              </MenuItem>
            ))}
          </CustomSelect>
        )}
      />
      <CustomTypography className={clsx('text-14', styles.selectSubtitle)} color="grey">
        В рамках одной лицензии вы можете использовать один и тот же Адрес сервера
      </CustomTypography>

      <ModalLicense isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} loadLicenses={loadLicenses} />
    </div>
  );
};
