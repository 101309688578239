import { createApi, FetchBaseQueryMeta } from '@reduxjs/toolkit/query/react';
import { Roles } from 'src/enums';
import {
  createBaseQuery,
  createFetchArgs,
  createFetchArgsWithBody,
  parseResponse,
} from '../helpers';
import {
  IQueryIdProps,
  IQueryPropsWithClientId,
  IQuerySortParams,
  responseListItems,
  TQueryId,
} from '../types';
import { clientParamsToFormData, TRequestParams } from './client';
import { TShortProvider } from './provider';
import { TUserWithRole } from './users';

export type TShortClient = {
  client_id: string;
  name: string;
  description: string | null;
  domain: string;
  avatar: string | null;
  created_at: string;
};

export type TClient = {
  client_id: string;
  name: string;
  description: string;
  domain: string;
  avatar: string;
  created_at: string;
  is_visible: boolean;
  Provider_relations: {
    provider: TShortProvider;
  }[];
  _count: {
    Role: number;
  };
};

export type TWidgetColors = {
  font_color: string;
  link_color: string;
  button_color: string;
};

export type TClientFull = {
  avatar: string;
  cover: string | null;
  created_at: string;
  description: string;
  domain: string;
  name: string;
  is_visible: boolean;
  authorize_only_admins: boolean;
  show_avatar_in_widget: boolean;
  hide_widget_header: boolean;
  hide_widget_footer: boolean;
  widget_title: string;
  widget_info: string;
  widget_colors: TWidgetColors;
  required_providers_ids: string[];
  client_id: string;
  client_secret: string;
  redirect_uris: string[];
  post_logout_redirect_uris: string[];
  application_type: string;
  registration_access_token: { iat: number; clientId: string; kind: string; jti: string };
  grant_types: string[];
  id_token_signed_response_alg: string;
  request_uris: string[];
  require_auth_time: boolean;
  response_types: string[];
  subject_type: string;
  token_endpoint_auth_method: string;
  introspection_endpoint_auth_method: string;
  revocation_endpoint_auth_method: string;
  require_signed_request_object: boolean;
  client_id_issued_at: number;
  client_secret_expires_at: number;
  access_token_ttl: number;
  refresh_token_ttl: number;
};

export type TClientWithRole = {
  client: TClientFull;
  role: Roles;
};

export interface queryIdPropsWithRole extends IQueryIdProps {
  role: Roles;
}

type TQueryParams = {
  id: string;
  data: Partial<TRequestParams & { client_id: string; client_secret?: string }>;
};

export const clientsApi = createApi({
  reducerPath: 'clientsApi',
  tagTypes: ['Clients', 'ClientDetails'],
  baseQuery: createBaseQuery('clients'),
  endpoints: (builder) => ({
    getClients: builder.query<responseListItems<TClient[]>, IQuerySortParams>({
      query: (query: IQuerySortParams) => createFetchArgs<IQuerySortParams>('', 'GET', query),
      transformResponse: (clients: TClient[], meta: FetchBaseQueryMeta) =>
        parseResponse<TClient[]>(clients, meta),
      providesTags: ['Clients'],
    }),
    getClientInfo: builder.query({
      query: (params: TQueryId) => createFetchArgs(params.id, 'GET'),
      transformResponse: (data: TClientFull) => data,
      providesTags: ['ClientDetails'],
    }),
    createClient: builder.mutation<{ client_id: string }, TRequestParams>({
      query: (requestParams: TRequestParams) =>
        createFetchArgsWithBody('', 'POST', clientParamsToFormData(requestParams)),
    }),
    updateClient: builder.mutation({
      query: (params: TQueryParams) =>
        createFetchArgsWithBody(`${params.id}`, 'PUT', clientParamsToFormData(params.data)),
      invalidatesTags: ['ClientDetails'],
    }),
    deleteClient: builder.mutation({
      query: (clientId: string) => createFetchArgs(clientId, 'DELETE'),
      invalidatesTags: ['Clients'],
    }),
    deleteSession: builder.mutation({
      query: ({ clientId, userId }: IQueryIdProps) =>
        createFetchArgs(`${clientId}/users/${userId}/sessions`, 'DELETE'),
    }),
    getUsersClient: builder.query<responseListItems<TUserWithRole[]>, IQueryPropsWithClientId>({
      query: (params: IQueryPropsWithClientId) =>
        createFetchArgs<IQuerySortParams>(`${params.clientId}/users`, 'GET', params.query),
      transformResponse: (users: TUserWithRole[], meta: FetchBaseQueryMeta) =>
        parseResponse<TUserWithRole[]>(users, meta),
    }),
    getUserClient: builder.query({
      query: ({ clientId, userId }: IQueryIdProps) =>
        createFetchArgs<TUserWithRole>(`${clientId}/users/${userId}`, 'GET'),
    }),
    updateUserRoleClient: builder.mutation({
      query: (params: queryIdPropsWithRole) =>
        createFetchArgsWithBody<{ role: Roles }>(
          `${params.clientId}/users/${params.userId}/role`,
          'PUT',
          { role: params.role },
        ),
    }),
    deleteUserRoleClient: builder.mutation({
      query: ({ clientId, userId }: IQueryIdProps) =>
        createFetchArgs<{ role: Roles }>(`${clientId}/users/${userId}/role`, 'DELETE'),
    }),
  }),
});

export const {
  useLazyGetClientsQuery,
  useCreateClientMutation,
  useUpdateClientMutation,
  useDeleteClientMutation,
  useDeleteSessionMutation,
  useGetUsersClientQuery,
  useLazyGetUsersClientQuery,
  useUpdateUserRoleClientMutation,
  useDeleteUserRoleClientMutation,
  useGetClientInfoQuery,
  useLazyGetClientInfoQuery,
  useLazyGetUserClientQuery,
} = clientsApi;
