import React from 'react';
import { TClient } from 'src/redux/services/clients';
import { Card, ICardProps } from '../shared/Card';
import styles from './ClientCard.module.css';
import Box from '@mui/material/Box';
import { CustomTypography } from '../custom/CustomTypography';
import clsx from 'clsx';
import { LOADING_TEXT } from 'src/constants';
import { IconWithTooltip } from '../shared/IconWithTooltip';
import LayersOutlinedIcon from '@mui/icons-material/LayersOutlined';
import { formatDate, getImageURL } from 'src/helpers';
import { IconWrapper } from '../shared/IconWrapper';
import InsertLinkOutlinedIcon from '@mui/icons-material/InsertLinkOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import BookmarksOutlinedIcon from '@mui/icons-material/BookmarksOutlined';
import { CustomMuiIcon } from '../custom/CustomMuiIcon';
import Link from '@mui/material/Link';
import Avatar from '@mui/material/Avatar';
import { TShortProvider } from 'src/redux/services/provider';

export interface IClientCardProps extends ICardProps {
  items: TClient[];
  index: number;
  onClick: (id?: string) => void;
}

const ClientCardComponent: React.FC<IClientCardProps> = (props) => {
  const { items, index, onClick } = props;
  const client = items[index] || {};

  const textWrapper = (text: string) => {
    return client ? text || '' : LOADING_TEXT;
  };

  const renderProviders = (items: { provider: TShortProvider }[]) => {
    const providers = items?.slice(0, 4);
    const hiddenCount = items?.length - 4;
    const hasHiddenProviders = hiddenCount > 0;

    if (!providers?.length) {
      return (
        <CustomTypography color={'error'} className={clsx('text-14', styles.providersEmpty)}>
          Отсутствуют
        </CustomTypography>
      );
    }

    return (
      <div className={styles.providersList}>
        {providers.map(({ provider }) => (
          <div key={provider?.id} onClick={(e) => e.stopPropagation()}>
            <IconWithTooltip
              customIcon={
                provider?.avatar ? (
                  <Avatar src={getImageURL(provider?.avatar)} className={styles.providerAvatar} />
                ) : undefined
              }
              customStyleButton={styles.providerButton}
              muiIconStyles={styles.providerAvatar}
              muiIcon={LayersOutlinedIcon}
              customTitle={provider?.name}
            />
          </div>
        ))}
        {hasHiddenProviders && (
          <CustomTypography color="grey" className="text-14">+{hiddenCount}</CustomTypography>
        )}
      </div>
    );
  };

  return (
    <Card
      {...props}
      cardId={client?.client_id}
      isImage
      avatarUrl={client?.avatar}
      onClick={() => onClick(client?.client_id)}
      className={styles.card}
      content={
        <div className={styles.content}>
          <div className={styles.clientInfo}>
            <Box className={styles.clientMainInfo}>
              <CustomTypography className={clsx('text-14', styles.hideText)}>
                {textWrapper(client?.name)}
              </CustomTypography>
              <CustomTypography color="grey" className={clsx('text-12')}>
                {textWrapper(formatDate(client?.created_at))}
              </CustomTypography>
            </Box>
            <Box className={styles.clientAddInfo}>
              <div className={styles.rowWithIcon}>
                <IconWrapper className={styles.iconWrapper}>
                  <CustomMuiIcon
                    Icon={InsertLinkOutlinedIcon}
                    color="grey"
                    className={styles.icon}
                  />
                </IconWrapper>
                <CustomTypography className={clsx('text-12', styles.hideText)}>
                  <Link className={styles.clientLink} href={client?.domain} target="_blank" onClick={(e) => e.stopPropagation()}>
                    {textWrapper(client?.domain)}
                  </Link>
                </CustomTypography>
              </div>
              <div className={styles.rowWithIcon}>
                <IconWrapper className={styles.iconWrapper}>
                  <CustomMuiIcon
                    Icon={PeopleAltOutlinedIcon}
                    color="grey"
                    className={styles.icon}
                  />
                </IconWrapper>
                <CustomTypography color="grey" className={'text-12'}>
                  Участников:
                  <span className={clsx('text-14', styles.textNumber)}>
                    {textWrapper(client?._count?.Role.toString())}
                  </span>
                </CustomTypography>
              </div>
              {client?.is_visible && (
                <div className={styles.rowWithIcon}>
                  <IconWrapper className={styles.iconWrapper}>
                    <CustomMuiIcon
                      Icon={BookmarksOutlinedIcon}
                      color="grey"
                      className={styles.icon}
                    />
                  </IconWrapper>
                  <CustomTypography color={'grey'} className={'text-12'}>
                    Каталог
                  </CustomTypography>
                </div>
              )}
            </Box>
            <Box className={styles.clientProviders}>
              <CustomTypography color="grey" className={clsx('text-12')}>
                Активные способы входа:
              </CustomTypography>
              {renderProviders(client?.Provider_relations)}
            </Box>
          </div>
        </div>
      }
    />
  );
};

export const ClientCard = ClientCardComponent;
