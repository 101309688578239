import TextField from '@mui/material/TextField';
import clsx from 'clsx';
import { useFormContext } from 'react-hook-form';
import { CustomTypography } from '../../custom/CustomTypography';
import ColorPicker from './ColorPicker';
import styles from './WidgetColor.module.css';
import { TWidgetInputs } from 'src/redux/types';

export const WidgetColor = () => {
  const {
    register,
    formState: { errors },
  } = useFormContext<TWidgetInputs>();

  return (
    <div className={styles['widget-color']}>
      <CustomTypography className={clsx('text-14', styles['color-title'])}>
        Заголовок виджета
      </CustomTypography>
      <TextField
        {...register('widget_title')}
        className="custom"
        fullWidth
        variant="standard"
      />
      <div className={styles['flex-container']}>
        <div className={styles['set-colors-container']}>
          <div className={styles['set-color']}>
            <CustomTypography className={clsx('text-14', styles['color-title'])}>
              Цвет кнопок
            </CustomTypography>
            <ColorPicker componentName="widget_colors" colorName="button_color" />
            {errors.widget_colors?.button_color && (
              <CustomTypography color="error" className={'text-14'}>
                {errors.widget_colors?.button_color.message}
              </CustomTypography>
            )}
          </div>
          <div className={styles['set-color']}>
            <CustomTypography className={clsx('text-14', styles['color-title'])}>
              Цвет шрифта на кнопках
            </CustomTypography>
            <ColorPicker componentName="widget_colors" colorName="font_color" />
            {errors.widget_colors?.font_color && (
              <CustomTypography color="error" className={clsx('text-14')}>
                {errors.widget_colors?.font_color.message}
              </CustomTypography>
            )}
          </div>
          <div className={styles['set-color']}>
            <CustomTypography className={clsx('text-14', styles['color-title'])}>
              Цвет ссылок
            </CustomTypography>
            <ColorPicker componentName="widget_colors" colorName="link_color" />
            {errors.widget_colors?.link_color && (
              <CustomTypography color="error" className={clsx('text-14')}>
                {errors.widget_colors?.link_color.message}
              </CustomTypography>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
