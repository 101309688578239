import React, { FC, useState } from 'react';
import styles from './ChooseProvider.module.css';
import clsx from 'clsx';
import ListItem from '@mui/material/ListItem';
import { ReactComponent as IdIcon } from '../../../icons/Id.svg';
import { BaseSidePanel } from 'src/components/sidePanel/BaseSidePanel';
import { CustomTypography } from 'src/components/custom/CustomTypography';
import { BACKEND_URL } from 'src/constants';
import { CreateLdapProvider } from './create/CreateLdapProvider';
import { Create1CProvider } from './create/Create1CProvider';
import { CreateIdmProvider } from './create/CreateIdmProvider';
import { addProviderType } from '../Settings';

type TCreateProvider = {
  isOpen: boolean;
  onClose: () => void;
};

type TProviderTemplate = {
  type: addProviderType;
  pathToAvatar: string;
  name: string;
  onClick?: () => void;
};

export const ChooseProvider: FC<TCreateProvider> = ({ isOpen, onClose }) => {
  const ldapProviderAvatar = 'public/default/ldap.svg';
  const aldProProviderAvatar = 'public/default/ald-pro.png';
  const _1CProviderAvatar = 'public/default/1c.svg';
  const idmProviderAvatar = 'public/default/idm.svg';

  const [isCreateFormLdapOpen, setIsCreateFormLdapOpen] = useState(false);
  const [isCreateFormAldProOpen, setIsCreateFormAldProOpen] = useState(false);
  const [isCreateForm1COpen, setIsCreateForm1COpen] = useState(false);
  const [isCreateFormIdmOpen, setIsCreateFormIdmOpen] = useState(false);

  const providerTemplates: TProviderTemplate[] = [
    {
      name: 'LDAP',
      type: addProviderType.LDAP,
      pathToAvatar: ldapProviderAvatar,
      onClick: () => setIsCreateFormLdapOpen(true),
    },
    {
      name: 'ALDPRO',
      type: addProviderType.ALDPRO,
      pathToAvatar: aldProProviderAvatar,
      onClick: () => setIsCreateFormAldProOpen(true),
    },
    {
      name: '1C',
      type: addProviderType._1C,
      pathToAvatar: _1CProviderAvatar,
      onClick: () => setIsCreateForm1COpen(true),
    },
    {
      name: 'IDM',
      type: addProviderType.IDM,
      pathToAvatar: idmProviderAvatar,
      onClick: () => setIsCreateFormIdmOpen(true),
    },
  ];

  const handleProviderClose = (
    closeProvider: (arg: false) => void,
    closeChooseProvider?: boolean,
  ) => {
    closeProvider(false);
    if (closeChooseProvider) onClose();
  };

  return (
    <>
      <BaseSidePanel
        handleClosePanel={onClose}
        isOpenPanel={isOpen}
        title={'Создать провайдер'}
        description={'Выберите шаблон провайдера'}
        isNoBackdrop
      >
        <div className={styles.wrapper}>
          <div className={styles['choose-provider']}>
            {providerTemplates.map((provider) => {
              return (
                <ListItem
                  key={provider.type}
                  className={styles.provider}
                  onClick={provider.onClick}
                >
                  <div
                    style={{
                      backgroundImage: `url(${BACKEND_URL + '/' + provider.pathToAvatar})`,
                    }}
                    className={styles['provider-icon-wrapper']}
                  >
                    {!provider.pathToAvatar && <IdIcon />}
                  </div>
                  <CustomTypography className={clsx('text-14', styles['provider-name'])}>
                    {provider.name}
                  </CustomTypography>
                </ListItem>
              );
            })}
          </div>
        </div>
      </BaseSidePanel>
      <CreateLdapProvider
        pathToAvatar={ldapProviderAvatar}
        isOpen={isCreateFormLdapOpen}
        onClose={(closeChooseProvider?: boolean) => {
          handleProviderClose(setIsCreateFormLdapOpen, closeChooseProvider);
        }}
        providerType={addProviderType.LDAP}
      />
      <CreateLdapProvider
        pathToAvatar={aldProProviderAvatar}
        isOpen={isCreateFormAldProOpen}
        onClose={(closeChooseProvider?: boolean) => {
          handleProviderClose(setIsCreateFormAldProOpen, closeChooseProvider);
        }}
        providerType={addProviderType.ALDPRO}
      />
      <Create1CProvider
        pathToAvatar={_1CProviderAvatar}
        isOpen={isCreateForm1COpen}
        onClose={(closeChooseProvider?: boolean) => {
          handleProviderClose(setIsCreateForm1COpen, closeChooseProvider);
        }}
      />
      <CreateIdmProvider
        pathToAvatar={idmProviderAvatar}
        isOpen={isCreateFormIdmOpen}
        onClose={(closeChooseProvider?: boolean) => {
          handleProviderClose(setIsCreateFormIdmOpen, closeChooseProvider);
        }}
      />
    </>
  );
};
